<template>
    <nav class="main-header navbar navbar-expand navbar-white navbar-light">
        <ul class="navbar-nav">
        <li class="nav-item">
            <a class="nav-link" data-widget="pushmenu" href="#" role="button"><i class="fas fa-bars"></i></a>
        </li>
        </ul>
        <ul class="navbar-nav ml-auto">
        <!-- <li class="nav-item mr-3 ">
            <v-menu
            v-model="menu"
            :close-on-content-click="false"
            :close-on-click="true"
            :nudge-width="200"
            :max-width="300"
            transition="slide-y-transition"
            bottom
            style="z-index: 999999;"
            >
            <template v-slot:activator="{ on, attrs }">
                <v-btn
                icon
                color="indigo"
                v-bind="attrs"
                v-on="on"
                @click="notificationRead()"
                class="Bell"
                >
                        <notification-bell
                            :size="27"
                            :count="count"
                            counterLocation="upperRight"
                            counterStyle="roundRectangle"
                            counterBackgroundColor="#FF0000"
                            counterTextColor="#FFFFFF"
                            iconColor="#1471b8"
                        />
                </v-btn>
            </template>

            <v-card>
                <v-list>
                <v-list-item v-for="items in userData" :key="items.id">
                    <v-list-item-avatar v-if="items.read_at==null">
                        <i class="fas fa-paw" v-if="items.id_role==101"></i>
                        <i class="fas fa-user" v-if="items.id_role==100"></i>
                        <i class="fas fa-briefcase-medical" v-if="items.id_role==102"></i>
                        <i class="fas fa-user-edit" v-if="items.id_role==103"></i>
                        <i class="fas fa-ellipsis-h" v-if="items.id_role==104"></i>
                    </v-list-item-avatar>
                    <v-list-item-avatar v-if="items.read_at!=null">
                        <i class="fas fa-paw red--text" v-if="items.id_role==101"></i>
                        <i class="fas fa-user red--text" v-if="items.id_role==100"></i>
                        <i class="fas fa-briefcase-medical red--text" v-if="items.id_role==102"></i>
                        <i class="fas fa-user-edit red--text" v-if="items.id_role==103"></i>
                        <i class="fas fa-ellipsis-h red--text" v-if="items.id_role==104"></i>
                    </v-list-item-avatar>

                    <v-list-item-content >
                        <v-list-item-title>{{items.role.nama}}</v-list-item-title>
                        <v-list-item-subtitle >{{items.data}}</v-list-item-subtitle>
                    </v-list-item-content>

                </v-list-item>
                </v-list>

                <v-divider></v-divider>

                <v-card-actions>
                <v-spacer></v-spacer>

                <v-btn
                    text
                    @click="menu = false"
                >
                    Cancel
                </v-btn>
                <v-btn
                    color="primary"
                    text
                    @click="menu = false"
                >
                    Save
                </v-btn>
                </v-card-actions>
            </v-card>
            </v-menu>
        </li>                 -->
        <li class="nav-item">
            <a href="#" @click.prevent="signOut ">
                Logout
            <i class="fas fa-sign-out-alt text-danger"></i>
            </a>
        </li>
        </ul>
    </nav>
</template>

<script>

    import { mapGetters, mapActions } from 'vuex'
    import NotificationBell from 'vue-notification-bell'
    export default {
        created() {
            if (this.authenticated!=null) {
                // this.firstNotif();
                // this.startInterval();
            }
        },
        data: () => ({
            fav: true,
            menu: false,
            message: false,
            hints: true,
            count: 0,
            userData: [],
        }),
        computed:{
            ...mapGetters({
                authenticated: 'auth/authenticated',
            })
        },
        name: 'Bell',
        components: {
            NotificationBell 
        },
        
        methods: {
            ...mapActions({
                signOutAction: 'auth/signOut'
            }),
            // notificationRead(){
            //     if (this.authenticated!=null) {
            //         axios.post(`notification/read/${this.authenticated.user.id}`)
            //         .then((response) => {    
            //             this.count = 0;
            //         })
            //         .catch(errors => {
            //             console.log(errors);
            //         });
            //     }
            // },
            // firstNotif(){
            //     if (this.authenticated!=null) {
            //         axios.get(`notification/bell/${this.authenticated.user.id}`)
            //         .then((response) => {    
            //             this.userData = response.data.data;
            //             this.count = response.data.count;
            //         })
            //         .catch(errors => {
            //             console.log(errors);
            //         });
            //     }
            // },
            signOut(){
                this.signOutAction().then(()=>{
                    this.$router.replace({
                        name:'login'
                    })
                })
            },
            // startInterval() {
            //        setInterval(() => {
            //            if (this.authenticated!=null) {
            //                 axios.get(`notification/bell/${this.authenticated.user.id}`)
            //                     .then((response) => {    
            //                         this.userData = response.data.data;
            //                         this.count = response.data.count;
            //                     })
            //                     .catch(errors => {
            //                         console.log(errors);
            //                     });
            //             }
                       
            //        }, 60000);
            // }
        },

    }
</script>