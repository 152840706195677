<template>
    <div class="content-wrapper">
      <section class="content-header">
        <div class="container-fluid">
          <div class="row">
            <div class="col-sm-6">
              <h2>Daftar Pesanan</h2>
            </div>
            <div class="col-sm-6">
              <ol class="breadcrumb float-sm-right">
                <li class="breadcrumb-item ">
                  <router-link :to="{ name:'' }">
                    Daftar 
                  </router-link>
                </li>
                <li class="breadcrumb-item active">
                  <router-link :to="{ name:'item' }">
                    Pesanan
                  </router-link>
                </li>
              </ol>
            </div>
          </div>
        </div>
      </section>
      <section class="content">
      <div class="container-fluid">
        <div id="app" >
          <v-app>
            <div >
              <div v-for="(items) in data">
                <v-toolbar
                  color="indigo"
                  dark
                >
                <v-spacer></v-spacer>
                  <div>
                    <div style="text-align: center;">Pesanan</div>
                    <v-toolbar-title >{{items.date}}</v-toolbar-title>
                  </div>
                <v-spacer></v-spacer>
                </v-toolbar>
                <div class="row m-4" v-for="item in items.data">
                  <div class="col-lg-12">
                  <v-card 
                    class="mx-auto"
                    outlined
                  >
                  <v-list-item class="row">
                      <v-list-item-content class="pa-5 pt-8">
                        <h4 class="col-12 minim text-bold pb-2">
                          Pesanan  {{ item.penerima }}
                        </h4>
                        <div class="col-lg-6 minim">
                          <div class="label-text" style="font-weight:800">No Telp Penerima</div>
                          <div class="sub-text">{{ item.no_hp }}</div>
                        </div>
                        <div class="col-lg-6 minim">
                          <div class="label-text" style="font-weight:1000">Harga</div>
                          <div class="sub-text">Total harga :Rp.{{ item.total_harga |numFormat}}</div>
                          <div class="sub-text">Terbayar Sebanyak: Rp.{{ item.total_terbayar |numFormat}}</div>
                        </div> 
                        <div class="col-lg-6 minim">
                          <div class="label-text" style="font-weight:800">Waktu</div>
                          <div class="sub-text">Dipesan pada: {{ item.jadi | moment("dddd, D/MM/YYYY HH:mm") }}</div>
                          <div class="sub-text">Jadi Pada: {{ item.waktu_dipesan | moment("dddd, D/MM/YYYY HH:mm")  }}</div>
                        </div>   
                        <div class="col-lg-12 minim">
                          <div class="label-text" style="font-weight:800">Catatan Tambahan</div>
                          <div class="sub-text">{{ item.catatan }}</div>
                        </div>                      
                      </v-list-item-content>
                    </v-list-item>

                    <v-list-item class="row">
                      <v-list-item-content class="pa-12">
                        <h6 class="minim text-bold mb-4" style="padding:0px !important">
                          Detail Item Pesanan
                        </h6>          
                        <v-simple-table >
                          <template v-slot:default>
                            <thead>
                              <tr>
                                <th class="text-left">
                                  Item
                                </th>
                                <th class="text-left">
                                  Banyak
                                </th>
                                <th class="text-left">
                                  Harga Satuan
                                </th>
                                <th>
                                  total Harga
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr
                                v-for="items in item.sub_item"
                                :key="items.nama"
                              >
                                <td>{{ items.nama }}</td>
                                <td>{{ items.banyak  | numFormat}}</td>
                                <td>{{ items.harga | numFormat}}</td>
                                <td>Rp.{{(items.harga*items.banyak)|numFormat}}</td>
                              </tr>
                            </tbody>
                          </template>
                        </v-simple-table>                
                      </v-list-item-content>
                    </v-list-item>
                    <v-card-action class="d-block text-right">
                      <v-btn
                        elevation="2"
                        color="primary"
                        class="ma-1 mb-2"
                        @click="printData(item,'kwitansi')"
                      >
                        Print Kwitansi
                      </v-btn>
                      <v-btn
                        elevation="2"
                        color="primary"
                        class="ma-1 mb-2"
                        @click="printData(item,'invoice')"
                      >
                        Print Invoice
                      </v-btn>
                      <v-btn
                        elevation="2"
                        color="primary"
                        class="ma-1 mb-2"
                        @click="printData(item,'surat')"
                      >
                        Print Surat Jalan
                      </v-btn>
                      <router-link 
                        :to="{
                          name: 'itemform', 
                          params: {
                            id: item.id, 
                            update: true, 
                          }
                        }"
                      >
                        <v-btn
                          elevation="2"
                          color="warning"
                          class="ma-1 mb-2"
                        >
                            Edit
                        </v-btn>
                      </router-link>
                      <v-btn
                        elevation="2"
                        color="red"
                        class="ma-1 mb-2"
                        dark
                        @click="deleteData(item.id,item.penerima)"
                      >
                        hapus
                      </v-btn>
                    </v-card-action>
                  </v-card>
                </div>
              </div>
                
              </div>
              <div style="display:none" id="invoicePrintField">
                      <div style="color: #131a47;border: 1px solid black; padding: 10px">
                        <div class=" row ">
                            <div class="col-sm-2 " style="padding:20px 30px 30px 30px ">
                                <img src="http://kotakanbackend.resepedia.net/images/logo_kedai.png " style='width: 100%; object-fit: contain'>
                            </div>
                            <div class="col-sm-5 ">
                                <h6 style="color: #131a47; font-weight: 1000; margin-bottom:0px">KEDAI KAYUMANIS</h6>
                                <span style="color: #131a47; font-size:12pt;">
                                    by PT. Amerta Sukses Abadi 
                                    <br> Jl. Kayu Manis VIII No.38 Matraman
                                    <br> Jakarta Timur
                                    <br> SMS: 0878 8160 4448
                                    <br> web: www.kedaikayumanis.com
                                </span>
                                <h6 style="color: #131a47; font-weight: 1000; margin-top:0px">PESAN ANTAR (021) 2128 1234</h6>
                            </div>
                            <div class="col-sm-5 row" >
                                <div class="col-sm-4" style="color: #131a47; text-align:right; font-size:12pt; font-weight: 800;">Nomor : </div>
                                <div class="col-sm-8" style="color: #131a47; text-align:left; font-size:12pt; font-weight: 800;">{{dataPrint.id}}INVKKM{{dataPrint.waktu_dipesan| moment("M")}}{{dataPrint.waktu_dipesan| moment("Y")}}</div>
                                <div class="col-sm-4" style="color: #131a47; text-align:right;font-size:12pt; font-weight: 800;">Tanggal : </div>
                                <div class="col-sm-8" style="color: #131a47; text-align:left; font-size:12pt; font-weight: 800;">{{dataPrint.waktu_dipesan| moment("D-MMMM-Y")}}</div>
                                <div class="col-sm-4" style="color: #131a47; text-align:right;font-size:12pt; font-weight: 800;">Kepada Yth :</div>
                                <div class="col-sm-8" style="color: #131a47; text-align:left; font-size:12pt; font-weight: 800;">{{dataPrint.penerima}}({{dataPrint.no_hp}})</div>
                            </div>
                        </div>
                        <p class="text-center" style="color: #131a47; font-weight:1000; font-size: 12pt;letter-spacing: 4px; background-color:rgb(190, 190, 190)">
                            INVOICE
                        </p>
                        <table class="table table-bordered table-condensed" style="color: #131a47; font-size:10pt !important; margin: 0px !important;">
                            <tr style="border: 1px solid black !important;">
                                <th style="width: 10%;border: 1px solid black !important;font-size:12pt; ">Banyak</th>
                                <th style="width: 40%;border: 1px solid black !important;font-size:12pt; ">Uraian</th>
                                <th style="width: 20%;border: 1px solid black !important;font-size:12pt; ">Harga Satuan</th>
                                <th style="width: 20%;border: 1px solid black !important;font-size:12pt; ">Jumlah</th>
                            </tr>
                            <tr style="border: 1px solid black !important;" v-for="items in dataPrint.sub_item" :key="items.id">
                                <td style="border: 1px solid black !important;font-size:12pt;">{{items.banyak|numFormat}}</td>
                                <td style="border: 1px solid black !important;font-size:12pt;"><span style="font-weight:800">{{items.nama}}</span>  <br> {{items.desc}} </td>
                                <td style="border: 1px solid black !important;font-size:12pt;">Rp.{{items.harga|numFormat}}</td>
                                <td style="border: 1px solid black !important;font-size:12pt;">Rp.{{(items.harga*items.banyak)|numFormat}}</td>
                            </tr>
                            <tr style="border: 1px solid black !important;">
                                <td style="border: 1px solid black !important;font-size:12pt;" colspan="3 " class="text-right ">Total</td>
                                <td style="border: 1px solid black !important;font-size:12pt;">Rp.{{dataPrint.total_harga|numFormat}}</td>
                            </tr>
                            <tr style="border: 1px solid black !important;">
                                <td style="border: 1px solid black !important;font-size:12pt;" colspan="3 " class="text-right ">Uang Muka</td>
                                <td style="border: 1px solid black !important;font-size:12pt;">Rp.{{dataPrint.total_terbayar|numFormat}}</td>
                            </tr>
                            <tr style="border: 1px solid black !important;">
                                <td style="border: 1px solid black !important;font-size:12pt;" colspan="3 " class="text-right ">Sisa Pembayaran</td>
                                <td style="border: 1px solid black !important;font-size:12pt;">Rp.{{(dataPrint.total_harga-dataPrint.total_terbayar)|numFormat}}</td>
                            </tr>
                            <tr style="border: 1px solid black !important;">
                                <td style="border: 1px solid black !important;font-size:12pt;" colspan="4 ">Terbilang : {{TerbilangFilter((dataPrint.total_harga-dataPrint.total_terbayar)) }} Rupiah</td>
                            </tr>
                        </table>  
                        <div class="row" style="color: #131a47; padding:2% 0% 2% 0%">
                          <div class="col-sm-8">
                            Pembayaran dilakukan melalui rekening:
                            <br>
                            BCA KCP AM Sangaji
                            <br>
                            No. Rek: 587 025 3038
                            <br>
                            an. PT Amerta Sukses Abadi
                            <br>
                            <br>
                            BRI KCP Manggarai
                            <br>
                            No Rek: 1125-01-000167-30-4
                            <br>
                            an. PT Amerta Sukses Abadi
                            <br>
                            <br>Pembayaran melalui cek dianggap sah apabila sudah dicairkan.
                          </div>
                          <div class="col-sm-4 " style="font-size:12pt;">
                            Hormat Kami
                            <br>
                            <br>
                            <br>
                            <br>
                            {{authenticated.user.name}}
                          </div>
                        </div>
                      </div>

                      <div style="color: #131a47; border: 1px solid black; padding: 10px; margin-top:2%">
                        <div class=" row ">
                            <div class="col-sm-2 " style="padding:20px 30px 30px 30px ">
                                <img src="http://kotakanbackend.resepedia.net/images/logo_kedai.png " style='width: 100%; object-fit: contain'>
                            </div>
                            <div class="col-sm-5 ">
                                <h6 style="font-weight: 1000; margin-bottom:0px">KEDAI KAYUMANIS</h6>
                                <span style="font-size:12pt;">
                                    by PT. Amerta Sukses Abadi 
                                    <br> Jl. Kayu Manis VIII No.38 Matraman
                                    <br> Jakarta Timur
                                    <br> SMS: 0878 8160 4448
                                    <br> web: www.kedaikayumanis.com
                                </span>
                                <h6 style="font-weight: 1000; margin-top:0px">PESAN ANTAR (021) 2128 1234</h6>
                            </div>
                            <div class="col-sm-5 row" >
                                <div class="col-sm-4" style="text-align:right; font-size:12pt; font-weight: 800;">Nomor : </div>
                                <div class="col-sm-8" style="text-align:left; font-size:12pt; font-weight: 800;">{{dataPrint.id}}INVKKM{{dataPrint.waktu_dipesan| moment("M")}}{{dataPrint.waktu_dipesan| moment("Y")}}</div>
                                <div class="col-sm-4" style="text-align:right;font-size:12pt; font-weight: 800;">Tanggal : </div>
                                <div class="col-sm-8" style="text-align:left; font-size:12pt; font-weight: 800;">{{dataPrint.waktu_dipesan| moment("D-MMMM-Y")}}</div>
                                <div class="col-sm-4" style="text-align:right;font-size:12pt; font-weight: 800;">Kepada Yth :</div>
                                <div class="col-sm-8" style="text-align:left; font-size:12pt; font-weight: 800;">{{dataPrint.penerima}}({{dataPrint.no_hp}})</div>
                            </div>
                        </div>
                        <p class="text-center" style="font-weight:1000; font-size: 12pt;letter-spacing: 4px; background-color:rgb(190, 190, 190)">
                            INVOICE
                        </p>
                        <table class="table table-bordered table-condensed" style="color: #131a47; font-size:10pt !important; margin: 0px !important;">
                            <tr style="border: 1px solid black !important;">
                                <th style="width: 10%;border: 1px solid black !important;font-size:12pt; ">Banyak</th>
                                <th style="width: 40%;border: 1px solid black !important;font-size:12pt; ">Uraian</th>
                                <th style="width: 20%;border: 1px solid black !important;font-size:12pt; ">Harga Satuan</th>
                                <th style="width: 20%;border: 1px solid black !important;font-size:12pt; ">Jumlah</th>
                            </tr>
                            <tr style="border: 1px solid black !important;" v-for="items in dataPrint.sub_item" :key="items.id">
                                <td style="border: 1px solid black !important;font-size:12pt;">{{items.banyak|numFormat}}</td>
                                <td style="border: 1px solid black !important;font-size:12pt;"><span style="font-weight:800">{{items.nama}}</span>  <br> {{items.desc}} </td>
                                <td style="border: 1px solid black !important;font-size:12pt;">Rp.{{items.harga|numFormat}}</td>
                                <td style="border: 1px solid black !important;font-size:12pt;">Rp.{{(items.harga*items.banyak)|numFormat}}</td>
                            </tr>
                            <tr style="border: 1px solid black !important;">
                                <td style="border: 1px solid black !important;font-size:12pt;" colspan="3 " class="text-right ">Total</td>
                                <td style="border: 1px solid black !important;font-size:12pt;">Rp.{{dataPrint.total_harga|numFormat}}</td>
                            </tr>
                            <tr style="border: 1px solid black !important;">
                                <td style="border: 1px solid black !important;font-size:12pt;" colspan="3 " class="text-right ">Uang Muka</td>
                                <td style="border: 1px solid black !important;font-size:12pt;">Rp.{{dataPrint.total_terbayar|numFormat}}</td>
                            </tr>
                            <tr style="border: 1px solid black !important;">
                                <td style="border: 1px solid black !important;font-size:12pt;" colspan="3 " class="text-right ">Sisa Pembayaran</td>
                                <td style="border: 1px solid black !important;font-size:12pt;">Rp.{{(dataPrint.total_harga-dataPrint.total_terbayar)|numFormat}}</td>
                            </tr>
                            <tr style="border: 1px solid black !important;">
                                <td style="border: 1px solid black !important;font-size:12pt;" colspan="4 ">Terbilang : {{TerbilangFilter((dataPrint.total_harga-dataPrint.total_terbayar)) }} Rupiah</td>
                            </tr>
                        </table>   
                        <div class="row" style="color: #131a47; padding:2% 0% 2% 0%">
                          <div class="col-sm-8">
                            <table class="table table-bordered table-condensed" style="color: #131a47; font-size:10pt !important">
                              <tr style="height:100px border: 1px solid black !important;">
                                <th style="border: 1px solid black !important;width:50%;font-size:12pt">
                                    Kepada:
                                    <br> {{dataPrint.penerima}}({{dataPrint.no_hp}})
                                    <br> {{dataPrint.alamat}}
                                </th>
                                <th style="border: 1px solid black !important;width:50%;font-size:12pt">Penerima:
                                  <br>
                                  <br>
                                </th>
                              </tr>
                            </table>
                          </div>
                          <div class="col-sm-4 " style="color: #131a47;font-size:12pt">
                            Hormat Kami
                            <br>
                            <br>
                            <br>
                            <br>
                            {{authenticated.user.name}}
                          </div>
                        </div>
                      </div>
                    </div>

                    <div style="display:none" id="kwitansiPrintField">
                      <div style="color: #131a47; border: 1px solid black; padding: 10px">
                          <div class=" row ">
                              <div class="col-sm-2 " style="padding:20px 30px 30px 30px ">
                                  <img src="http://kotakanbackend.resepedia.net/images/logo_kedai.png " style='width: 100%; object-fit: contain'>
                              </div>
                              <div class="col-sm-4 ">
                                  <h6 style="font-weight: 1000; margin-bottom:0px">KEDAI KAYUMANIS</h6>
                                  <span style="font-size:12pt;">
                                      by PT. Amerta Sukses Abadi 
                                      <br> Jl. Kayu Manis VIII No.38 Matraman
                                      <br> Jakarta Timur
                                      <br> SMS: 0878 8160 4448
                                      <br> web: www.kedaikayumanis.com
                                  </span>
                                  <h6 style="font-weight: 1000; margin-top:0px">PESAN ANTAR (021) 2128 1234</h6>
                              </div>
                              <div class="col-sm-6 row" style="margin: 20 0 0 !important">
                                  <div class="col-sm-4" style="text-align:right; font-size:12pt; font-weight: 800;">Nomor : </div>
                                  <div class="col-sm-8" style="text-align:left; font-size:12pt; font-weight: 800;">{{dataPrint.id}}KWTKKM{{dataPrint.waktu_dipesan| moment("M")}}{{dataPrint.waktu_dipesan| moment("Y")}}</div>
                                  <div class="col-sm-4" style="text-align:right;font-size:12pt; font-weight: 800;">Tanggal : </div>
                                  <div class="col-sm-8" style="text-align:left; font-size:12pt; font-weight: 800;">{{dataPrint.waktu_dipesan| moment("D-MMMM-Y")}}</div>
                              </div>
                          </div>
                          <p class="text-center" style="font-weight:1000; font-size: 12pt;letter-spacing: 4px; background-color:rgb(190, 190, 190)">
                              KWITANSI
                          </p>
                          <div class="row " style="padding:10px 10px ">
                            <div class="col-sm-3 " style=" font-size:12pt; font-weight: 800; ">Sudah Terima Dari</div>
                            <div class="col-sm-9 " style="font-size:12pt; font-weight: 800; ">: {{dataPrint.penerima}}({{dataPrint.no_hp}})</div>
                            <div class="col-sm-3 " style=" font-size:12pt; font-weight: 800; ">Total Harga</div>
                            <div class="col-sm-9 " style="font-size:12pt; font-weight: 800; ">: {{dataPrint.total_harga|numFormat}}</div>
                            <div class="col-sm-3 " style=" font-size:12pt; font-weight: 800; margin-top:20px ">Untuk Pembayaran :</div>
                          </div>
                          <table class="table table-bordered table-condensed" style="color: #131a47; font-size:10pt !important; margin: 0px !important;">
                            <tr style="border: 1px solid black !important;">
                                <th style="width: 10%; border: 1px solid black !important;">Banyak</th>
                                <th style="width: 40%; border: 1px solid black !important;">Uraian</th>
                                <th style="width: 20%; border: 1px solid black !important;">Harga Satuan</th>
                                <th style="width: 20%; border: 1px solid black !important;">Jumlah</th>
                            </tr>
                            <tr style="border: 1px solid black !important;" v-for="items in dataPrint.sub_item" :key="items.id">
                                <td style="border: 1px solid black !important;">{{items.banyak|numFormat}}</td>
                                <td style="border: 1px solid black !important; font-weight:800">{{items.nama}} </td>
                                <td style="border: 1px solid black !important;">Rp.{{items.harga|numFormat}}</td>
                                <td style="border: 1px solid black !important;">Rp.{{(items.harga*items.banyak)|numFormat}}</td>
                            </tr>
                            <tr style="border: 1px solid black !important;">
                                <td style="border: 1px solid black !important;" colspan="3 " class="text-right ">Total</td>
                                <td style="border: 1px solid black !important;">Rp.{{dataPrint.total_harga|numFormat}}</td>
                            </tr>
                            <tr style="border: 1px solid black !important;">
                                <td style="border: 1px solid black !important;" colspan="4 ">Terbilang : {{TerbilangFilter(dataPrint.total_harga)}} Rupiah</td>
                            </tr>
                          </table>
                          <div style="color: #131a47; font-size:12pt; margin:2% 0% 2% 80%;font-size:12pt">
                            Hormat Kami
                            <br>
                            <br>
                            <br>
                            <br>
                            {{authenticated.user.name}}
                          </div>
                      </div>
                      
                      <div style="color: #131a47; border: 1px solid black; padding: 10px;margin-top:2%">
                          <div class=" row ">
                              <div class="col-sm-2 " style="padding:20px 30px 30px 30px ">
                                  <img src="http://kotakanbackend.resepedia.net/images/logo_kedai.png " style='width: 100%; object-fit: contain'>
                              </div>
                              <div class="col-sm-4 ">
                                  <h6 style="font-weight: 1000; margin-bottom:0px">KEDAI KAYUMANIS</h6>
                                  <span style="font-size:12pt;">
                                      by PT. Amerta Sukses Abadi 
                                      <br> Jl. Kayu Manis VIII No.38 Matraman
                                      <br> Jakarta Timur
                                      <br> SMS: 0878 8160 4448
                                      <br> web: www.kedaikayumanis.com
                                  </span>
                                  <h6 style="font-weight: 1000; margin-top:0px">PESAN ANTAR (021) 2128 1234</h6>
                              </div>
                              <div class="col-sm-6 row" style="margin: 20 0 0 !important">
                                  <div class="col-sm-4" style="text-align:right; font-size:12pt; font-weight: 800;">Nomor : </div>
                                  <div class="col-sm-8" style="text-align:left; font-size:12pt; font-weight: 800;">{{dataPrint.id}}KWTKKM{{dataPrint.waktu_dipesan| moment("M")}}{{dataPrint.waktu_dipesan| moment("Y")}}</div>
                                  <div class="col-sm-4" style="text-align:right;font-size:12pt; font-weight: 800;">Tanggal : </div>
                                  <div class="col-sm-8" style="text-align:left; font-size:12pt; font-weight: 800;">{{dataPrint.waktu_dipesan| moment("D-MMMM-Y")}}</div>
                              </div>
                          </div>
                          <p class="text-center" style="font-weight:1000; font-size: 12pt;letter-spacing: 4px; background-color:rgb(190, 190, 190)">
                              KWITANSI
                          </p>
                          <div class="row " style="padding:10px 10px ">
                            <div class="col-sm-3 " style=" font-size:12pt; font-weight: 800; ">Sudah Terima Dari</div>
                            <div class="col-sm-9 " style="font-size:12pt; font-weight: 800; ">: {{dataPrint.penerima}}({{dataPrint.no_hp}})</div>
                            <div class="col-sm-3 " style=" font-size:12pt; font-weight: 800; ">Total Harga</div>
                            <div class="col-sm-9 " style="font-size:12pt; font-weight: 800; ">: {{dataPrint.total_harga|numFormat}}</div>
                            <div class="col-sm-3 " style=" font-size:12pt; font-weight: 800; margin-top:20px ">Untuk Pembayaran :</div>
                          </div>
                          <table class="table table-bordered table-condensed" style="color: #131a47; font-size:10pt !important; margin: 0px !important;">
                            <tr style="border: 1px solid black !important;">
                                <th style="width: 10%; border: 1px solid black !important;">Banyak</th>
                                <th style="width: 40%; border: 1px solid black !important;">Uraian</th>
                                <th style="width: 20%; border: 1px solid black !important;">Harga Satuan</th>
                                <th style="width: 20%; border: 1px solid black !important;">Jumlah</th>
                            </tr>
                            <tr style="border: 1px solid black !important;" v-for="items in dataPrint.sub_item" :key="items.id">
                                <td style="border: 1px solid black !important;">{{items.banyak|numFormat}}</td>
                                <td style="border: 1px solid black !important;font-weight:800">{{items.nama}}</td>
                                <td style="border: 1px solid black !important;">Rp.{{items.harga|numFormat}}</td>
                                <td style="border: 1px solid black !important;">Rp.{{(items.harga*items.banyak)|numFormat}}</td>
                            </tr>
                            <tr style="border: 1px solid black !important;">
                                <td style="border: 1px solid black !important;" colspan="3 " class="text-right ">Total</td>
                                <td style="border: 1px solid black !important;">Rp.{{dataPrint.total_harga|numFormat}}</td>
                            </tr>
                            <tr style="border: 1px solid black !important;">
                                <td style="border: 1px solid black !important;" colspan="4 ">Terbilang : {{TerbilangFilter(dataPrint.total_harga)}} Rupiah</td>
                            </tr>
                          </table>
                          <h6 style="font-weight: 1000; margin-bottom:10px;margin-top:5px "> 
                            Catatan :
                            <br>
                            {{dataPrint.catatan}}
                          </h6>
                          <div class="row ">
                            <div class="col-sm-8">
                              <table class="table table-bordered table-condensed" style="color: #131a47; font-size:10pt !important">
                                <tr style="height:100px border: 1px solid black !important;">
                                  <th style="border: 1px solid black !important;width:50%">
                                      Kepada:
                                      <br> {{dataPrint.penerima}}({{dataPrint.no_hp}})
                                      <br> {{dataPrint.alamat}}

                                  </th>
                                  <th style="border: 1px solid black !important;width:50%">Penerima:
                                    <br>
                                    <br>
                                  </th>
                                </tr>
                              </table>
                            </div>
                            <div class="col-sm-4 " style="font-size:12pt;">
                              Hormat Kami
                              <br>
                              <br>
                              <br>
                              <br>
                              {{authenticated.user.name}}
                            </div>
                          </div>
                      </div>
                    </div>
                    <div style="display:none" id="suratPrintField">
                      <div style="color: #131a47; border: 1px solid black; padding: 10px">
                          <div class=" row ">
                              <div class="col-sm-2 " style="padding:20px 30px 30px 30px ">
                                <img src="http://kotakanbackend.resepedia.net/images/logo_kedai.png " style='width: 100%; object-fit: contain'>
                              </div>
                              <div class="col-sm-4 ">
                                <h6 style="font-weight: 1000; margin-bottom:0px">KEDAI KAYUMANIS</h6>
                                <span style="font-size:12pt;">
                                    by PT. Amerta Sukses Abadi 
                                    <br> Jl. Kayu Manis VIII No.38 Matraman
                                    <br> Jakarta Timur
                                    <br> SMS: 0878 8160 4448
                                    <br> web: www.kedaikayumanis.com
                                </span>
                                <h6 style="font-weight: 1000; margin-top:0px">PESAN ANTAR (021) 2128 1234</h6>
                              </div>
                              <div class="col-sm-6 row" style="margin: 20 0 0 !important">
                                <div class="col-sm-4" style="text-align:right; font-size:12pt; font-weight: 800;">Nomor : </div>
                                <div class="col-sm-8" style="text-align:left; font-size:12pt; font-weight: 800;">{{dataPrint.id}}SJKKM{{dataPrint.waktu_dipesan| moment("M")}}{{dataPrint.waktu_dipesan| moment("Y")}}</div>
                                <div class="col-sm-4" style="text-align:right;font-size:12pt; font-weight: 800;">Tanggal : </div>
                                <div class="col-sm-8" style="text-align:left; font-size:12pt; font-weight: 800;">{{dataPrint.waktu_dipesan| moment("D-MMMM-Y")}}</div>
                              </div>
                          </div>
                          <p class="text-center" style="font-weight:1000; font-size: 12pt;letter-spacing: 4px; background-color:rgb(190, 190, 190)">
                            SURAT JALAN / TANDA TERIMA
                          </p>
                          <div class="row " style="color: #131a47; padding:10px 10px ">
                            <div class="col-sm-3 " style=" font-size:12pt; font-weight: 800; ">Sudah Terima Dari</div>
                            <div class="col-sm-9 " style="font-size:12pt; font-weight: 800; ">: {{dataPrint.penerima}}({{dataPrint.no_hp}})</div>
                            <div class="col-sm-3 " style=" font-size:12pt; font-weight: 800; ">Alamat</div>
                            <div class="col-sm-9 " style="font-size:12pt; font-weight: 800; ">: {{dataPrint.alamat}}</div>
                            <div class="col-sm-12 " style=" font-size:12pt; font-weight: 800; margin-top:20px ">Kami kirimkan dengan kendaraan (motor/mobil) dengan nomor polisi : {{dataPrint.nomor_driver}}</div>
                          </div>
                          <table class="table table-bordered table-condensed" style="color: #131a47; font-size:10pt !important; margin: 0px !important;">
                            <tr>
                              <th style="width: 10%; border: 1px solid black !important;">Banyak</th>
                              <th style="width: 90%; border: 1px solid black !important;">Uraian</th>
                            </tr>
                            <tr style="border: 1px solid black !important;" v-for="items in dataPrint.sub_item" :key="items.id">
                                <td style="border: 1px solid black !important;">{{items.banyak|numFormat}}</td>
                                <td style="border: 1px solid black !important; font-weight:800">{{items.nama}}</td>
                            </tr>
                          </table>
                          <h6 style="font-weight: 1000; margin-bottom:10px;margin-top:5px "> 
                            Catatan :
                            {{dataPrint.catatan}}
                          </h6>
                          <div>
                            <table class="table" style="color: #131a47; font-size:10pt !important;font-size:12pt">
                              <tr style="height:100px">
                                <th style="width: 25%; border: 1px solid black !important">
                                  Catatan:
                                  <br> Lembar 1: pengiriman
                                  <br> Lembar 2: Penerima
                                </th>
                                <th>
                                  pengirim
                                  <br>
                                  <br>
                                  <br>
                                  <br> 
                                  __________
                                </th>
                                <th>
                                  driver
                                  <br>
                                  <br>
                                  <br>
                                  <br> 
                                  {{dataPrint.driver}}
                                </th>
                                <th>
                                  Penerima
                                  <br>
                                  <br>
                                  <br>
                                  <br>
                                  __________
                                </th>
                              </tr>
                            </table>
                          </div>
                      </div>
                      
                      <div style="border: 1px solid black; padding: 10px;margin-top:2%">
                          <div class=" row " style="color: #131a47;">
                              <div class="col-sm-2 " style="padding:20px 30px 30px 30px ">
                                <img src="http://kotakanbackend.resepedia.net/images/logo_kedai.png " style='width: 100%; object-fit: contain'>
                              </div>
                              <div class="col-sm-4 ">
                                <h6 style="color: #131a47; font-weight: 1000; margin-bottom:0px">KEDAI KAYUMANIS</h6>
                                <span style="font-size:12pt;">
                                    by PT. Amerta Sukses Abadi 
                                    <br> Jl. Kayu Manis VIII No.38 Matraman
                                    <br> Jakarta Timur
                                    <br> SMS: 0878 8160 4448
                                    <br> web: www.kedaikayumanis.com
                                </span>
                                <h6 style="font-weight: 1000; margin-top:0px">PESAN ANTAR (021) 2128 1234</h6>
                              </div>
                              <div class="col-sm-6 row" style="margin: 20 0 0 !important">
                                <div class="col-sm-4" style="text-align:right; font-size:12pt; font-weight: 800;">Nomor : </div>
                                <div class="col-sm-8" style="text-align:left; font-size:12pt; font-weight: 800;">{{dataPrint.id}}SJKKM{{dataPrint.waktu_dipesan| moment("M")}}{{dataPrint.waktu_dipesan| moment("Y")}}</div>
                                <div class="col-sm-4" style="text-align:right;font-size:12pt; font-weight: 800;">Tanggal : </div>
                                <div class="col-sm-8" style="text-align:left; font-size:12pt; font-weight: 800;">{{dataPrint.waktu_dipesan| moment("D-MMMM-Y")}}</div>
                              </div>
                          </div>
                          <p class="text-center" style="color: #131a47; font-weight:1000; font-size: 12pt;letter-spacing: 4px; background-color:rgb(190, 190, 190)">
                            SURAT JALAN / TANDA TERIMA
                          </p>
                          <div class="row " style="color: #131a47; padding:10px 10px ">
                            <div class="col-sm-3 " style=" font-size:12pt; font-weight: 800; ">Sudah Terima Dari</div>
                            <div class="col-sm-9 " style="font-size:12pt; font-weight: 800; ">: {{dataPrint.penerima}}({{dataPrint.no_hp}})</div>
                            <div class="col-sm-3 " style=" font-size:12pt; font-weight: 800; ">Alamat</div>
                            <div class="col-sm-9 " style="font-size:12pt; font-weight: 800; ">: {{dataPrint.alamat}}</div>
                            <div class="col-sm-12 " style=" font-size:12pt; font-weight: 800; margin-top:20px ">Kami kirimkan dengan kendaraan (motor/mobil) dengan nomor polisi : {{dataPrint.nomor_driver}}</div>
                          </div>
                          <table class="table table-bordered table-condensed" style="color: #131a47; font-size:10pt !important; margin: 0px !important;">
                            <tr>
                              <th style="width: 10%; border: 1px solid black !important;">Banyak</th>
                              <th style="width: 90%; border: 1px solid black !important;">Uraian</th>
                            </tr>
                            <tr style="border: 1px solid black !important;" v-for="items in dataPrint.sub_item" :key="items.id">
                                <td style="border: 1px solid black !important;">{{items.banyak|numFormat}}</td>
                                <td style="border: 1px solid black !important; font-weight:800">{{items.nama}}</td>
                            </tr>
                          </table>
                          <h6 style="color: #131a47; font-weight: 1000; margin-bottom:10px;margin-top:5px "> 
                            Catatan :
                            {{dataPrint.catatan}}
                          </h6>
                          <div>
                            <table class="table" style="color: #131a47; font-size:10pt !important;font-size:12pt">
                              <tr style="height:100px">
                                <th style="width: 25%; border: 1px solid black !important;">
                                  Catatan:
                                  <br> Lembar 1: pengiriman
                                  <br> Lembar 2: Penerima
                                </th>
                                <th>
                                  pengirim
                                  <br>
                                  <br>
                                  <br>
                                  <br> 
                                  ___________
                                </th>
                                <th>
                                  driver
                                  <br>
                                  <br>
                                  <br>
                                  <br> 
                                  {{dataPrint.driver}}
                                </th>
                                <th>
                                  Penerima
                                  <br>
                                  <br>
                                  <br>
                                  <br>
                                  ___________
                                </th>
                              </tr>
                            </table>
                          </div>
                      </div>
                    </div>
              <div class="scrolled">
                
              </div>
            </div>
          </v-app>
        </div>
      </div>
    </section>
    </div>
</template>
<script>
import { mapGetters, mapActions } from 'vuex'
  export default {
        created() {
            this.data=[]
            this.getData();
            Fire.$on('reloaddata', () => {
              this.data=[]
              this.getData();
            })
        },
        mounted() {
          const targetElement = document.querySelector('.scrolled'); // Replace with your specific class name
          if (targetElement) {
            const options = {
              root: null,
              rootMargin: '0px',
              threshold: 0.5 // Adjust this threshold value as needed (0 to 1)
            };
            const observer = new IntersectionObserver(this.handleIntersection, options);
            observer.observe(targetElement);
          }
        },
        data() {        
            return {
              data: [],
              pagination:{
                totalPage:0,
                page:1,
                limit:100,
              },
              dataPrint: {
                id:0,
                penerima:"",
                alamat:"",
                no_hp:"",
                total_harga:13531533,
                total_terbayar:0,
                driver:"",
                nomor_driver:"",
                created_by:0,
                status_id:0,
                sales:0,
                jadi:"",
                diterima:"",
                created_at:"",
                updated_at:null,
                deleted_at:null,
                sales_data:{
                    id:0,
                    name:"",
                    desc:"",
                    created_at:"",
                    updated_at:null,
                    deleted_at:null
                },
                status:{
                    id:0,
                    name:"",
                    desc:"",
                    created_at:"",
                    updated_at:null,
                    deleted_at:null
                },
                user:{
                    id:0,
                    username:"",
                    name:"",
                    email:"",
                    created_at:"",
                    updated_at:null,
                    deleted_at:null
                },
                sub_item:[
                    {
                      id:0,
                      nama:"",
                      banyak:0,
                      desc:"",
                      type_id:"",
                      harga:0,
                      item_id:0,
                    },
                ]
              },
              search:"",
              days:0,
              until:3,
              bottom: false
            }
        },
        watch: {
          // whenever question changes, this function will run
          page() {
            this.getData()
          },
          deep: true
        },
        computed:{
            ...mapGetters({
                authenticated: 'auth/authenticated',
            }),
            page() {
              return this.pagination.page;
            }
        },
        methods: {
            getData() {
                axios.get(`order/date/${this.days}`)
                  .then(response => {   
                    this.pagination.totalpage=response.data.totalpage
                    this.loading = false
                    if (response.data.data.length === 0) {
                      this.getData()
                      console.log(0)
                    }
                    response.data.data.forEach(element => {
                      this.data.push(element)  
                    });
                    this.days = response.data.dateNow;
                  })
                  .catch(errors => {
                    this.loading = false
                    console.log(errors);
                  });
            },
            handleIntersection(entries) {
              entries.forEach(entry => {
                if (entry.isIntersecting) {
                  this.getData()
                } 
              });
            },
            TerbilangFilter(a){
              var c = " Satu Dua Tiga Empat Lima Enam Tujuh Delapan Sembilan Sepuluh Sebelas".split(" ");
                if (12 > a) var b = c[a];
                else 20 > a ? b = c[a - 10] + " Belas" : 100 > a ? (b = parseInt(String(a / 10).substr(0, 1)), b = c[b] + " Puluh " + c[a % 10]) : 200 > a ? b = "Seratus " + this.TerbilangFilter(a - 100) : 1E3 > a ? (b = parseInt(String(a / 100).substr(0, 1)), b = c[b] + " Ratus " + this.TerbilangFilter(a % 100)) : 2E3 > a ? b = "Seribu " + this.TerbilangFilter(a - 1E3) : 1E4 > a ? (b = parseInt(String(a / 1E3).substr(0, 1)), b = c[b] + " Ribu " + this.TerbilangFilter(a % 1E3)) : 1E5 > a ? (b = parseInt(String(a / 100).substr(0, 2)),
                        a %= 1E3, b = this.TerbilangFilter(b) + " Ribu " + this.TerbilangFilter(a)) : 1E6 > a ? (b = parseInt(String(a / 1E3).substr(0, 3)), a %= 1E3, b = this.TerbilangFilter(b) + " Ribu " + this.TerbilangFilter(a)) : 1E8 > a ? (b = parseInt(String(a / 1E6).substr(0, 4)), a %= 1E6, b = this.TerbilangFilter(b) + " Juta " + this.TerbilangFilter(a)) : 1E9 > a ? (b = parseInt(String(a / 1E6).substr(0, 4)), a %= 1E6, b = this.TerbilangFilter(b) + " Juta " + this.TerbilangFilter(a)) : 1E10 > a ? (b = parseInt(String(a / 1E9).substr(0, 1)), a %= 1E9, b = this.TerbilangFilter(b) + " Milyar " + this.TerbilangFilter(a)) : 1E11 > a ? (b = parseInt(String(a / 1E9).substr(0, 2)), a %= 1E9, b = this.TerbilangFilter(b) + " Milyar " + this.TerbilangFilter(a)) :
                    1E12 > a ? (b = parseInt(String(a / 1E9).substr(0, 3)), a %= 1E9, b = this.TerbilangFilter(b) + " Milyar " + this.TerbilangFilter(a)) : 1E13 > a ? (b = parseInt(String(a / 1E10).substr(0, 1)), a %= 1E10, b = this.TerbilangFilter(b) + " Triliun " + this.TerbilangFilter(a)) : 1E14 > a ? (b = parseInt(String(a / 1E12).substr(0, 2)), a %= 1E12, b = this.TerbilangFilter(b) + " Triliun " + this.TerbilangFilter(a)) : 1E15 > a ? (b = parseInt(String(a / 1E12).substr(0, 3)), a %= 1E12, b = this.TerbilangFilter(b) + " Triliun " + this.TerbilangFilter(a)) : 1E16 > a && (b = parseInt(String(a / 1E15).substr(0, 1)), a %= 1E15, b = this.TerbilangFilter(b) + " Kuadriliun " + this.TerbilangFilter(a));
                a = b.split(" ");
                c = [];
                for (b = 0; b < a.length; b++) "" != a[b] && c.push(a[b]);
                return c.join(" ")
            },
            switchData(data){
              this.dataPrint=data;
            },
            async printData(item,type) {
              await this.switchData(item);
              if (type=="invoice") {
                this.$htmlToPaper('invoicePrintField');
              }
              if (type=="kwitansi") {
                this.$htmlToPaper('kwitansiPrintField');
              }
              if (type=="surat") {
                this.$htmlToPaper('suratPrintField');
              }
            },
        },
  };
  
</script>
