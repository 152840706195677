<template>
<v-app>
    <div class="content-wrapper">
        <section class="content-header">
        <div class="container-fluid">
            <div class="row mb-2">
            <div class="col-sm-6">
                <h1>Formulir Item {{form.nama}}</h1>
            </div>
            <div class="col-sm-6">
                <ol class="breadcrumb float-sm-right">
                <li class="breadcrumb-item"><a href="#">Form</a></li>
                <li class="breadcrumb-item active">
                    <router-link :to="{ name:'hewanform' }">
                        Item
                    </router-link>
                </li>
                </ol>
            </div>
            </div>
        </div>
        </section>
        <section class="content">
        <div class="container-fluid">
            <div class="row">
            <div class="col-md-12">
                <div 
                    class="card card-primary" 
                    style="border-top: 5px solid #007bff;
                    "
                > 
                <!-- form start -->
                    <v-form @submit.prevent="saveData" ref="form" >
                        <v-card>
                            <v-row class="pa-5">
                                <v-col md="12" sm="12">
                                    <h3 class="mb-12">{{form.type}}</h3>
                                    <v-row>
                                        <v-col md="12" sm="12">
                                            <v-autocomplete
                                                v-model="customer"
                                                :items="customerData"
                                                hide-details="auto"
                                                class="mb-3"
                                                @input="changeCustomer()" 
                                                item-text="nama"
                                                item-value="id"
                                                outlined
                                                label="Pilih Customer"
                                            ></v-autocomplete>
                                        </v-col>
                                        <v-col md="6" sm="12">
                                            <v-text-field
                                                v-model="form.penerima"
                                                label="Nama Penerima"
                                                required
                                                outlined
                                                hide-details="auto"
                                                :rules="[v => !!v || 'Item is required']"
                                            ></v-text-field>
                                        </v-col>
                                        <v-col md="6" sm="12">
                                            <v-text-field
                                                v-model="form.no_hp"
                                                label="No Hp Penerima"
                                                outlined
                                                placeholder="08xxxxxxxxxx"
                                                hide-details="auto"
                                                :rules="[v => !!v || 'Item is required']"
                                            ></v-text-field>
                                        </v-col>
                                        <v-col md="12" sm="12">
                                            <v-textarea
                                                v-model="form.alamat"
                                                label="Alamat Penerima"
                                                required
                                                outlined
                                                hide-details="auto"
                                                :rules="[v => !!v || 'Item is required']"
                                            ></v-textarea>
                                        </v-col>
                                        <v-col md="4" sm="12">
                                            <v-autocomplete
                                                v-model="form.sales"
                                                :items="salesData"
                                                hide-details="auto"
                                                class="mb-3" 
                                                item-text="name"
                                                item-value="id"
                                                
                                                outlined
                                                label="Sales yang bertanggung jawab"
                                            ></v-autocomplete>
                                        </v-col>
                                        <v-col md="4" sm="12">
                                            <v-text-field
                                                v-model="form.driver"
                                                label="Nama driver"
                                                outlined
                                                hide-details="auto"
                                                :rules="[v => !!v || 'Item is required']"
                                            ></v-text-field>
                                        </v-col>
                                        <v-col md="4" sm="12">
                                            <v-text-field
                                                v-model="form.nomor_driver"
                                                label="No Kendaraan Driver"
                                                outlined
                                                hide-details="auto"
                                            ></v-text-field>
                                        </v-col>
                                        <v-col md="6" sm="12">
                                            <v-autocomplete
                                                v-model="form.status_id"
                                                :items="subStatusData"
                                                hide-details="auto"
                                                class="mb-3" 
                                                item-text="name"
                                                item-value="id"
                                                outlined
                                                label="Status"
                                                :rules="[v => !!v || 'Item is required']"
                                            ></v-autocomplete>
                                        </v-col>
                                        <v-col md="6" sm="12">
                                            <v-text-field
                                                v-model="form.total_terbayar"
                                                label="Total Terbayarkan"
                                                outlined
                                                hide-details="auto"
                                            ></v-text-field>
                                        </v-col>
                                        <v-col md="6" sm="12">
                                            <v-menu
                                                ref="terimaJam"
                                                v-model="terima.jam"
                                                :close-on-content-click="false"
                                                :nudge-right="40"
                                                :return-value.sync="form.terima.jam"
                                                transition="scale-transition"
                                                offset-y
                                                max-width="290px"
                                                min-width="290px"
                                                >
                                                <template v-slot:activator="{ on, attrs }">
                                                    <v-text-field
                                                    v-model="form.terima.jam"
                                                    label="Waktu Diterima"
                                                    prepend-icon="mdi-clock-time-four-outline"
                                                    readonly
                                                    solo
                                                    v-bind="attrs"
                                                    v-on="on"
                                                    :rules="[v => !!v || 'Item is required']"
                                                    ></v-text-field>
                                                </template>
                                                <v-time-picker
                                                    v-if="terima.jam"
                                                    v-model="form.terima.jam"
                                                    full-width
                                                    format="24hr"
                                                    @click:minute="$refs.terimaJam.save(form.terima.jam)"
                                                ></v-time-picker>
                                            </v-menu>
                                        </v-col>
                                        <v-col cols="6">
                                            <v-menu
                                            ref="terimaTanggal"
                                            v-model="terima.tanggal"
                                            :close-on-content-click="false"
                                            :return-value.sync="terima.tanggal"
                                            transition="scale-transition"
                                            offset-y
                                            min-width="auto"
                                            >
                                            <template v-slot:activator="{ on, attrs }">
                                                <v-text-field
                                                v-model="form.terima.tanggal"
                                                label="Tanggal Diterima"
                                                prepend-icon="mdi-calendar"
                                                readonly
                                                solo
                                                v-bind="attrs"
                                                v-on="on"
                                                :rules="[v => !!v || 'Item is required']"
                                                ></v-text-field>
                                            </template>
                                            <v-date-picker
                                                v-model="form.terima.tanggal"
                                                no-title
                                                scrollable
                                            >
                                                <v-spacer></v-spacer>
                                                <v-btn
                                                    text
                                                    color="primary"
                                                    @click="terima.tanggal = false"
                                                >
                                                    Cancel
                                                </v-btn>
                                                <v-btn
                                                    text
                                                    color="primary"
                                                    @click="$refs.terimaTanggal.save(form.terima.tanggal)"
                                                >
                                                    OK
                                                </v-btn>
                                            </v-date-picker>
                                            </v-menu>
                                        </v-col>
                                        
                                        <v-col md="6" sm="12">
                                            <v-menu
                                                ref="jadiJam"
                                                v-model="jadi.jam"
                                                :close-on-content-click="false"
                                                :nudge-right="40"
                                                :return-value.sync="form.jadi.jam"
                                                transition="scale-transition"
                                                offset-y
                                                max-width="290px"
                                                min-width="290px"
                                                >
                                                <template v-slot:activator="{ on, attrs }">
                                                    <v-text-field
                                                    v-model="form.jadi.jam"
                                                    label="Waktu Jadi"
                                                    prepend-icon="mdi-clock-time-four-outline"
                                                    readonly
                                                    solo
                                                    v-bind="attrs"
                                                    v-on="on"
                                                    :rules="[v => !!v || 'Item is required']"
                                                    ></v-text-field>
                                                </template>
                                                <v-time-picker
                                                    v-if="jadi.jam"
                                                    v-model="form.jadi.jam"
                                                    full-width
                                                    format="24hr"
                                                    @click:minute="$refs.jadiJam.save(form.jadi.jam)"
                                                ></v-time-picker>
                                            </v-menu>
                                        </v-col>
                                        <v-col cols="6">
                                            <v-menu
                                            ref="jadiTanggal"
                                            v-model="jadi.tanggal"
                                            :close-on-content-click="false"
                                            :return-value.sync="jadi.tanggal"
                                            transition="scale-transition"
                                            offset-y
                                            min-width="auto"
                                            >
                                            <template v-slot:activator="{ on, attrs }">
                                                <v-text-field
                                                v-model="form.jadi.tanggal"
                                                label="Tanggal Jadi"
                                                prepend-icon="mdi-calendar"
                                                readonly
                                                solo
                                                v-bind="attrs"
                                                v-on="on"
                                                :rules="[v => !!v || 'Item is required']"
                                                ></v-text-field>
                                            </template>
                                            <v-date-picker
                                                v-model="form.jadi.tanggal"
                                                no-title
                                                scrollable
                                            >
                                                <v-spacer></v-spacer>
                                                <v-btn
                                                    text
                                                    color="primary"
                                                    @click="jadi.tanggal = false"
                                                >
                                                    Cancel
                                                </v-btn>
                                                <v-btn
                                                    text
                                                    color="primary"
                                                    @click="$refs.jadiTanggal.save(form.terima.tanggal)"
                                                >
                                                    OK
                                                </v-btn>
                                            </v-date-picker>
                                            </v-menu>
                                        </v-col>
                                        <v-col md="12" sm="12">
                                            <v-textarea
                                                v-model="form.catatan"
                                                label="catatan tambahan"
                                                outlined
                                                hide-details="auto"
                                            ></v-textarea>
                                        </v-col>
                                    </v-row>
                                </v-col>

                                <v-col md="6" sm="12">
                                    <h3 class="mb-6 mt-6">Formulir Item Pesanan</h3>
                                    <v-row>
                                        <v-col md="12" sm="12">
                                            <v-autocomplete
                                                v-model="menu"
                                                :items="menuData"
                                                hide-details="auto"
                                                class="mb-3"
                                                @input="changeMenu()" 
                                                item-text="nama"
                                                item-value="id"
                                                
                                                outlined
                                                label="Pilih Menu"
                                            ></v-autocomplete>
                                        </v-col>
                                        <v-col md="12" sm="12">
                                            <v-text-field
                                                v-model="form.sub.nama"
                                                label="Nama item yang dipesan"
                                                outlined
                                                hide-details="auto"
                                            ></v-text-field>
                                        </v-col>
                                        <v-col md="12" sm="12">
                                            <v-textarea
                                                v-model="form.sub.desc"
                                                label="deskripsi item yang dipesan"
                                                outlined
                                                hide-details="auto"
                                            ></v-textarea>
                                        </v-col>
                                        <v-col md="6" sm="12">
                                            <v-text-field
                                                v-model="form.sub.harga"
                                                label="harga per item"
                                                outlined
                                                hide-details="auto"
                                            ></v-text-field>
                                        </v-col>
                                        <v-col md="6" sm="12">
                                            <v-text-field
                                                v-model="form.sub.banyak"
                                                label="banyak dipesan"
                                                outlined
                                                hide-details="auto"
                                            ></v-text-field>
                                        </v-col>
                                        <v-col md="12" sm="12">
                                            <v-autocomplete
                                                v-model="form.sub.type"
                                                :items="subTypeData"
                                                hide-details="auto"
                                                class="mb-3" 
                                                item-text="nama"
                                                item-value="id"
                                                outlined
                                                label="Type"
                                            ></v-autocomplete>
                                        </v-col>
                                        <v-col md="12" sm="12">
                                            <v-btn 
                                                block  
                                                color="blue" 
                                                class="btn btn-primary" 
                                                style="color:white;padding:27px"
                                                @click="ArrayProcess()"
                                            >
                                                Simpan Item Pesanan
                                            </v-btn>
                                        </v-col>
                                    </v-row>
                                    
                                </v-col>
                                <v-col md="6" sm="12">
                                    <h3 class="mb-6 mt-6">Daftar Item Pesanan</h3>
                                    <v-simple-table>
                                        <template v-slot:default>
                                        <thead>
                                            <tr>
                                            <th class="text-left">
                                                Nama Item
                                            </th>
                                            <th class="text-left">
                                                desc
                                            </th>
                                            <th class="text-left">
                                                harga satuan
                                            </th>
                                            <th class="text-left">
                                                Banyak
                                            </th>
                                            <th class="text-left">
                                                Action
                                            </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr
                                                v-for="(item,index) in form.sub.array_nama"
                                                :key="item"
                                            >
                                            <td>{{ form.sub.array_nama[index] }}</td>
                                            <td>{{ form.sub.array_desc[index] }}</td>
                                            <td>{{ form.sub.array_harga[index] | numFormat}}</td>
                                            <td>{{ form.sub.array_banyak[index] | numFormat}}</td>
                                            <td>
                                                <v-icon 
                                                    small 
                                                    class="mr-2"
                                                    @click="
                                                        updateSub(
                                                            form.sub.array_nama[index],
                                                            form.sub.array_harga[index],
                                                            form.sub.array_banyak[index],
                                                            form.sub.array_desc[index],
                                                            index,
                                                        )
                                                    "
                                                >
                                                    mdi-pencil
                                                </v-icon>
                                                <v-icon
                                                    small
                                                    @click="
                                                        deleteSub(
                                                            index
                                                        )
                                                    "
                                                >
                                                mdi-delete
                                                </v-icon>
                                            </td>
                                            </tr>
                                        </tbody>
                                        </template>
                                    </v-simple-table>
                                    <p class="text-right mt-6 text-bold">Total Harga :Rp. {{this.form.total_harga| numFormat}}</p>
                                </v-col>
                            </v-row>
                            <v-card-actions class="pa-5">
                                <v-btn block  color="blue" type="submit" class="btn btn-primary" style="color:white;padding:27px">Simpan Data Pesanan</v-btn>
                            </v-card-actions>
                        </v-card>
                    </v-form>
                <!-- form end -->
                </div>
            </div>
            </div>
        </div>
        </section>
        <v-overlay :value="overlay">
            <v-progress-circular
            style="z-index:999999"
            indeterminate
            size="64"
            ></v-progress-circular>
        </v-overlay>
    </div>
    </v-app>
</template>
<script>
import { mapGetters, mapActions } from 'vuex'
export default {
    data() {
        return {
            form: new Form({
                alamat: "",
                sales:null,
                created_by: null,
                driver: "",
                no_hp: "",
                catatan: "",
                nomor_driver: "",
                penerima: "",
                diterima: "",
                status_id: null,
                total_harga: 0,
                total_terbayar: null,
                type:"Form Input Data Pesanan",
                terima:{
                    jam:null,
                    tanggal:null,
                },
                jadi:{
                    jam:null,
                    tanggal:null,
                },
                sub:{
                    array_nama:[],
                    array_harga:[],
                    array_desc:[],
                    array_type:[],
                    array_banyak:[],
                    nama:'',
                    harga:'',
                    desc:'',
                    type:'',
                    banyak:'',
                    update:false,
                }
            }),
            terima:{
                jam:null,
                tanggal:null,
            },
            jadi:{
                jam:null,
                tanggal:null,
            },
            menu:null,
            customer:null,
            index:null,
            isSelecting: false,
            showPreview: true,    
            tgllahir: false,
            image:null,
            data:[],
            overlay:true,
            menuData:[],
            customerData:[],
            subCategoryData:[],
            subStatusData:[],
            subTypeData:[],
            salesData:[],
            imagePreview: `${process.env.BASE_URL}image/image.webp`,
        }
    },
    computed:{
        ...mapGetters({
            authenticated: 'auth/authenticated',
        })
    },
    
    created() {
        this.startData();

        if (this.$route.params.update) {
            
            if (this.$route.params.id !=null || JSON.parse(localStorage.getItem('key'))) {
            if (this.$route.params.id) {
                localStorage.setItem('key', JSON.stringify(this.$route.params.id))
                localStorage.setItem('update', JSON.stringify(this.$route.params.update))
            }
            let id = this.getItemData(JSON.parse(localStorage.getItem('key')));
            Fire.$on('reloaddata', () => {
                this.getItemData(id);
            })
            }else if (this.$route.params.id ==null && this.$route.params.type=="new"){
                localStorage.removeItem('key');
                this.overlay = false;
            }  
        }
        else if (this.$route.params.update==false ){
            localStorage.removeItem('key');
            localStorage.setItem('update', false)
            this.overlay = false;
        }
        else if ( !localStorage.getItem('key')&&localStorage.getItem('update')){
            localStorage.removeItem('key');
            localStorage.removeItem('update');
            this.overlay = false;
        }
        else {
            this.getItemData(JSON.parse(localStorage.getItem('key')));
        }
     },
    methods: {
        changeCustomer(){
            this.overlay = true
            axios.get(`/customer/show/${this.customer}`)
                .then((response) => {    
                    this.form.penerima = response.data.data.nama;
                    this.form.alamat = response.data.data.alamat;
                    this.form.no_hp = response.data.data.phone;
                    this.overlay = false
                })
                .catch(errors => {
                    console.log(errors);
                    this.overlay = false                
        });
        },
        changeMenu(){
            this.overlay = true
            axios.get(`/menu/show/${this.menu}`)
                .then((response) => {    
                    this.form.sub.nama = response.data.data.nama;
                    this.form.sub.desc = response.data.data.desc;
                    this.form.sub.harga = response.data.data.harga;
                    this.overlay = false
                })
                .catch(errors => {
                    console.log(errors);
                    this.overlay = false
                });
        },
        reset(){
            this.form.name=null
            this.form.src=null
            this.form.created_by=null
            this.form.category=null
            this.form.status=null                               
            this.isSelecting= false
            this.showPreview= true    
            this.tgllahir= false
            this.image=null
            this.overlay = true;
            this.imagePreview= `${process.env.BASE_URL}image/image.webp`
        },
        updateSub(nama,harga,banyak,desc,index){
            this.form.sub.nama = nama;
            this.form.sub.banyak = banyak;
            this.form.sub.desc = desc;
            this.form.sub.harga = harga;
            this.form.sub.type = type;
            this.form.sub.update = true;
            this.index = index;
        },
        deleteSub(index){
            this.form.total_harga = this.form.total_harga-(this.form.sub.array_banyak[index]*this.form.sub.array_harga[index]);
            this.form.sub.array_nama.splice(index,1);
            this.form.sub.array_desc.splice(index,1);
            this.form.sub.array_banyak.splice(index,1);
            this.form.sub.array_type.splice(index,1);
            this.form.sub.array_harga.splice(index,1);
        },
        ArrayProcess(){
            if ( this.form.sub.update==false) {
                console.log("update false")
                this.form.sub.array_nama.push(this.form.sub.nama);
                this.form.sub.array_desc.push(this.form.sub.desc);
                this.form.sub.array_banyak.push(this.form.sub.banyak);
                this.form.sub.array_type.push(this.form.sub.type);
                this.form.sub.array_harga.push(this.form.sub.harga);
                this.form.total_harga = parseInt(this.form.total_harga)+(this.form.sub.harga*this.form.sub.banyak);
            }
            if ( this.form.sub.update==true) {
                console.log("update true")
                this.form.sub.array_nama.splice(this.index,1,this.form.sub.nama);
                this.form.sub.array_desc.splice(this.index,1,this.form.sub.desc);
                this.form.sub.array_banyak.splice(this.index,1,this.form.sub.banyak);
                this.form.sub.array_type.splice(this.index,1,this.form.sub.type);
                this.form.sub.array_harga.splice(this.index,1,this.form.sub.harga);
                this.form.total_harga = this.form.total_harga-(this.form.sub.array_banyak[this.index]*this.form.sub.array_harga[this.index]);
                
                this.form.total_harga = parseInt(this.form.total_harga)+(this.form.sub.harga*this.form.sub.banyak);
            }
            this.form.sub.nama = null;
            this.form.sub.desc = null;
            this.form.sub.banyak = null;
            this.form.sub.harga = null;
        },
        getItemData(id){
            axios.get(`/show/${id}`)
               .then((response) => {    
                    this.form.type="Form update Data";
                    this.form.penerima = response.data.data.penerima;
                    this.form.alamat = response.data.data.alamat;
                    this.form.catatan = response.data.data.catatan;
                    this.form.diterima = response.data.data.diterima;
                    this.form.driver = response.data.data.driver
                    this.form.terima.tanggal = this.$moment(response.data.data.waktu_dipesan).format('YYYY-MM-DD');
                    this.form.terima.jam = this.$moment(response.data.data.waktu_dipesan).format('HH:mm');
                    this.form.jadi.tanggal = this.$moment(response.data.data.jadi).format('YYYY-MM-DD');
                    this.form.jadi.jam = this.$moment(response.data.data.jadi).format('HH:mm');
                    this.form.no_hp = response.data.data.no_hp
                    this.form.nomor_driver = response.data.data.nomor_driver
                    this.form.penerima  = response.data.data.penerima
                    this.form.sales  = parseInt(response.data.data.sales)
                    this.form.status_id = parseInt(response.data.data.status_id)
                    this.form.total_harga = response.data.data.total_harga
                    this.form.total_terbayar = response.data.data.total_terbayar

                    let sub= response.data.data.sub_item 
                    sub.forEach(element => {
                        this.form.sub.array_nama.push(element.nama);
                        this.form.sub.array_desc.push(element.desc);
                        this.form.sub.array_type.push(element.type_id);
                        this.form.sub.array_banyak.push(element.banyak);
                        this.form.sub.array_harga.push(element.harga);
                    });
               })
               .catch(errors => {
                   console.log(errors);
               });
            this.showPreview= true;
        },
        startData(){
            axios.get(`customer`)
                .then((response) => {    
                    this.customerData = response.data.data;
                    this.overlay=false;
                })
                .catch(errors => {
                    console.log(errors);
                    this.overlay=false;
                });
            axios.get(`sales/list`)
                .then((response) => {    
                    this.salesData = response.data.data;
                    this.overlay=false;
                })
                .catch(errors => {
                    console.log(errors);
                    this.overlay=false;
                });
            axios.get(`menu`)
                .then((response) => {    
                    this.menuData = response.data.data;
                    this.overlay=false;
                })
                .catch(errors => {
                    console.log(errors);
                    this.overlay=false;
                });
            axios.get(`status`)
                .then((response) => {    
                    this.subStatusData = response.data.data;
                    this.overlay=false;
                })
                .catch(errors => {
                    console.log(errors);
                    this.overlay=false;
                });
            axios.get(`type`)
                .then((response) => {    
                    this.subTypeData = response.data.data;
                    this.overlay=false;
                })
                .catch(errors => {
                    console.log(errors);
                    this.overlay=false;
                });      
        },
        onButtonClick() {
            this.isSelecting = true
            window.addEventListener('focus', () => {
                this.isSelecting = false
            }, { once: true })

            this.$refs.uploader.click()
        },
        onFileChange(e) {    
           this.form.src = e.target.files[0];
           let reader  = new FileReader();

           reader.addEventListener("load", function () {
               this.showPreview = true;
                this.imagePreview = reader.result;
            }.bind(this), false);

            if( this.form.src ){
                if ( /\.(jpe?g|png|gif)$/i.test( this.form.src.name ) ) {
                    console.log("here");
                    reader.readAsDataURL( this.form.src);
                }
            }
        },
        selectFile(event) {
           this.form.src = event.target.files[0];
        },
        
        async saveData(){
            this.$refs.form.validate();
            let data = new FormData();
            console.log(this.form.src);
            if (this.form.src!=null) {
                data.append('item_img',this.form.src)   
            }
            let dipesan = this.$moment(this.form.terima.tanggal).format('YYYY-M-D')+' '+this.form.terima.jam;
            let jadi = this.$moment(this.form.jadi.tanggal).format('YYYY-M-D')+' '+this.form.jadi.jam;
            data.append('created_by',this.authenticated.user.id)
            data.append('penerima',this.form.penerima)
            data.append('alamat',this.form.alamat)
            data.append('catatan',this.form.catatan)
            data.append('jadi',jadi)
            data.append('diterima',this.form.diterima)
            data.append('driver',this.form.driver)
            data.append('no_hp',this.form.no_hp)
            data.append('nomor_driver',this.form.nomor_driver)
            data.append('penerima',this.form.penerima)
            data.append('status_id',this.form.status_id)
            data.append('sales',this.form.sales)
            data.append('total_harga',this.form.total_harga)
            data.append('total_terbayar',this.form.total_terbayar)
            data.append('waktu_dipesan',dipesan)
            data.append('sub_nama',this.form.sub.array_nama)
            data.append('sub_banyak',this.form.sub.array_banyak)
            data.append('sub_type',this.form.sub.array_type)
            data.append('sub_harga',this.form.sub.array_harga)
            data.append('sub_desc',this.form.sub.array_desc)

            if (this.$route.params.id!=null) {
                // data.append('_method', 'PUT')
                axios.post(`item/update/${this.$route.params.id}`,data).then((res)=>{
                    if (res.data.status == true) {   
                        this.$fire({
                            title: "Success",
                            text: `apakah ingin kembali ke tabel Item?`,
                            type: "question",
                            showCancelButton: true,
                            confirmButtonColor: '#3085d6',
                            cancelButtonColor: '#d33',
                            confirmButtonText: 'Yes'
                        }).then(r => {
                            if (r.value==true) {
                                this.$router.push({name:'item'})
                            }else{
                                this.reset()
                            }
                        });   
                    }else{
                        this.overlay = false;
                        this.$fire({
                        title: "Error",
                        text: res.data.message,
                        type: "error",
                        showCancelButton: true,
                        }).then(r => {
                            
                        });   
                    }        
               })   
            }
            else{
               axios.post(`item/add`,data).then((res)=>{
                    if (res.data.status == true) {   
                        this.$fire({
                            title: "Success",
                            text: `apakah ingin kembali ke tabel Item?`,
                            type: "question",
                            showCancelButton: true,
                            confirmButtonColor: '#3085d6',
                            cancelButtonColor: '#d33',
                            confirmButtonText: 'Yes'
                        }).then(r => {
                            if (r.value==true) {
                                this.$router.push({name:'item'})
                            }else{
                                this.reset()
                            }
                        });  
                    }else{
                        this.overlay = false;
                        this.$fire({
                        title: "Error",
                        text: res.data.message,
                        type: "error",
                        showCancelButton: true,
                        }).then(r => {
                            
                        });   
                    }         
               })                                
               }
        },
    },
} 
</script>