import './bootstrap'
import Vue from 'vue'
import router from './router'
import VueAxios from 'vue-axios';
import Axios from 'axios';
import Form from './plugins/form';
import VueSimpleAlert from "vue-simple-alert";
import vuetify from './plugins/vuetify';
import PushMenu from './plugins/PushMenu';
import CardWidget from './plugins/CardWidget';
import ControlSidebar from './plugins/ControlSidebar';
import Expandable from './plugins/ExpandableTable';
import Treeview from './plugins/Treeview';
import vSelect from "vue-select";
import VueTelInput from 'vue-tel-input'
import store from "./store/index.js";
import VueTelInputVuetify from 'vue-tel-input-vuetify/lib';
import numeral from 'numeral';
import numFormat from 'vue-filter-number-format';
import App from './App.vue'
import swal from 'sweetalert2';
import VueHtmlToPaper from 'vue-html-to-paper';
import VueGoogleCharts from 'vue-google-charts';
import VueMasonry from 'vue-masonry-css';
import { VueEditor } from "vue2-editor";
import EmptyCheck from "./plugins/EmptyCheck";
import terbilang from "./plugins/terbilang-vue.js";
import VueClipboard from 'vue-clipboard2'
import './stylus/main.styl'
import 'bootstrap/dist/js/bootstrap'
import 'admin-lte/dist/js/adminlte'
require('@/store/subscriber');
axios.defaults.baseURL = 'http://kotakanbackend.resepedia.net/api/'
    // axios.defaults.baseURL = 'http://127.0.0.1:8000/api/'

store.dispatch('auth/attempt', localStorage.getItem('token')).then(() => {

    const options = {
        name: '_blank',
        specs: [
            'fullscreen=yes',
            'titlebar=yes',
            'scrollbars=yes'
        ],
        styles: [
            'https://stackpath.bootstrapcdn.com/bootstrap/4.3.1/css/bootstrap.min.css',
            'https://unpkg.com/kidlat-css/css/kidlat.css',
            'http://kotakanbackend.resepedia.net/print.css'
        ]
    }

    Vue.router = router;
    Vue.use(VueMasonry);
    Vue.use(VueTelInputVuetify, { vuetify, });
    Vue.use(VueSimpleAlert);
    Vue.component("v-select", vSelect);
    Vue.use(require('vue-moment'));
    Vue.use(Axios, VueAxios);
    Vue.use(VueTelInput);
    Vue.use(VueGoogleCharts)
    Vue.use(VueHtmlToPaper, options);
    Vue.use(Axios, VueAxios);
    Vue.use(VueClipboard);
    Vue.filter('numFormat', numFormat(numeral));
    window.Form = Form;
    window.swal = swal;
    window.Fire = new Vue();
    window.$ = jQuery


    Vue.config.productionTip = false
    new Vue({
        icons: {
            iconfont: 'md' || 'fa'
        },
        components: {
            VueEditor
        },
        vuetify,
        Treeview,
        CardWidget,
        ControlSidebar,
        PushMenu,
        Expandable,
        EmptyCheck,
        terbilang,
        router,
        store,
        render: h => h(App)
    }).$mount('#app')
})