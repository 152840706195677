import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '@/store'
import axios from 'axios'

import UserTable from '../views/UserTable.vue'
import ItemTable from '../views/ItemTable.vue'
import RekapPesanan from '../views/RekapPesanan.vue'
import RekapCustomer from '../views/RekapCustomer.vue'
import ItemCard from '../views/ItemCard.vue'
import StatusTable from '../views/StatusTable.vue'
import CustomerTable from '../views/CustomerTable.vue'
import MenuTable from '../views/MenuTable.vue'
import HomeSuper from '../views/HomeSuper.vue'
import HomeAdmin from '../views/HomeAdmin.vue'
import HomeSales from '../views/HomeSales.vue'
import Login from '../views/Login.vue'
import ItemForm from '../views/forms/ItemForm.vue'

import NotFound from '../views/404.vue';
Vue.use(VueRouter)

const routes = [{
        path: '/',
        name: 'login',
        mode: 'history',
        component: Login,
        beforeEnter: (to, from, next) => {
            if (store.getters['auth/authenticated']) {
                if (store.getters['auth/authenticated'].user.type == 1) {
                    return next({
                        name: 'homesuper'
                    })
                }
                if (store.getters['auth/authenticated'].user.type == 2) {
                    return next({
                        name: 'homeadmin'
                    })
                }
                if (store.getters['auth/authenticated'].user.type == 3) {
                    return next({
                        name: 'homesales'
                    })
                }
            }

            next()
        }
    },
    {
        path: '/super',
        name: 'homesuper',
        mode: 'history',
        component: HomeSuper,
        beforeEnter: (to, from, next) => {

            if (!store.getters['auth/authenticated']) {
                store.dispatch('auth/signOut').then(() => {
                    return next({
                        name: 'login'
                    })
                })
            }
            if (!localStorage.getItem('token')) {
                return next({
                    name: 'login'
                })
            }
            if (!store.getters['auth/authenticated'] && !localStorage.getItem('token')) {
                return next({
                    name: 'login'
                })
            }
            if (store.getters['auth/authenticated'].user.type == 2) {
                return next({
                    name: 'homeadmin'
                })
            }
            if (store.getters['auth/authenticated'].user.type == 3) {
                return next({
                    name: 'homesales'
                })
            }


            next()
        }
    },
    {
        path: '/home',
        name: 'homeadmin',
        mode: 'history',
        component: HomeAdmin,
        beforeEnter: (to, from, next) => {
            if (!store.getters['auth/authenticated']) {
                store.dispatch('auth/signOut').then(() => {
                    return next({
                        name: 'login'
                    })
                })
            }
            if (!localStorage.getItem('token')) {
                return next({
                    name: 'login'
                })
            }
            if (!store.getters['auth/authenticated'] && !localStorage.getItem('token')) {
                return next({
                    name: 'login'
                })
            }
            if (store.getters['auth/authenticated'].user.type == 1) {
                return next({
                    name: 'homesuper'
                })
            }
            if (store.getters['auth/authenticated'].user.type == 3) {
                return next({
                    name: 'homesales'
                })
            }


            next()
        }
    },
    {
        path: '/sales',
        name: 'homesales',
        mode: 'history',
        component: HomeSales,
        beforeEnter: (to, from, next) => {

            if (!store.getters['auth/authenticated']) {
                store.dispatch('auth/signOut').then(() => {
                    return next({
                        name: 'login'
                    })
                })
            }
            if (!localStorage.getItem('token')) {
                return next({
                    name: 'login'
                })
            }
            if (!store.getters['auth/authenticated'] && !localStorage.getItem('token')) {
                return next({
                    name: 'login'
                })
            }
            if (store.getters['auth/authenticated'].user.type == 1) {
                return next({
                    name: 'homesuper'
                })
            }
            if (store.getters['auth/authenticated'].user.type == 2) {
                return next({
                    name: 'homeadmin'
                })
            }


            next()
        }
    },
    {
        path: '/user',
        name: 'user',
        mode: 'history',
        component: UserTable,
        beforeEnter: (to, from, next) => {
            if (!store.getters['auth/authenticated']) {
                store.dispatch('auth/signOut').then(() => {
                    return next({
                        name: 'login'
                    })
                })
            }
            if (!localStorage.getItem('token')) {
                return next({
                    name: 'login'
                })
            }
            if (!store.getters['auth/authenticated'] && !localStorage.getItem('token')) {
                return next({
                    name: 'login'
                })
            }

            next()
        }

    },

    {
        path: '/item',
        name: 'item',
        mode: 'history',
        component: ItemTable,
        beforeEnter: (to, from, next) => {
            if (!store.getters['auth/authenticated']) {
                store.dispatch('auth/signOut').then(() => {
                    return next({
                        name: 'login'
                    })
                })
            }
            if (!localStorage.getItem('token')) {
                return next({
                    name: 'login'
                })
            }
            if (!store.getters['auth/authenticated'] && !localStorage.getItem('token')) {
                return next({
                    name: 'login'
                })
            }

            next()
        },
    },
    {
        path: '/itemCard',
        name: 'itemCard',
        mode: 'history',
        component: ItemCard,
        beforeEnter: (to, from, next) => {
            if (!store.getters['auth/authenticated']) {
                store.dispatch('auth/signOut').then(() => {
                    return next({
                        name: 'login'
                    })
                })
            }
            if (!localStorage.getItem('token')) {
                return next({
                    name: 'login'
                })
            }
            if (!store.getters['auth/authenticated'] && !localStorage.getItem('token')) {
                return next({
                    name: 'login'
                })
            }

            next()
        }
    },

    {
        path: '/rekapPesanan',
        name: 'rekapPesanan',
        mode: 'history',
        component: RekapPesanan,
        beforeEnter: (to, from, next) => {
            if (!store.getters['auth/authenticated']) {
                store.dispatch('auth/signOut').then(() => {
                    return next({
                        name: 'login'
                    })
                })
            }
            if (!localStorage.getItem('token')) {
                return next({
                    name: 'login'
                })
            }
            if (!store.getters['auth/authenticated'] && !localStorage.getItem('token')) {
                return next({
                    name: 'login'
                })
            }

            next()
        }
    },

    {
        path: '/rekapCustomer',
        name: 'rekapCustomer',
        mode: 'history',
        component: RekapCustomer,
        beforeEnter: (to, from, next) => {
            if (!store.getters['auth/authenticated']) {
                store.dispatch('auth/signOut').then(() => {
                    return next({
                        name: 'login'
                    })
                })
            }
            if (!localStorage.getItem('token')) {
                return next({
                    name: 'login'
                })
            }
            if (!store.getters['auth/authenticated'] && !localStorage.getItem('token')) {
                return next({
                    name: 'login'
                })
            }

            next()
        }
    },

    {
        path: '/status',
        name: 'status',
        mode: 'history',
        component: StatusTable,
        beforeEnter: (to, from, next) => {
            if (!store.getters['auth/authenticated']) {
                store.dispatch('auth/signOut').then(() => {
                    return next({
                        name: 'login'
                    })
                })
            }
            if (!localStorage.getItem('token')) {
                return next({
                    name: 'login'
                })
            }
            if (!store.getters['auth/authenticated'] && !localStorage.getItem('token')) {
                return next({
                    name: 'login'
                })
            }

            next()
        }
    },
    {
        path: '/customer',
        name: 'customer',
        mode: 'history',
        component: CustomerTable,
        beforeEnter: (to, from, next) => {
            if (!store.getters['auth/authenticated']) {
                store.dispatch('auth/signOut').then(() => {
                    return next({
                        name: 'login'
                    })
                })
            }
            if (!localStorage.getItem('token')) {
                return next({
                    name: 'login'
                })
            }
            if (!store.getters['auth/authenticated'] && !localStorage.getItem('token')) {
                return next({
                    name: 'login'
                })
            }

            next()
        }
    },
    {
        path: '/menu',
        name: 'menu',
        mode: 'history',
        component: MenuTable,
        beforeEnter: (to, from, next) => {
            if (!store.getters['auth/authenticated']) {
                store.dispatch('auth/signOut').then(() => {
                    return next({
                        name: 'login'
                    })
                })
            }
            if (!localStorage.getItem('token')) {
                return next({
                    name: 'login'
                })
            }
            if (!store.getters['auth/authenticated'] && !localStorage.getItem('token')) {
                return next({
                    name: 'login'
                })
            }

            next()
        }
    },

    {
        path: '/itemform',
        name: 'itemform',
        mode: 'history',
        component: ItemForm,
        beforeEnter: (to, from, next) => {
            if (!store.getters['auth/authenticated']) {
                store.dispatch('auth/signOut').then(() => {
                    return next({
                        name: 'login'
                    })
                })
            }
            if (!localStorage.getItem('token')) {
                return next({
                    name: 'login'
                })
            }
            if (!store.getters['auth/authenticated'] && !localStorage.getItem('token')) {
                return next({
                    name: 'login'
                })
            }

            next()
        }
    },
    {
        path: '/404',
        name: '404',
        mode: 'history',
        component: NotFound,
    },

]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes,
    scrollBehavior(to, from, savedPosition) {
        return { x: 0, y: 0 }
    }
})

export default router