<template>
  <v-app>
    <div class="wrapper" style="width:100%">
      <div class="content-wrapper">
        <section class="content">
          <div class="container-fluid">
            <!-- Upper Card Start Here -->
              <!-- Super Admin Upper Card Start -->
              <div class="row">
                <div class="col-lg-4 col-sm-12">
                  <div class="small-box blue darken-2 white--text">
                    <div class="inner">
                      <p>Seluruh Pesanan </p>
                      <h3>{{orderTotal|numFormat}}</h3>
                    </div>
                    <div class="icon">
                      <i class="fas fa-box"></i>
                    </div>
                  </div>
                </div>
                <div class="col-lg-4 col-sm-12">
                  <div class="small-box green darken-2 white--text">
                    <div class="inner">
                      <p>Pesanan Selesai</p>
                      <h3>{{orderDone|numFormat}}</h3>
                    </div>
                    <div class="icon">
                      <i class="fas fa-box"></i>
                    </div>
                  </div>
                </div>
                <div class="col-lg-4 col-sm-12">
                  <div class="small-box orange darken-2 white--text">
                    <div class="inner">
                      <p>Pesanan Berjalan</p>
                      <h3>{{orderRunning|numFormat}}</h3>
                    </div>
                    <div class="icon">
                      <i class="fas fa-box"></i>
                    </div>
                  </div>
                </div>
                <div class="col-lg-4 col-sm-12">
                  <div class="small-box blue darken-2 white--text">
                    <div class="inner">
                      <p>Seluruh Kotak Dipesan </p>
                      <h3>{{boxTotal|numFormat}}</h3>
                    </div>
                    <div class="icon">
                      <i class="fas fa-box"></i>
                    </div>
                  </div>
                </div>
                <div class="col-lg-4 col-sm-12">
                  <div class="small-box green darken-2 white--text">
                    <div class="inner">
                      <p>Kotak Selesai</p>
                      <h3>{{boxDone|numFormat}}</h3>
                    </div>
                    <div class="icon">
                      <i class="fas fa-box"></i>
                    </div>
                  </div>
                </div>
                <div class="col-lg-4 col-sm-12">
                  <div class="small-box orange darken-2 white--text">
                    <div class="inner">
                      <p>kotak Berjalan</p>
                      <h3>{{boxRunning|numFormat}}</h3>
                    </div>
                    <div class="icon">
                      <i class="fas fa-box"></i>
                    </div>
                  </div>
                </div>
                <div class="col-lg-4 col-sm-12">
                  <div class="small-box blue darken-2 white--text">
                    <div class="inner">
                      <p>Pendapatan Total</p>
                      <h3>Rp. {{profitTotal|numFormat}}</h3>
                    </div>
                    <div class="icon">
                      <i class="fas fa-box"></i>
                    </div>
                  </div>
                </div>
                <div class="col-lg-4 col-sm-12">
                  <div class="small-box green darken-2 white--text">
                    <div class="inner">
                      <p>Pendapatan Lunas</p>
                      <h3>Rp. {{profitDone|numFormat}}</h3>
                    </div>
                    <div class="icon">
                      <i class="fas fa-box"></i>
                    </div>
                  </div>
                </div>
                <div class="col-lg-4 col-sm-12">
                  <div class="small-box orange darken-2 white--text">
                    <div class="inner">
                      <p>Pendapatan Berjalan</p>
                      <h3>Rp. {{profitRunning|numFormat}}</h3>
                    </div>
                    <div class="icon">
                      <i class="fas fa-box"></i>
                    </div>
                  </div>
                </div>
                <div class="col-lg-6 col-sm-12">
                  <div class="small-box green darken-2 white--text">
                    <div class="inner overflow-hidden">
                      <p>Client Dengan Pemesanan Terbesar</p>
                      <h1><b>{{clientWithBigestOrder}}</b></h1>
                      <p>Rp.{{clientWithBiggestOrderMoney|numFormat}}</p>
                    </div>
                    <div class="icon">
                      <i class="fas fa-box"></i>
                    </div>
                  </div>
                </div>
                <div class="col-lg-6 col-sm-12">
                  <div class="small-box green darken-2 white--text">
                    <div class="inner overflow-hidden">
                      <p>Client Dengan Pemesanan Tersering</p>
                      <h1><b>{{clientWithBigestOrder}}</b></h1>
                      <p>{{clientWithMostOrderCount|numFormat}} box</p>
                    </div>
                    <div class="icon">
                      <i class="fas fa-box"></i>
                    </div>
                  </div>
                </div>
              </div>
              <!-- Super Admin Upper Card End -->
            <!-- Upper Card End Here -->
            <div class="row" >
                <div class="connectedSortable col-md-4">
                  <v-card>
                    
                    <v-toolbar
                      color="orange darken-2"
                      dark
                    >
                    
                      <v-toolbar-title>Pesanan terbaru</v-toolbar-title>
                      
                      <v-spacer></v-spacer>

                      <v-btn icon>
                        <router-link class="white--text" :to="{ name:'item' }">
                          <v-icon>mdi-magnify</v-icon>
                        </router-link>
                      </v-btn>
                    
                    </v-toolbar>

                    <v-list>
                        <v-list-item-group >
                          <v-list-item>
                            <v-list-item-content style="0px 0px!important">
                              <v-list-item-subtitle>pelanggan</v-list-item-subtitle>
                            </v-list-item-content>
                               <v-list-item-action>
                                  <v-list-item-subtitle>Total Harga</v-list-item-subtitle>
                                </v-list-item-action>
                            </v-list-item>
                          <template v-for="(item, index) in order">
                            <v-tooltip bottom>
                              <template v-slot:activator="{ on, attrs }">
                                <v-list-item :key="item.id" v-bind="attrs"
                                  v-on="on">
                                  <v-list-item-content style="0px 0px!important">
                                    <v-list-item-subtitle>{{item.penerima}}</v-list-item-subtitle>
                                    <v-list-item-subtitle>{{item.status.name}}</v-list-item-subtitle>
                                    
                                  </v-list-item-content>
                                  <v-list-item-action>
                                    <v-list-item-subtitle>Rp. {{item.total_harga|numFormat}}</v-list-item-subtitle>
                                  </v-list-item-action>
                                </v-list-item>
                              </template>
                              <span>{{item.penerima}}</span>
                            </v-tooltip>
                            <v-divider
                              v-if="index < item.length - 1"
                              :key="index"
                            ></v-divider>
                          </template>
                        </v-list-item-group>
                      </v-list>

                  </v-card>
                </div>

                <div class="connectedSortable col-md-4">
                  <v-card>
                    <v-toolbar
                      color="blue darken-2"
                      dark
                    >
                      <v-toolbar-title>Pelanggan Pengorder Terbesar</v-toolbar-title>
                      <v-spacer></v-spacer>
                      <v-btn icon>
                        <router-link class="white--text" :to="{ name:'item' }">
                          <v-icon>mdi-magnify</v-icon>
                        </router-link>
                      </v-btn>
                    </v-toolbar>
                    <v-list>
                        <v-list-item-group >
                          <v-list-item>
                            <v-list-item-content style="0px 0px!important">
                              <v-list-item-subtitle>pelanggan</v-list-item-subtitle>
                            </v-list-item-content>
                               <v-list-item-action>
                                  <v-list-item-subtitle>Total Harga</v-list-item-subtitle>
                                </v-list-item-action>
                            </v-list-item>
                          <template v-for="(item, index) in bigestOrderClient">
                            <v-tooltip bottom>
                              <template v-slot:activator="{ on, attrs }">
                                <v-list-item :key="item.id" v-bind="attrs"
                                  v-on="on">
                                  <v-list-item-content style="0px 0px!important">
                                    <v-list-item-subtitle>{{item.penerima}}</v-list-item-subtitle>
                                    
                                  </v-list-item-content>
                                  <v-list-item-action>
                                    <v-list-item-subtitle>Rp. {{item.profit_total|numFormat}}</v-list-item-subtitle>
                                  </v-list-item-action>
                                </v-list-item>
                              </template>
                              <span>{{item.penerima}}</span>
                            </v-tooltip>
                            <v-divider
                              v-if="index < item.length - 1"
                              :key="index"
                            ></v-divider>
                          </template>
                        </v-list-item-group>
                      </v-list>
                  </v-card>
                </div>

                <div class="connectedSortable col-md-4">
                  <v-card>
                    <v-toolbar
                      color="green darken-2"
                      dark
                    >
                      <v-toolbar-title>Pelanggan Dengan Order Tersering</v-toolbar-title>
                      <v-spacer></v-spacer>
                      <v-btn icon>
                        <router-link class="white--text" :to="{ name:'item' }">
                          <v-icon>mdi-magnify</v-icon>
                        </router-link>
                      </v-btn>
                    </v-toolbar>
                    <v-list>
                        <v-list-item-group >
                          <v-list-item>
                            <v-list-item-content style="0px 0px!important">
                              <v-list-item-subtitle>pelanggan</v-list-item-subtitle>
                            </v-list-item-content>
                               <v-list-item-action>
                                  <v-list-item-subtitle>Total order</v-list-item-subtitle>
                                </v-list-item-action>
                            </v-list-item>
                          <template v-for="(item, index) in mostOrderClient">
                            <v-tooltip bottom>
                              <template v-slot:activator="{ on, attrs }">
                                <v-list-item :key="item.id" v-bind="attrs"
                                  v-on="on">
                                  <v-list-item-content style="0px 0px!important">
                                    <v-list-item-subtitle>{{item.penerima}}</v-list-item-subtitle>
                                    <v-list-item-subtitle>Pesanan Terakhir: {{item.created_at| moment("D/M/YYYY")}}</v-list-item-subtitle>
                                  </v-list-item-content>
                                  <v-list-item-action>
                                    <v-list-item-subtitle>{{item.id}} kali</v-list-item-subtitle>
                                  </v-list-item-action>
                                </v-list-item>
                              </template>
                              <span>{{item.penerima}}</span>
                            </v-tooltip>
                            <v-divider
                              v-if="index < item.length - 1"
                              :key="index"
                            ></v-divider>
                          </template>
                        </v-list-item-group>
                      </v-list>
                  </v-card>
                </div>         
            </div>
            <div class="row" >
              <div class="col-md-12">
                <v-card>
                  <v-card-title>
                    <span class="text-h6 font-weight-light">Pesanan Bulan ini</span>
                  </v-card-title>
                  <GChart
                    type="ColumnChart"
                    :data="monthOrderChartData"
                    :options="chartOptions"
                  />       
                </v-card>
              </div>
              <div class="col-md-6">
                <v-card>
                  <v-card-title>
                    <span class="text-h6 font-weight-light">Pesanan Tahun ini</span>
                  </v-card-title>
                  <GChart
                    type="ColumnChart"
                    :data="yearOrderChartData"
                    :options="chartOptions"
                  />       
                </v-card>
              </div>
              <div class="col-md-6">
                <v-card>
                  <v-card-title>
                    <span class="text-h6 font-weight-light">Pesanan Minggu ini</span>
                  </v-card-title>
                  <GChart
                    type="ColumnChart"
                    :data="weekOrderChartData"
                    :options="chartOptions"
                  />       
                </v-card>
              </div>
            </div>
            
            
          </div>

            

        </section>
      </div>
      
      <v-overlay :value="overlay">
        <v-progress-circular
          style="z-index:999999"
          indeterminate
          size="64"
        ></v-progress-circular>
      </v-overlay>
      
    </div>
  </v-app>
</template>
<style>
  .v-sheet--offset {
    top: -24px;
    position: relative;
  }
</style>
<script>
  import { mapGetters, mapActions } from 'vuex'
  import { GChart } from 'vue-google-charts'
  export default {
    components: {
      GChart
    },
    data() {        
            return {
              modal: {
                typeData:0,
                data:null
              },
              GraphPlaceHolder: "pesanan",
              data: [],
              orderGraph:[],
              order:[],
              bigestOrderClient:[],
              mostOrderClient:[],
              user:[],
              overlay: true,
              dialog: false,
              orderTotal:0,
              orderDone:0,
              orderRunning:0,
              boxTotal:0,
              boxDone:0,
              boxRunning:0,
              profitTotal:0,
              profitDone:0,
              profitRunning:0,
              clientWithBigestOrder:"",
              clientWithBigestOrderMoney:0,
              clientWithMostOrder:"",
              clientWithMostOrderCount:0,
              year:2020,
              labels: [],
              value: [],
              imagePreview: `${process.env.BASE_URL}image/image.webp`,
              yearOrderChartData:[
                ['Bulan', 'Pesanan'],
              ],
              monthOrderChartData:[
                ['Tanggal', 'Pesanan'],
              ],
              weekOrderChartData:[
                ['Hari', 'Pesanan'],
              ],
            }
          },
    created() {
                this.getData();
                Fire.$on('reloaddata', () => {
                  this.getData();
                })
            },
    computed:{
              ...mapGetters({
                  authenticated: 'auth/authenticated',
              })
          },
    methods: {
            imageNull(event) { 
                event.target.src = this.imagePreview 
            },
            getModalData(type,id){
              this.overlay = true
              this.modal.typeData =type
              if (this.modal.typeData==1) {
                axios.get(`item/${id}`)
                .then(response => {     
                    this.modal.data=response.data.data
                    this.dialog=true
                    this.overlay = false
                })
                .catch(errors => {
                    console.log(errors);
                });
              }
              if (this.modal.typeData==2) {
                axios.get(`user/${id}`)
                .then(response => {     
                    this.modal.data=response.data.data
                    this.dialog=true
                    this.overlay = false
                })
                .catch(errors => {
                    console.log(errors);
                });
                
              }
            },
            getData() {
                axios.get(`item/latest`)
                    .then(response => {     
                        this.order = response.data.data;
                        this.bigestOrderClient = response.data.biggest_order;
                        this.mostOrderClient = response.data.most_order;
                        this.orderGraph = response.data.data;
                        this.orderTotal= response.data.order_total;
                        this.orderDone= response.data.order_done;
                        this.orderRunning= response.data.order_running;
                        this.boxTotal = response.data.box_total;
                        this.boxDone = response.data.box_done;
                        this.boxRunning = response.data.box_running;
                        this.profitTotal= response.data.profit_total;
                        this.profitDone=  response.data.profit_done;
                        this.profitRunning= response.data.profit_running;
                        this.clientWithBigestOrder=response.data.biggest_order[0].penerima;
                        this.clientWithBiggestOrderMoney=response.data.biggest_order[0].profit_total;
                        this.clientWithMostOrder=response.data.most_order[0].penerima;
                        this.clientWithMostOrderCount=response.data.most_order[0].total_kuantitas_dipesan;
                    })
                    .catch(errors => {
                        console.log(errors);
                    });
                axios.get(`graph/item/year`)
                    .then(response => {     
                        this.yearOrderChartData = response.data.data;
                    })
                    .catch(errors => {
                        console.log(errors);
                    });
                axios.get(`graph/item/month`)
                    .then(response => {     
                        this.monthOrderChartData = response.data.data;
                    })
                    .catch(errors => {
                        console.log(errors);
                    });
                axios.get(`graph/item/week`)
                    .then(response => {     
                        this.weekOrderChartData = response.data.data;
                    })
                    .catch(errors => {
                        console.log(errors);
                    });
                    
                this.overlay = false
              }, 
                           
            },    
  }
</script>



