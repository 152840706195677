<template>
    <div class="content-wrapper">
      <section class="content-header">
        <div class="container-fluid">
          <div class="row">
            <div class="col-sm-6">
              <h2>Daftar Pesanan</h2>
            </div>
            <div class="col-sm-6">
              <ol class="breadcrumb float-sm-right">
                <li class="breadcrumb-item ">
                  <router-link :to="{ name:'' }">
                    Table
                  </router-link>
                </li>
                <li class="breadcrumb-item active">
                  <router-link :to="{ name:'item' }">
                    Pesanan
                  </router-link>
                </li>
              </ol>
            </div>
          </div>
        </div>
      </section>
      <section class="content">
      <div class="container-fluid">
        <div id="app">
            <v-app>
                <v-main>
                    <v-card>
                        <v-card-title>
                            <router-link 
                              :to="{
                                name: 'itemform',
                                params: {
                                  update: false, 
                                }
                              }" 
                              style="color:white"
                            >
                              <v-btn
                                color="primary"
                                dark
                                class="mt-3"
                              >
                                Tambah Baru
                              </v-btn>
                            </router-link>
                            <v-spacer></v-spacer>
                            <v-spacer></v-spacer>
                            <v-spacer></v-spacer>
                            <v-text-field
                                v-model="search"
                                append-icon="mdi-magnify"
                                label="Search"
                                class="ml-5"
                                single-line
                                hide-details
                                v-on:keyup.enter="searchData()"
                            ></v-text-field>
                        </v-card-title> 
                        <!-- Datatable Start -->
                        <v-data-table
                            fixed-header 
                            height="600"
                            :headers="headers"
                            :items="data"
                            :single-expand="singleExpand"
                            :expanded.sync="expanded"
                            item-key="id"
                            :loading="loading"
                            :options="tableOption"
                            show-expand
                            class="elevation-1"
                            hide-default-footer
                            >
                            <template v-slot:[`item.total_harga`]="{ item }">
                              Rp.{{item.total_harga | numFormat}}
                            </template>
                            <template v-slot:[`item.jadi`]="{ item }">
                              {{item.jadi | moment("dddd, D/M/YYYY HH:mm") }}
                            </template>
                            <template v-slot:[`item.waktu_dipesan`]="{ item }">
                              {{item.waktu_dipesan | moment("dddd, D/M/YYYY HH:mm") }}
                            </template>
                            <template v-slot:[`item.status_pemesanan`]="{ item }">
                              <span v-if="item.status_pemesanan==4">Pesanan Dibuat/Diproses</span>
                              <span v-if="item.status_pemesanan==5">Pesanan Selesai</span>
                            </template>
                            <template v-slot:[`item.actions`]="{ item }">
                                <v-tooltip bottom>
                                  <template v-slot:activator="{ on, attrs }">
                                    <v-icon
                                      v-if="item.status_pemesanan == 4"
                                      small
                                      v-bind="attrs"
                                      v-on="on"
                                      @click="
                                        orderDone(
                                          item.id,
                                          item.penerima
                                        )
                                      "
                                    >
                                      mdi-check
                                    </v-icon>
                                  </template>
                                  <span>Klik untuk menyelesaikan pesanan</span>
                                </v-tooltip>
                                <v-tooltip bottom>
                                  <template v-slot:activator="{ on, attrs }">
                                    <span v-bind="attrs"
                                      v-on="on">
                                      <router-link 
                                        :to="{
                                          name: 'itemform', 
                                          params: {
                                            id: item.id, 
                                            update: true, 
                                          }
                                        }"
                                      >
                                        <v-icon  small class="mr-2">
                                            mdi-pencil
                                        </v-icon>
                                      </router-link>
                                    </span>
                                    
                                  </template>
                                  <span>Klik untuk mengedit pesanan</span>
                                </v-tooltip>
                                <v-tooltip bottom>
                                  <template v-slot:activator="{ on, attrs }">
                                    <v-icon
                                      v-bind="attrs"
                                      v-on="on"
                                      small
                                      @click="
                                        deleteData(
                                          item.id,
                                          item.penerima
                                        )
                                      "
                                    >
                                      mdi-delete
                                    </v-icon>
                                  </template>
                                  <span>Klik untuk menghapus pesanan</span>
                                </v-tooltip>
                                <v-tooltip bottom>
                                  <template v-slot:activator="{ on, attrs }">
                                    <span v-bind="attrs" v-on="on">
                                      <v-menu>
                                      <template v-slot:activator="{ on, attrs }">
                                        <v-icon
                                          v-bind="attrs"
                                          v-on="on"
                                        >
                                          mdi-printer
                                        </v-icon>
                                      </template>
                                      <v-list>
                                        <a href="#">
                                          <v-list-item>
                                            <v-list-item-title @click="printData(item,'kwitansi')">Print Kwitansi</v-list-item-title>
                                          </v-list-item>
                                        </a>
                                        <a href="#">
                                          <v-list-item>
                                            <v-list-item-title @click="printData(item,'invoice')">Print Invoice</v-list-item-title>
                                          </v-list-item>
                                        </a>
                                        <a href="#">
                                          <v-list-item>
                                            <v-list-item-title @click="printData(item,'surat')">Print Surat</v-list-item-title>
                                          </v-list-item>
                                        </a>
                                      </v-list>
                                    </v-menu>
                                    </span>
                                  </template>
                                  <span>Klik untuk mencetak kwitansi, invoice dan surat jalan dari pesanan ini</span>
                                </v-tooltip>
                                
                            </template>
                            <!-- Expand Start -->
                            <template v-slot:expanded-item="{ headers, item }" >
                              <td :colspan="headers.length">
                                <div class="row m-4">
                                    <div class="col-lg-12">
                                      <v-card
                                        class="mx-auto"
                                        outlined
                                      >
                                       <v-list-item class="row">
                                          <v-list-item-content class="pa-5 pb-12 pt-8">
                                            <h4 class="col-12 minim text-bold pb-6">
                                              Data Pesanan
                                            </h4>
                                            <div class="col-lg-6 minim">
                                              <div class="label-text" style="font-weight:800">Penerima</div>
                                              <div class="sub-text">{{ item.penerima }}</div>
                                            </div>
                                            <div class="col-lg-6 minim">
                                              <div class="label-text" style="font-weight:800">No Telp Penerima</div>
                                              <div class="sub-text">{{ item.no_hp }}</div>
                                            </div>
                                            <div class="col-lg-6 minim">
                                              <div class="label-text" style="font-weight:800">Status</div>
                                              <div class="sub-text">{{ item.status.name }}</div>
                                            </div>
                                            <div class="col-lg-6 minim">
                                              <div class="label-text" style="font-weight:800">Waktu Jadi</div>
                                              <div class="sub-text">{{ item.jadi | moment("dddd, D/MM/YYYY HH:mm") }}</div>
                                            </div>
                                            <div class="col-lg-6 minim">
                                              <div class="label-text" style="font-weight:800">Waktu Diterima</div>
                                              <div class="sub-text">{{ item.waktu_dipesan | moment("dddd, D/MM/YYYY HH:mm")  }}</div>
                                            </div>       
                                            <div class="col-lg-6 minim">
                                              <div class="label-text" style="font-weight:800">Total harga</div>
                                              <div class="sub-text">Rp.{{ item.total_harga |numFormat}}</div>
                                            </div>         
                                            <div class="col-lg-6 minim">
                                              <div class="label-text" style="font-weight:800">Terbayar</div>
                                              <div class="sub-text">Rp.{{ item.total_terbayar |numFormat}}</div>
                                            </div>
                                                          
                                            <div class="col-lg-6 minim">
                                              <div class="label-text" style="font-weight:800">Driver</div>
                                              <div class="sub-text">{{ item.driver }}</div>
                                            </div>                
                                            <div class="col-lg-6 minim">
                                              <div class="label-text" style="font-weight:800">Dibuat Oleh</div>
                                              <div class="sub-text">{{ item.user.name }}</div>
                                            </div>     
                                            <div class="col-lg-12 minim">
                                              <div class="label-text" style="font-weight:800">Alamat</div>
                                              <div class="sub-text">{{ item.alamat }}</div>
                                            </div>   
                                            <div class="col-lg-12 minim">
                                              <div class="label-text" style="font-weight:800">Catatan Tambahan</div>
                                              <div class="sub-text">{{ item.catatan }}</div>
                                            </div>                      
                                          </v-list-item-content>
                                        </v-list-item>
                                      </v-card>
                                    </div>
                                      <div class="col-lg-12">
                                      <v-card
                                        class="mx-auto"
                                        outlined
                                      >
                                       <v-list-item class="row">
                                          <v-list-item-content class="pa-12">
                                            <h6 class="minim text-bold mb-4" style="padding:0px !important">
                                              Detail Item Pesanan
                                            </h6>          
                                            <v-simple-table >
                                              <template v-slot:default>
                                                <thead>
                                                  <tr>
                                                    <th class="text-left">
                                                      Item
                                                    </th>
                                                    <th class="text-left">
                                                      Banyak
                                                    </th>
                                                    <th class="text-left">
                                                      Harga Satuan
                                                    </th>
                                                    <th>
                                                      total Harga
                                                    </th>
                                                  </tr>
                                                </thead>
                                                <tbody>
                                                  <tr
                                                    v-for="items in item.sub_item"
                                                    :key="items.nama"
                                                  >
                                                    <td>{{ items.nama }}</td>
                                                    <td>{{ items.banyak  | numFormat}}</td>
                                                    <td>{{ items.harga | numFormat}}</td>
                                                    <td>Rp.{{(items.harga*items.banyak)|numFormat}}</td>
                                                  </tr>
                                                </tbody>
                                              </template>
                                            </v-simple-table>                
                                          </v-list-item-content>
                                        </v-list-item>
                                      </v-card>
                                    </div>
                                </div>
                              </td>
                              
                          </template>
                          <!-- Expand End -->
                        </v-data-table>
                        <div class="text-center pt-3 pb-3">
                          <v-pagination 
                            v-model="pagination.page"
                            :length="pagination.totalpage"
                            :total-visible="10"
                          ></v-pagination>
                        </div>
                        <!-- Datatable End -->
                    </v-card>
                    <div style="display:none" id="invoicePrintField">
                      <div style="color: #131a47;border: 1px solid black; padding: 10px">
                        <div class=" row ">
                            <div class="col-sm-2 " style="padding:20px 30px 30px 30px ">
                                <img src="http://kotakanbackend.resepedia.net/images/logo_kedai.png " style='width: 100%; object-fit: contain'>
                            </div>
                            <div class="col-sm-5 ">
                                <h6 style="color: #131a47; font-weight: 1000; margin-bottom:0px">KEDAI KAYUMANIS</h6>
                                <span style="color: #131a47; font-size:12pt;">
                                    by PT. Amerta Sukses Abadi 
                                    <br> Jl. Kayu Manis VIII No.38 Matraman
                                    <br> Jakarta Timur
                                    <br> SMS: 0878 8160 4448
                                    <br> web: www.kedaikayumanis.com
                                </span>
                                <h6 style="color: #131a47; font-weight: 1000; margin-top:0px">PESAN ANTAR (021) 2128 1234</h6>
                            </div>
                            <div class="col-sm-5 row" >
                                <div class="col-sm-4" style="color: #131a47; text-align:right; font-size:12pt; font-weight: 800;">Nomor : </div>
                                <div class="col-sm-8" style="color: #131a47; text-align:left; font-size:12pt; font-weight: 800;">{{dataPrint.id}}INVKKM{{dataPrint.waktu_dipesan| moment("M")}}{{dataPrint.waktu_dipesan| moment("Y")}}</div>
                                <div class="col-sm-4" style="color: #131a47; text-align:right;font-size:12pt; font-weight: 800;">Tanggal : </div>
                                <div class="col-sm-8" style="color: #131a47; text-align:left; font-size:12pt; font-weight: 800;">{{dataPrint.waktu_dipesan| moment("D-MMMM-Y")}}</div>
                                <div class="col-sm-4" style="color: #131a47; text-align:right;font-size:12pt; font-weight: 800;">Kepada Yth :</div>
                                <div class="col-sm-8" style="color: #131a47; text-align:left; font-size:12pt; font-weight: 800;">{{dataPrint.penerima}}({{dataPrint.no_hp}})</div>
                            </div>
                        </div>
                        <p class="text-center" style="color: #131a47; font-weight:1000; font-size: 12pt;letter-spacing: 4px; background-color:rgb(190, 190, 190)">
                            INVOICE
                        </p>
                        <table class="table table-bordered table-condensed" style="color: #131a47; font-size:10pt !important; margin: 0px !important;">
                            <tr style="border: 1px solid black !important;">
                                <th style="width: 10%;border: 1px solid black !important;font-size:12pt; ">Banyak</th>
                                <th style="width: 40%;border: 1px solid black !important;font-size:12pt; ">Uraian</th>
                                <th style="width: 20%;border: 1px solid black !important;font-size:12pt; ">Harga Satuan</th>
                                <th style="width: 20%;border: 1px solid black !important;font-size:12pt; ">Jumlah</th>
                            </tr>
                            <tr style="border: 1px solid black !important;" v-for="items in dataPrint.sub_item" :key="items.id">
                                <td style="border: 1px solid black !important;font-size:12pt;">{{items.banyak|numFormat}}</td>
                                <td style="border: 1px solid black !important;font-size:12pt;"><span style="font-weight:800">{{items.nama}}</span>  <br> {{items.desc}} </td>
                                <td style="border: 1px solid black !important;font-size:12pt;">Rp.{{items.harga|numFormat}}</td>
                                <td style="border: 1px solid black !important;font-size:12pt;">Rp.{{(items.harga*items.banyak)|numFormat}}</td>
                            </tr>
                            <tr style="border: 1px solid black !important;">
                                <td style="border: 1px solid black !important;font-size:12pt;" colspan="3 " class="text-right ">Total</td>
                                <td style="border: 1px solid black !important;font-size:12pt;">Rp.{{dataPrint.total_harga|numFormat}}</td>
                            </tr>
                            <tr style="border: 1px solid black !important;">
                                <td style="border: 1px solid black !important;font-size:12pt;" colspan="3 " class="text-right ">Uang Muka</td>
                                <td style="border: 1px solid black !important;font-size:12pt;">Rp.{{dataPrint.total_terbayar|numFormat}}</td>
                            </tr>
                            <tr style="border: 1px solid black !important;">
                                <td style="border: 1px solid black !important;font-size:12pt;" colspan="3 " class="text-right ">Sisa Pembayaran</td>
                                <td style="border: 1px solid black !important;font-size:12pt;">Rp.{{(dataPrint.total_harga-dataPrint.total_terbayar)|numFormat}}</td>
                            </tr>
                            <tr style="border: 1px solid black !important;">
                                <td style="border: 1px solid black !important;font-size:12pt;" colspan="4 ">Terbilang : {{TerbilangFilter((dataPrint.total_harga-dataPrint.total_terbayar)) }} Rupiah</td>
                            </tr>
                        </table>  
                        <div class="row" style="color: #131a47; padding:2% 0% 2% 0%">
                          <div class="col-sm-8">
                            Pembayaran dilakukan melalui rekening:
                            <br>
                            BCA KCP AM Sangaji
                            <br>
                            No. Rek: 587 025 3038
                            <br>
                            an. PT Amerta Sukses Abadi
                            <br>
                            <br>
                            BRI KCP Manggarai
                            <br>
                            No Rek: 1125-01-000167-30-4
                            <br>
                            an. PT Amerta Sukses Abadi
                            <br>
                            <br>Pembayaran melalui cek dianggap sah apabila sudah dicairkan.
                          </div>
                          <div class="col-sm-4 " style="font-size:12pt;">
                            Hormat Kami
                            <br>
                            <br>
                            <br>
                            <br>
                            {{authenticated.user.name}}
                          </div>
                        </div>
                      </div>

                      <div style="color: #131a47; border: 1px solid black; padding: 10px; margin-top:2%">
                        <div class=" row ">
                            <div class="col-sm-2 " style="padding:20px 30px 30px 30px ">
                                <img src="http://kotakanbackend.resepedia.net/images/logo_kedai.png " style='width: 100%; object-fit: contain'>
                            </div>
                            <div class="col-sm-5 ">
                                <h6 style="font-weight: 1000; margin-bottom:0px">KEDAI KAYUMANIS</h6>
                                <span style="font-size:12pt;">
                                    by PT. Amerta Sukses Abadi 
                                    <br> Jl. Kayu Manis VIII No.38 Matraman
                                    <br> Jakarta Timur
                                    <br> SMS: 0878 8160 4448
                                    <br> web: www.kedaikayumanis.com
                                </span>
                                <h6 style="font-weight: 1000; margin-top:0px">PESAN ANTAR (021) 2128 1234</h6>
                            </div>
                            <div class="col-sm-5 row" >
                                <div class="col-sm-4" style="text-align:right; font-size:12pt; font-weight: 800;">Nomor : </div>
                                <div class="col-sm-8" style="text-align:left; font-size:12pt; font-weight: 800;">{{dataPrint.id}}INVKKM{{dataPrint.waktu_dipesan| moment("M")}}{{dataPrint.waktu_dipesan| moment("Y")}}</div>
                                <div class="col-sm-4" style="text-align:right;font-size:12pt; font-weight: 800;">Tanggal : </div>
                                <div class="col-sm-8" style="text-align:left; font-size:12pt; font-weight: 800;">{{dataPrint.waktu_dipesan| moment("D-MMMM-Y")}}</div>
                                <div class="col-sm-4" style="text-align:right;font-size:12pt; font-weight: 800;">Kepada Yth :</div>
                                <div class="col-sm-8" style="text-align:left; font-size:12pt; font-weight: 800;">{{dataPrint.penerima}}({{dataPrint.no_hp}})</div>
                            </div>
                        </div>
                        <p class="text-center" style="font-weight:1000; font-size: 12pt;letter-spacing: 4px; background-color:rgb(190, 190, 190)">
                            INVOICE
                        </p>
                        <table class="table table-bordered table-condensed" style="color: #131a47; font-size:10pt !important; margin: 0px !important;">
                            <tr style="border: 1px solid black !important;">
                                <th style="width: 10%;border: 1px solid black !important;font-size:12pt; ">Banyak</th>
                                <th style="width: 40%;border: 1px solid black !important;font-size:12pt; ">Uraian</th>
                                <th style="width: 20%;border: 1px solid black !important;font-size:12pt; ">Harga Satuan</th>
                                <th style="width: 20%;border: 1px solid black !important;font-size:12pt; ">Jumlah</th>
                            </tr>
                            <tr style="border: 1px solid black !important;" v-for="items in dataPrint.sub_item" :key="items.id">
                                <td style="border: 1px solid black !important;font-size:12pt;">{{items.banyak|numFormat}}</td>
                                <td style="border: 1px solid black !important;font-size:12pt;"><span style="font-weight:800">{{items.nama}}</span>  <br> {{items.desc}} </td>
                                <td style="border: 1px solid black !important;font-size:12pt;">Rp.{{items.harga|numFormat}}</td>
                                <td style="border: 1px solid black !important;font-size:12pt;">Rp.{{(items.harga*items.banyak)|numFormat}}</td>
                            </tr>
                            <tr style="border: 1px solid black !important;">
                                <td style="border: 1px solid black !important;font-size:12pt;" colspan="3 " class="text-right ">Total</td>
                                <td style="border: 1px solid black !important;font-size:12pt;">Rp.{{dataPrint.total_harga|numFormat}}</td>
                            </tr>
                            <tr style="border: 1px solid black !important;">
                                <td style="border: 1px solid black !important;font-size:12pt;" colspan="3 " class="text-right ">Uang Muka</td>
                                <td style="border: 1px solid black !important;font-size:12pt;">Rp.{{dataPrint.total_terbayar|numFormat}}</td>
                            </tr>
                            <tr style="border: 1px solid black !important;">
                                <td style="border: 1px solid black !important;font-size:12pt;" colspan="3 " class="text-right ">Sisa Pembayaran</td>
                                <td style="border: 1px solid black !important;font-size:12pt;">Rp.{{(dataPrint.total_harga-dataPrint.total_terbayar)|numFormat}}</td>
                            </tr>
                            <tr style="border: 1px solid black !important;">
                                <td style="border: 1px solid black !important;font-size:12pt;" colspan="4 ">Terbilang : {{TerbilangFilter((dataPrint.total_harga-dataPrint.total_terbayar)) }} Rupiah</td>
                            </tr>
                        </table>   
                        <div class="row" style="color: #131a47; padding:2% 0% 2% 0%">
                          <div class="col-sm-8">
                            <table class="table table-bordered table-condensed" style="color: #131a47; font-size:10pt !important">
                              <tr style="height:100px border: 1px solid black !important;">
                                <th style="border: 1px solid black !important;width:50%;font-size:12pt">
                                    Kepada:
                                    <br> {{dataPrint.penerima}}({{dataPrint.no_hp}})
                                    <br> {{dataPrint.alamat}}
                                </th>
                                <th style="border: 1px solid black !important;width:50%;font-size:12pt">Penerima:
                                  <br>
                                  <br>
                                </th>
                              </tr>
                            </table>
                          </div>
                          <div class="col-sm-4 " style="color: #131a47;font-size:12pt">
                            Hormat Kami
                            <br>
                            <br>
                            <br>
                            <br>
                            {{authenticated.user.name}}
                          </div>
                        </div>
                      </div>
                    </div>

                    <div style="display:none" id="kwitansiPrintField">
                      <div style="color: #131a47; border: 1px solid black; padding: 10px">
                          <div class=" row ">
                              <div class="col-sm-2 " style="padding:20px 30px 30px 30px ">
                                  <img src="http://kotakanbackend.resepedia.net/images/logo_kedai.png " style='width: 100%; object-fit: contain'>
                              </div>
                              <div class="col-sm-4 ">
                                  <h6 style="font-weight: 1000; margin-bottom:0px">KEDAI KAYUMANIS</h6>
                                  <span style="font-size:12pt;">
                                      by PT. Amerta Sukses Abadi 
                                      <br> Jl. Kayu Manis VIII No.38 Matraman
                                      <br> Jakarta Timur
                                      <br> SMS: 0878 8160 4448
                                      <br> web: www.kedaikayumanis.com
                                  </span>
                                  <h6 style="font-weight: 1000; margin-top:0px">PESAN ANTAR (021) 2128 1234</h6>
                              </div>
                              <div class="col-sm-6 row" style="margin: 20 0 0 !important">
                                  <div class="col-sm-4" style="text-align:right; font-size:12pt; font-weight: 800;">Nomor : </div>
                                  <div class="col-sm-8" style="text-align:left; font-size:12pt; font-weight: 800;">{{dataPrint.id}}KWTKKM{{dataPrint.waktu_dipesan| moment("M")}}{{dataPrint.waktu_dipesan| moment("Y")}}</div>
                                  <div class="col-sm-4" style="text-align:right;font-size:12pt; font-weight: 800;">Tanggal : </div>
                                  <div class="col-sm-8" style="text-align:left; font-size:12pt; font-weight: 800;">{{dataPrint.waktu_dipesan| moment("D-MMMM-Y")}}</div>
                              </div>
                          </div>
                          <p class="text-center" style="font-weight:1000; font-size: 12pt;letter-spacing: 4px; background-color:rgb(190, 190, 190)">
                              KWITANSI
                          </p>
                          <div class="row " style="padding:10px 10px ">
                            <div class="col-sm-3 " style=" font-size:12pt; font-weight: 800; ">Sudah Terima Dari</div>
                            <div class="col-sm-9 " style="font-size:12pt; font-weight: 800; ">: {{dataPrint.penerima}}({{dataPrint.no_hp}})</div>
                            <div class="col-sm-3 " style=" font-size:12pt; font-weight: 800; ">Total Harga</div>
                            <div class="col-sm-9 " style="font-size:12pt; font-weight: 800; ">: {{dataPrint.total_harga|numFormat}}</div>
                            <div class="col-sm-3 " style=" font-size:12pt; font-weight: 800; margin-top:20px ">Untuk Pembayaran :</div>
                          </div>
                          <table class="table table-bordered table-condensed" style="color: #131a47; font-size:10pt !important; margin: 0px !important;">
                            <tr style="border: 1px solid black !important;">
                                <th style="width: 10%; border: 1px solid black !important;">Banyak</th>
                                <th style="width: 40%; border: 1px solid black !important;">Uraian</th>
                                <th style="width: 20%; border: 1px solid black !important;">Harga Satuan</th>
                                <th style="width: 20%; border: 1px solid black !important;">Jumlah</th>
                            </tr>
                            <tr style="border: 1px solid black !important;" v-for="items in dataPrint.sub_item" :key="items.id">
                                <td style="border: 1px solid black !important;">{{items.banyak|numFormat}}</td>
                                <td style="border: 1px solid black !important; font-weight:800">{{items.nama}} </td>
                                <td style="border: 1px solid black !important;">Rp.{{items.harga|numFormat}}</td>
                                <td style="border: 1px solid black !important;">Rp.{{(items.harga*items.banyak)|numFormat}}</td>
                            </tr>
                            <tr style="border: 1px solid black !important;">
                                <td style="border: 1px solid black !important;" colspan="3 " class="text-right ">Total</td>
                                <td style="border: 1px solid black !important;">Rp.{{dataPrint.total_harga|numFormat}}</td>
                            </tr>
                            <tr style="border: 1px solid black !important;">
                                <td style="border: 1px solid black !important;" colspan="4 ">Terbilang : {{TerbilangFilter(dataPrint.total_harga)}} Rupiah</td>
                            </tr>
                          </table>
                          <div style="color: #131a47; font-size:12pt; margin:2% 0% 2% 80%;font-size:12pt">
                            Hormat Kami
                            <br>
                            <br>
                            <br>
                            <br>
                            {{authenticated.user.name}}
                          </div>
                      </div>
                      
                      <div style="color: #131a47; border: 1px solid black; padding: 10px;margin-top:2%">
                          <div class=" row ">
                              <div class="col-sm-2 " style="padding:20px 30px 30px 30px ">
                                  <img src="http://kotakanbackend.resepedia.net/images/logo_kedai.png " style='width: 100%; object-fit: contain'>
                              </div>
                              <div class="col-sm-4 ">
                                  <h6 style="font-weight: 1000; margin-bottom:0px">KEDAI KAYUMANIS</h6>
                                  <span style="font-size:12pt;">
                                      by PT. Amerta Sukses Abadi 
                                      <br> Jl. Kayu Manis VIII No.38 Matraman
                                      <br> Jakarta Timur
                                      <br> SMS: 0878 8160 4448
                                      <br> web: www.kedaikayumanis.com
                                  </span>
                                  <h6 style="font-weight: 1000; margin-top:0px">PESAN ANTAR (021) 2128 1234</h6>
                              </div>
                              <div class="col-sm-6 row" style="margin: 20 0 0 !important">
                                  <div class="col-sm-4" style="text-align:right; font-size:12pt; font-weight: 800;">Nomor : </div>
                                  <div class="col-sm-8" style="text-align:left; font-size:12pt; font-weight: 800;">{{dataPrint.id}}KWTKKM{{dataPrint.waktu_dipesan| moment("M")}}{{dataPrint.waktu_dipesan| moment("Y")}}</div>
                                  <div class="col-sm-4" style="text-align:right;font-size:12pt; font-weight: 800;">Tanggal : </div>
                                  <div class="col-sm-8" style="text-align:left; font-size:12pt; font-weight: 800;">{{dataPrint.waktu_dipesan| moment("D-MMMM-Y")}}</div>
                              </div>
                          </div>
                          <p class="text-center" style="font-weight:1000; font-size: 12pt;letter-spacing: 4px; background-color:rgb(190, 190, 190)">
                              KWITANSI
                          </p>
                          <div class="row " style="padding:10px 10px ">
                            <div class="col-sm-3 " style=" font-size:12pt; font-weight: 800; ">Sudah Terima Dari</div>
                            <div class="col-sm-9 " style="font-size:12pt; font-weight: 800; ">: {{dataPrint.penerima}}({{dataPrint.no_hp}})</div>
                            <div class="col-sm-3 " style=" font-size:12pt; font-weight: 800; ">Total Harga</div>
                            <div class="col-sm-9 " style="font-size:12pt; font-weight: 800; ">: {{dataPrint.total_harga|numFormat}}</div>
                            <div class="col-sm-3 " style=" font-size:12pt; font-weight: 800; margin-top:20px ">Untuk Pembayaran :</div>
                          </div>
                          <table class="table table-bordered table-condensed" style="color: #131a47; font-size:10pt !important; margin: 0px !important;">
                            <tr style="border: 1px solid black !important;">
                                <th style="width: 10%; border: 1px solid black !important;">Banyak</th>
                                <th style="width: 40%; border: 1px solid black !important;">Uraian</th>
                                <th style="width: 20%; border: 1px solid black !important;">Harga Satuan</th>
                                <th style="width: 20%; border: 1px solid black !important;">Jumlah</th>
                            </tr>
                            <tr style="border: 1px solid black !important;" v-for="items in dataPrint.sub_item" :key="items.id">
                                <td style="border: 1px solid black !important;">{{items.banyak|numFormat}}</td>
                                <td style="border: 1px solid black !important;font-weight:800">{{items.nama}}</td>
                                <td style="border: 1px solid black !important;">Rp.{{items.harga|numFormat}}</td>
                                <td style="border: 1px solid black !important;">Rp.{{(items.harga*items.banyak)|numFormat}}</td>
                            </tr>
                            <tr style="border: 1px solid black !important;">
                                <td style="border: 1px solid black !important;" colspan="3 " class="text-right ">Total</td>
                                <td style="border: 1px solid black !important;">Rp.{{dataPrint.total_harga|numFormat}}</td>
                            </tr>
                            <tr style="border: 1px solid black !important;">
                                <td style="border: 1px solid black !important;" colspan="4 ">Terbilang : {{TerbilangFilter(dataPrint.total_harga)}} Rupiah</td>
                            </tr>
                          </table>
                          <h6 style="font-weight: 1000; margin-bottom:10px;margin-top:5px "> 
                            Catatan :
                            <br>
                            {{dataPrint.catatan}}
                          </h6>
                          <div class="row ">
                            <div class="col-sm-8">
                              <table class="table table-bordered table-condensed" style="color: #131a47; font-size:10pt !important">
                                <tr style="height:100px border: 1px solid black !important;">
                                  <th style="border: 1px solid black !important;width:50%">
                                      Kepada:
                                      <br> {{dataPrint.penerima}}({{dataPrint.no_hp}})
                                      <br> {{dataPrint.alamat}}

                                  </th>
                                  <th style="border: 1px solid black !important;width:50%">Penerima:
                                    <br>
                                    <br>
                                  </th>
                                </tr>
                              </table>
                            </div>
                            <div class="col-sm-4 " style="font-size:12pt;">
                              Hormat Kami
                              <br>
                              <br>
                              <br>
                              <br>
                              {{authenticated.user.name}}
                            </div>
                          </div>
                      </div>
                    </div>
                    <div style="display:none" id="suratPrintField">
                      <div style="color: #131a47; border: 1px solid black; padding: 10px">
                          <div class=" row ">
                              <div class="col-sm-2 " style="padding:20px 30px 30px 30px ">
                                <img src="http://kotakanbackend.resepedia.net/images/logo_kedai.png " style='width: 100%; object-fit: contain'>
                              </div>
                              <div class="col-sm-4 ">
                                <h6 style="font-weight: 1000; margin-bottom:0px">KEDAI KAYUMANIS</h6>
                                <span style="font-size:12pt;">
                                    by PT. Amerta Sukses Abadi 
                                    <br> Jl. Kayu Manis VIII No.38 Matraman
                                    <br> Jakarta Timur
                                    <br> SMS: 0878 8160 4448
                                    <br> web: www.kedaikayumanis.com
                                </span>
                                <h6 style="font-weight: 1000; margin-top:0px">PESAN ANTAR (021) 2128 1234</h6>
                              </div>
                              <div class="col-sm-6 row" style="margin: 20 0 0 !important">
                                <div class="col-sm-4" style="text-align:right; font-size:12pt; font-weight: 800;">Nomor : </div>
                                <div class="col-sm-8" style="text-align:left; font-size:12pt; font-weight: 800;">{{dataPrint.id}}SJKKM{{dataPrint.waktu_dipesan| moment("M")}}{{dataPrint.waktu_dipesan| moment("Y")}}</div>
                                <div class="col-sm-4" style="text-align:right;font-size:12pt; font-weight: 800;">Tanggal : </div>
                                <div class="col-sm-8" style="text-align:left; font-size:12pt; font-weight: 800;">{{dataPrint.waktu_dipesan| moment("D-MMMM-Y")}}</div>
                              </div>
                          </div>
                          <p class="text-center" style="font-weight:1000; font-size: 12pt;letter-spacing: 4px; background-color:rgb(190, 190, 190)">
                            SURAT JALAN / TANDA TERIMA
                          </p>
                          <div class="row " style="color: #131a47; padding:10px 10px ">
                            <div class="col-sm-3 " style=" font-size:12pt; font-weight: 800; ">Sudah Terima Dari</div>
                            <div class="col-sm-9 " style="font-size:12pt; font-weight: 800; ">: {{dataPrint.penerima}}({{dataPrint.no_hp}})</div>
                            <div class="col-sm-3 " style=" font-size:12pt; font-weight: 800; ">Alamat</div>
                            <div class="col-sm-9 " style="font-size:12pt; font-weight: 800; ">: {{dataPrint.alamat}}</div>
                            <div class="col-sm-12 " style=" font-size:12pt; font-weight: 800; margin-top:20px ">Kami kirimkan dengan kendaraan (motor/mobil) dengan nomor polisi : {{dataPrint.nomor_driver}}</div>
                          </div>
                          <table class="table table-bordered table-condensed" style="color: #131a47; font-size:10pt !important; margin: 0px !important;">
                            <tr>
                              <th style="width: 10%; border: 1px solid black !important;">Banyak</th>
                              <th style="width: 90%; border: 1px solid black !important;">Uraian</th>
                            </tr>
                            <tr style="border: 1px solid black !important;" v-for="items in dataPrint.sub_item" :key="items.id">
                                <td style="border: 1px solid black !important;">{{items.banyak|numFormat}}</td>
                                <td style="border: 1px solid black !important; font-weight:800">{{items.nama}}</td>
                            </tr>
                          </table>
                          <h6 style="font-weight: 1000; margin-bottom:10px;margin-top:5px "> 
                            Catatan :
                            {{dataPrint.catatan}}
                          </h6>
                          <div>
                            <table class="table" style="color: #131a47; font-size:10pt !important;font-size:12pt">
                              <tr style="height:100px">
                                <th style="width: 25%; border: 1px solid black !important">
                                  Catatan:
                                  <br> Lembar 1: pengiriman
                                  <br> Lembar 2: Penerima
                                </th>
                                <th>
                                  pengirim
                                  <br>
                                  <br>
                                  <br>
                                  <br> 
                                  __________
                                </th>
                                <th>
                                  driver
                                  <br>
                                  <br>
                                  <br>
                                  <br> 
                                  {{dataPrint.driver}}
                                </th>
                                <th>
                                  Penerima
                                  <br>
                                  <br>
                                  <br>
                                  <br>
                                  __________
                                </th>
                              </tr>
                            </table>
                          </div>
                      </div>
                      
                      <div style="border: 1px solid black; padding: 10px;margin-top:2%">
                          <div class=" row " style="color: #131a47;">
                              <div class="col-sm-2 " style="padding:20px 30px 30px 30px ">
                                <img src="http://kotakanbackend.resepedia.net/images/logo_kedai.png " style='width: 100%; object-fit: contain'>
                              </div>
                              <div class="col-sm-4 ">
                                <h6 style="color: #131a47; font-weight: 1000; margin-bottom:0px">KEDAI KAYUMANIS</h6>
                                <span style="font-size:12pt;">
                                    by PT. Amerta Sukses Abadi 
                                    <br> Jl. Kayu Manis VIII No.38 Matraman
                                    <br> Jakarta Timur
                                    <br> SMS: 0878 8160 4448
                                    <br> web: www.kedaikayumanis.com
                                </span>
                                <h6 style="font-weight: 1000; margin-top:0px">PESAN ANTAR (021) 2128 1234</h6>
                              </div>
                              <div class="col-sm-6 row" style="margin: 20 0 0 !important">
                                <div class="col-sm-4" style="text-align:right; font-size:12pt; font-weight: 800;">Nomor : </div>
                                <div class="col-sm-8" style="text-align:left; font-size:12pt; font-weight: 800;">{{dataPrint.id}}SJKKM{{dataPrint.waktu_dipesan| moment("M")}}{{dataPrint.waktu_dipesan| moment("Y")}}</div>
                                <div class="col-sm-4" style="text-align:right;font-size:12pt; font-weight: 800;">Tanggal : </div>
                                <div class="col-sm-8" style="text-align:left; font-size:12pt; font-weight: 800;">{{dataPrint.waktu_dipesan| moment("D-MMMM-Y")}}</div>
                              </div>
                          </div>
                          <p class="text-center" style="color: #131a47; font-weight:1000; font-size: 12pt;letter-spacing: 4px; background-color:rgb(190, 190, 190)">
                            SURAT JALAN / TANDA TERIMA
                          </p>
                          <div class="row " style="color: #131a47; padding:10px 10px ">
                            <div class="col-sm-3 " style=" font-size:12pt; font-weight: 800; ">Sudah Terima Dari</div>
                            <div class="col-sm-9 " style="font-size:12pt; font-weight: 800; ">: {{dataPrint.penerima}}({{dataPrint.no_hp}})</div>
                            <div class="col-sm-3 " style=" font-size:12pt; font-weight: 800; ">Alamat</div>
                            <div class="col-sm-9 " style="font-size:12pt; font-weight: 800; ">: {{dataPrint.alamat}}</div>
                            <div class="col-sm-12 " style=" font-size:12pt; font-weight: 800; margin-top:20px ">Kami kirimkan dengan kendaraan (motor/mobil) dengan nomor polisi : {{dataPrint.nomor_driver}}</div>
                          </div>
                          <table class="table table-bordered table-condensed" style="color: #131a47; font-size:10pt !important; margin: 0px !important;">
                            <tr>
                              <th style="width: 10%; border: 1px solid black !important;">Banyak</th>
                              <th style="width: 90%; border: 1px solid black !important;">Uraian</th>
                            </tr>
                            <tr style="border: 1px solid black !important;" v-for="items in dataPrint.sub_item" :key="items.id">
                                <td style="border: 1px solid black !important;">{{items.banyak|numFormat}}</td>
                                <td style="border: 1px solid black !important; font-weight:800">{{items.nama}}</td>
                            </tr>
                          </table>
                          <h6 style="color: #131a47; font-weight: 1000; margin-bottom:10px;margin-top:5px "> 
                            Catatan :
                            {{dataPrint.catatan}}
                          </h6>
                          <div>
                            <table class="table" style="color: #131a47; font-size:10pt !important;font-size:12pt">
                              <tr style="height:100px">
                                <th style="width: 25%; border: 1px solid black !important;">
                                  Catatan:
                                  <br> Lembar 1: pengiriman
                                  <br> Lembar 2: Penerima
                                </th>
                                <th>
                                  pengirim
                                  <br>
                                  <br>
                                  <br>
                                  <br> 
                                  ___________
                                </th>
                                <th>
                                  driver
                                  <br>
                                  <br>
                                  <br>
                                  <br> 
                                  {{dataPrint.driver}}
                                </th>
                                <th>
                                  Penerima
                                  <br>
                                  <br>
                                  <br>
                                  <br>
                                  ___________
                                </th>
                              </tr>
                            </table>
                          </div>
                      </div>
                    </div>
                </v-main>
            </v-app>
        </div>
      </div>
    </section>
    </div>
</template>
<style>
  .minim{
    padding-top:5px !important;
    padding-bottom:5px !important;
    padding-right:5px !important;
    padding-left:3% !important;
  }
  .label-text{
    font-size: 15px;
    padding-bottom: 10px !important;
    font-weight: 400;
    color: rgb(109, 109, 109)
  }
  .main-text{
    font-size: 18px;
    font-weight: 500;
    color: rgb(36, 35, 35)
  }
  .sub-text{
    font-size: 16px;
    font-weight: 500;
    color: rgb(53, 53, 53)
  }
</style>
<script>
import { mapGetters, mapActions } from 'vuex'
  export default {
        created() {
            this.getData();
            Fire.$on('reloaddata', () => {
              this.getData();
            })
        },
    
        data() {        
            return {
              expanded: [],
              data: [],
              singleExpand: true,
              loading: true,
              search: '',
              dataPrint: {
                id:0,
                penerima:"",
                alamat:"",
                no_hp:"",
                total_harga:13531533,
                total_terbayar:0,
                driver:"",
                nomor_driver:"",
                created_by:0,
                status_id:0,
                sales:0,
                jadi:"",
                diterima:"",
                created_at:"",
                updated_at:null,
                deleted_at:null,
                sales_data:{
                    id:0,
                    name:"",
                    desc:"",
                    created_at:"",
                    updated_at:null,
                    deleted_at:null
                },
                status:{
                    id:0,
                    name:"",
                    desc:"",
                    created_at:"",
                    updated_at:null,
                    deleted_at:null
                },
                user:{
                    id:0,
                    username:"",
                    name:"",
                    email:"",
                    created_at:"",
                    updated_at:null,
                    deleted_at:null
                },
                sub_item:[
                    {
                      id:0,
                      nama:"",
                      banyak:0,
                      desc:"",
                      type_id:"",
                      harga:0,
                      item_id:0,
                    },
                ]
              },
              imagePreview: `${process.env.BASE_URL}image/image.webp`,
              pagination:{
                totalPage:0,
                page:1,
                limit:50,
              },
              tableOption:{
                itemsPerPage: 50,
              },
              headers: [
                  { text: 'Penerima',value: 'penerima', },
                  { text: 'status',value: 'status.name', },
                  { text: 'alamat',value: 'alamat', },
                  { text: 'Waktu Jadi',value: 'jadi', },
                  { text: 'Waktu Diterima',value: 'waktu_dipesan', },
                  { text: 'total',value: 'total_harga', },
                  { text: 'Status Pesanan',value: 'status_pemesanan', },
                  { text: 'Actions', value: 'actions', sortable: false },
                  { text: 'Detail', value: 'data-table-expand' },
              ],
            }
        },
         computed:{
            ...mapGetters({
                authenticated: 'auth/authenticated',
            }),
            page() {
              return this.pagination.page;
            }

        },
        watch: {
          page() {
            this.getData()
          },
          deep: true
        },
        methods: {
            TerbilangFilter(a){
              var c = " Satu Dua Tiga Empat Lima Enam Tujuh Delapan Sembilan Sepuluh Sebelas".split(" ");
                if (12 > a) var b = c[a];
                else 20 > a ? b = c[a - 10] + " Belas" : 100 > a ? (b = parseInt(String(a / 10).substr(0, 1)), b = c[b] + " Puluh " + c[a % 10]) : 200 > a ? b = "Seratus " + this.TerbilangFilter(a - 100) : 1E3 > a ? (b = parseInt(String(a / 100).substr(0, 1)), b = c[b] + " Ratus " + this.TerbilangFilter(a % 100)) : 2E3 > a ? b = "Seribu " + this.TerbilangFilter(a - 1E3) : 1E4 > a ? (b = parseInt(String(a / 1E3).substr(0, 1)), b = c[b] + " Ribu " + this.TerbilangFilter(a % 1E3)) : 1E5 > a ? (b = parseInt(String(a / 100).substr(0, 2)),
                        a %= 1E3, b = this.TerbilangFilter(b) + " Ribu " + this.TerbilangFilter(a)) : 1E6 > a ? (b = parseInt(String(a / 1E3).substr(0, 3)), a %= 1E3, b = this.TerbilangFilter(b) + " Ribu " + this.TerbilangFilter(a)) : 1E8 > a ? (b = parseInt(String(a / 1E6).substr(0, 4)), a %= 1E6, b = this.TerbilangFilter(b) + " Juta " + this.TerbilangFilter(a)) : 1E9 > a ? (b = parseInt(String(a / 1E6).substr(0, 4)), a %= 1E6, b = this.TerbilangFilter(b) + " Juta " + this.TerbilangFilter(a)) : 1E10 > a ? (b = parseInt(String(a / 1E9).substr(0, 1)), a %= 1E9, b = this.TerbilangFilter(b) + " Milyar " + this.TerbilangFilter(a)) : 1E11 > a ? (b = parseInt(String(a / 1E9).substr(0, 2)), a %= 1E9, b = this.TerbilangFilter(b) + " Milyar " + this.TerbilangFilter(a)) :
                    1E12 > a ? (b = parseInt(String(a / 1E9).substr(0, 3)), a %= 1E9, b = this.TerbilangFilter(b) + " Milyar " + this.TerbilangFilter(a)) : 1E13 > a ? (b = parseInt(String(a / 1E10).substr(0, 1)), a %= 1E10, b = this.TerbilangFilter(b) + " Triliun " + this.TerbilangFilter(a)) : 1E14 > a ? (b = parseInt(String(a / 1E12).substr(0, 2)), a %= 1E12, b = this.TerbilangFilter(b) + " Triliun " + this.TerbilangFilter(a)) : 1E15 > a ? (b = parseInt(String(a / 1E12).substr(0, 3)), a %= 1E12, b = this.TerbilangFilter(b) + " Triliun " + this.TerbilangFilter(a)) : 1E16 > a && (b = parseInt(String(a / 1E15).substr(0, 1)), a %= 1E15, b = this.TerbilangFilter(b) + " Kuadriliun " + this.TerbilangFilter(a));
                a = b.split(" ");
                c = [];
                for (b = 0; b < a.length; b++) "" != a[b] && c.push(a[b]);
                return c.join(" ")
            },
            format_time(value){
              if (value) {
                  if (this.$moment(value).format('HH:mm') >= "12:00") {
                    return true
                  }
                  if (this.$moment(value).format('HH:mm') < "12:00") {
                    return false
                  }
                }
              // return this.$moment(String(value)).format('HH:mm')
            },
            imageNull(event) { 
                event.target.src = this.imagePreview 
            },
            getData() {
              if(this.search!=""){
                axios.get(`item/search/${this.search}/${this.pagination.page}/${this.pagination.limit}`)
                  .then(response => {   
                    this.pagination.totalpage=response.data.totalpage
                    this.loading = false  
                    this.data = [];
                    this.data = response.data.data;
                  })
                  .catch(errors => {
                    this.loading = false
                    console.log(errors);
                  });
              }else{
                axios.get(`item/${this.pagination.page}/${this.pagination.limit}`)
                  .then(response => {   
                    this.pagination.totalpage=response.data.totalpage
                    this.loading = false  
                    this.data = [];
                    this.data = response.data.data;
                  })
                  .catch(errors => {
                    this.loading = false
                    console.log(errors);
                  });
              }
            },
            switchData(data){
              this.dataPrint=data;
            },
            async printData(item,type) {
              await this.switchData(item);
              if (type=="invoice") {
                this.$htmlToPaper('invoicePrintField');
              }
              if (type=="kwitansi") {
                this.$htmlToPaper('kwitansiPrintField');
              }
              if (type=="surat") {
                this.$htmlToPaper('suratPrintField');
              }
            },
            searchData () {
              this.pagination.page=1;
              if(this.search!=""){
                axios.get(`item/search/${this.search}/${this.pagination.page}/${this.pagination.limit}`)
                    .then(response => {   
                      this.pagination.totalpage=response.data.totalpage
                      this.loading = false  
                      this.data = [];
                      this.data = response.data.data;
                    })
                    .catch(errors => {
                      this.loading = false
                      console.log(errors);
                    });
              }else{
                console.log("search is empty")
                this.getData()
              }
            },
            orderDone(id,name){
              this.$fire({
                    title: "Success",
                    text: `Apakah anda benar-benar ingin menyelesaikan pesanan ${name} ?`,
                    type: "question",
                    showCancelButton: true,
                    confirmButtonColor: '#3085d6',
                    cancelButtonColor: '#d33',
                    confirmButtonText: 'Iya'
                }).then(r => {
                    if (r.value==true) {
                        axios.post(`item/done/${id}`).then((res) =>{
                        this.todos = res.data
                        Fire.$emit('reloaddata')
                        this.$fire({
                          title: "Yey, Pesanan Selesai",
                          text: `Pesanan Dari ${name} berhasil dieselesaikan`,
                          type: "success",
                          timer: 3000
                        }).then(r => {
                          console.log(r.value);
                        });
                        Fire.$emit('AfterCreate');
                      }).catch(() => {
                        swal("Failed!", "There was something wronge.", "warning");
                      })
                    }
                });
            },  
            deleteData(id,name){
                console.log(id)
                this.$fire({
                    title: "Success",
                    text: `Apakah anda benar-benar ingin menghapus ${name} ?`,
                    type: "question",
                    showCancelButton: true,
                    confirmButtonColor: '#3085d6',
                    cancelButtonColor: '#d33',
                    confirmButtonText: 'Yes, delete it!'
                }).then(r => {
                    if (r.value==true) {
                        let data = new FormData();
                        data.append('_method', 'DELETE')
                        axios.post(`item/delete/${id}`, data).then((res) =>{
                          this.todos = res.data
                          Fire.$emit('reloaddata')
                          this.$fire({
                            title: "Pesanan Berhasil Dihapus",
                            text: `User ${name} berhasil dihapus`,
                            type: "success",
                            timer: 3000
                          }).then(r => {
                            console.log(r.value);
                          });
                          Fire.$emit('AfterCreate');
                        }).catch(() => {
                          swal("Failed!", "There was something wronge.", "warning");
                        })
                       
                      }
                });              
            }, 
        },
  };
  
</script>

<style scoped>
@media print {
  @page {
    size: landscape
  }
}
</style>