<template>
  <v-app > 
    <v-container class="backgroundImage"  v-bind:style="{ 'background-image': 'url(image/illustration-main.webp)' }" >
      <v-card class="eleveation-12 white card-size mx-auto" min-width="50%" >

            <v-img :src="'image/logo.webp'" class="logoImage"/>
            <!-- <v-btn
              fab
              color="cyan accent-2"
              top
              absolute
              :style="{left: '37%', transform:'translateX(-50%)',transform:'translateY(-30%)',padding:'15%'}"
              @click="dialog = !dialog"
            >
              <v-icon>mdi-plus</v-icon>
            </v-btn> -->
          <v-form @submit.prevent="submit">
          <v-card-text style="padding:0px" class="mt-4 mx-auto">
            <h3 class=" black--text text--darken-2">LOGIN</h3>
            <p class="grey--text">masukan username dan password</p>
              <v-text-field
                label="username"
                name="username"
                type="text"
                color="blue accent-3"
                class="mt-4"
                hide-details="auto"
                v-model="form.username"
              />
              <v-text-field
                label="password"
                name="password"
                type="password"
                color="blue accent-3"
                class="mt-6"
                hide-details="auto"
                
                v-model="form.password"
              />
            </v-card-text>
          <div class="text-center mt-8">
            <v-btn rounded large min-width="50%" color="blue accent-3" dark type="submit" @click="overlay = !overlay">LOGIN</v-btn>
          </div>
          </v-form>
      </v-card>
    </v-container>

    <v-overlay :value="overlay">
      <v-progress-circular
        style="z-index:999999"
        indeterminate
        size="64"
      ></v-progress-circular>
    </v-overlay>
  </v-app>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
export default {
  name: 'signin',
  components: {
    
  },
  data() {
    return {
      overlay: false,
      step: 1,
      image:`${process.env.BASE_URL}image/illustration.webp`,
      form:{

        username:'',
        password:'',
      }
    }
  },
  props:{
    source: String
  },
  computed:{
    ...mapGetters({
        authenticated: 'auth/authenticated',
    })
  },
  methods: {
    ...mapActions({
      signIn:'auth/signIn'
    }),
    
    submit(){
      this.signIn(this.form).then(()=>{
        console.log(this.authenticated.user.type)
        if (this.authenticated.user.type==1) {
          this.$router.push({name:'homesuper'});
        }else if (this.authenticated.user.type==2) {
          this.$router.push({name:'homeadmin'});
        }else if (this.authenticated.user.type==3) {
          this.$router.push({name:'homesales'});
        }
      }).catch(()=>{
        this.overlay = false
        this.$fire({
          title: "Tidak dapat melanjutkan",
          text: `Password/Username Anda Salah`,
          type: "error",
          timer: 999999999
        }).then(r => {
          console.log(r.value);
        });
      })
    },
    
  },
  watch: {
      overlay (val) {
        val && setTimeout(() => {
          this.overlay = false
        }, 90000)
      },
    },
}
</script>

<style>
  .backgroundImage{
    display: flex;
    align-items:center; 
    height:100%; 
    padding:12%;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: 60%; 
  }
 
  @media screen and (max-width: 768px) {
    .pc {
        display: none !important;
    }
    .card-size{
      padding:10%;
      border-radius:5%;
    }
    .logoImage{
        display: block;
        margin-left: auto;
        margin-right: auto;
        width: 100px;
        height: 100px;
        box-shadow: 2px 2px 6px grey;
        background-color:white;
        padding:12%;
        border-radius: 50% !important;
        border: 1px solid white;
        z-index: 4;
        position: absolute;
        left: 33% !important; 
        transform:translateX(-50%);
        transform:translateY(-100%); 
        object-fit: cover;
    }
  }
  @media screen and (min-width: 768px) {
    .phone {
        display: none !important;
    }
    .card-size{
      padding:10%;
      border-radius:5%;
      max-width:200px !important;
    }
    .logoImage{
        display: block;
        margin-left: auto;
        margin-right: auto;
        width: 150px;
        height:150px;
        box-shadow: 2px 2px 6px grey;
        background-color:white;
        padding:12%;
        border-radius: 50% !important;
        border: 1px solid white;
        z-index: 4;
        position: absolute;
        left: 35% !important; 
        transform:translateX(-50%);
        transform:translateY(-120%); 
        object-fit: cover;
    }
  }
</style>