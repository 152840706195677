var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"content-wrapper"},[_c('section',{staticClass:"content-header"},[_c('div',{staticClass:"container-fluid"},[_c('div',{staticClass:"row"},[_vm._m(0),_c('div',{staticClass:"col-sm-6"},[_c('ol',{staticClass:"breadcrumb float-sm-right"},[_c('li',{staticClass:"breadcrumb-item"},[_c('router-link',{attrs:{"to":{ name:'' }}},[_vm._v(" Table ")])],1),_c('li',{staticClass:"breadcrumb-item active"},[_c('router-link',{attrs:{"to":{ name:'user' }}},[_vm._v(" User ")])],1)])])])])]),_c('section',{staticClass:"content"},[_c('div',{staticClass:"container-fluid"},[_c('div',{attrs:{"id":"app"}},[_c('v-app',[_c('v-main',[_c('v-row',[_c('v-col',[_c('v-card',[_c('v-card-title',[_c('v-spacer'),_c('v-spacer'),_c('v-spacer'),_c('v-text-field',{staticClass:"ml-5",attrs:{"append-icon":"mdi-magnify","label":"Search","single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.data,"search":_vm.search,"item-key":"id","loading":_vm.loading},scopedSlots:_vm._u([{key:`item.actions`,fn:function({ item }){return [_c('v-icon',{staticClass:"mr-2",attrs:{"small":""},on:{"click":function($event){return _vm.openModal(
                                true,
                                item.id,
                              )}}},[_vm._v(" mdi-pencil ")]),_c('v-icon',{attrs:{"small":""},on:{"click":function($event){return _vm.deleteData(
                                item.id,
                                item.username
                              )}}},[_vm._v(" mdi-delete ")])]}}],null,true)})],1)],1),_c('v-col',[_c('v-card',[_c('v-card-title',[_c('h3',[_vm._v(_vm._s(_vm.form.type))]),_c('form',[_c('v-card-text',[_c('v-row',[_c('div',{staticClass:"col-sm-6"},[_c('v-text-field',{attrs:{"label":"Nama Customer","hide-details":"true","required":"","outlined":""},model:{value:(_vm.form.nama),callback:function ($$v) {_vm.$set(_vm.form, "nama", $$v)},expression:"form.nama"}})],1),_c('div',{staticClass:"col-sm-6"},[_c('v-text-field',{attrs:{"label":"No Telp Customer","hide-details":"true","required":"","outlined":""},model:{value:(_vm.form.phone),callback:function ($$v) {_vm.$set(_vm.form, "phone", $$v)},expression:"form.phone"}})],1),_c('div',{staticClass:"col-sm-12"},[_c('v-textarea',{attrs:{"label":"Alamat Customer","hide-details":"true","required":"","outlined":""},model:{value:(_vm.form.alamat),callback:function ($$v) {_vm.$set(_vm.form, "alamat", $$v)},expression:"form.alamat"}})],1)])],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":function($event){_vm.dialog = false}}},[_vm._v(" Close ")]),_c('v-btn',{attrs:{"color":"blue darken-1","text":"","type":"submit"},on:{"click":function($event){return _vm.saveData()}}},[_vm._v(" Save ")])],1)],1)])],1)],1)],1)],1)],1)],1)])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-sm-6"},[_c('h2',[_vm._v("Daftar Pengguna")])])
}]

export { render, staticRenderFns }