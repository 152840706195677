<template>
  <div class="content-wrapper">
    <section class="content-header">
      <div class="container-fluid">
        <div class="row">
          <div class="col-sm-6">
            <h2>Rekap Pesanan</h2>
          </div>
        </div>
      </div>
    </section>
    <section class="content">
      <div class="container-fluid">
        <div id="app">
            <v-app>
              
              <v-card outlined>
                <v-alert
                border="top"
                colored-border
                type="info"
              >
                Masukan jarak waktu dari pesanan untuk memulai merekap
              </v-alert>
              <v-row class="pr-5 pl-5">
                  <v-col md="3" sm="12">
                    <v-menu
                      ref="menuDateStart"
                      v-model="menu.date.start"
                      :close-on-content-click="false"
                      :return-value.sync="menu.date.start"
                      transition="scale-transition"
                      offset-y
                      min-width="auto"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          v-model="rekap.date.start"
                          label="Tanggal Mulai"
                          prepend-icon="mdi-calendar"
                          readonly
                          solo
                          v-bind="attrs"
                          v-on="on"
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        v-model="rekap.date.start"
                        no-title
                        scrollable
                      >
                        <v-spacer></v-spacer>
                        <v-btn
                          text
                          color="primary"
                          @click="menu.date.start = false"
                        >
                          Cancel
                        </v-btn>
                        <v-btn
                          text
                          color="primary"
                          @click="$refs.menuDateStart.save(rekap.date.start)"
                        >
                          OK
                        </v-btn>
                      </v-date-picker>
                    </v-menu>
                  </v-col>
                  <v-col md="3" sm="12">
                    <v-menu
                      ref="menuTimeStart"
                      v-model="menu.time.start"
                      :close-on-content-click="false"
                      :nudge-right="40"
                      :return-value.sync="rekap.time.start"
                      transition="scale-transition"
                      offset-y
                      max-width="290px"
                      min-width="290px"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          v-model="rekap.time.start"
                          label="Waktu Mulai"
                          prepend-icon="mdi-clock-time-four-outline"
                          readonly
                          solo
                          v-bind="attrs"
                          v-on="on"
                        ></v-text-field>
                      </template>
                      <v-time-picker
                        v-if="menu.time.start"
                        v-model="rekap.time.start"
                        full-width
                        format="24hr"
                        @click:minute="$refs.menuTimeStart.save(rekap.time.start)"
                      ></v-time-picker>
                    </v-menu>
                  </v-col>
                  <v-col md="3" sm="12">
                    <v-menu
                      ref="menuDateEnd"
                      v-model="menu.date.end"
                      :close-on-content-click="false"
                      :return-value.sync="menu.date.end"
                      transition="scale-transition"
                      offset-y
                      min-width="auto"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          v-model="rekap.date.end"
                          label="Tanggal Akhir"
                          prepend-icon="mdi-calendar"
                          readonly
                          solo
                          v-bind="attrs"
                          v-on="on"
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        v-model="rekap.date.end"
                        no-title
                        scrollable
                      >
                        <v-spacer></v-spacer>
                        <v-btn
                          text
                          color="primary"
                          @click="rekap"
                        >
                          Cancel
                        </v-btn>
                        <v-btn
                          text
                          color="primary"
                          @click="$refs.menuDateEnd.save(rekap.date.end)"
                        >
                          OK
                        </v-btn>
                      </v-date-picker>
                    </v-menu>
                  </v-col>
                  <v-col md="3" sm="12">
                    <v-menu
                      ref="menuTimeEnd"
                      v-model="menu.time.end"
                      :close-on-content-click="false"
                      :nudge-right="40"
                      :return-value.sync="rekap.time.end"
                      transition="scale-transition"
                      offset-y
                      max-width="290px"
                      min-width="290px"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          v-model="rekap.time.end"
                          label="Waktu Akhir"
                          prepend-icon="mdi-clock-time-four-outline"
                          readonly
                          solo
                          v-bind="attrs"
                          v-on="on"
                        ></v-text-field>
                      </template>
                      <v-time-picker
                        v-if="menu.time.end"
                        v-model="rekap.time.end"
                        full-width
                        format="24hr"
                        @click:minute="$refs.menuTimeEnd.save(rekap.time.end)"
                      ></v-time-picker>
                    </v-menu>
                  </v-col>
                  
                </v-row>
                <v-card-actions class="d-block text-right" >
                  <v-btn
                    elevation="2"
                    color="primary"
                    @click="RekapData(false)"
                  >
                    Lihat Rekap
                  </v-btn>
                  <v-btn
                    elevation="2"
                    color="orange dark-3"
                    dark
                    @click="RekapData(true)"
                  >
                    Lihat Rekap (Dengan Harga)
                  </v-btn>
                </v-card-actions>
              </v-card>
              <v-card 
                outlined
                class="mt-3"
              >
                <v-card-title>
                  <v-row>
                    <v-col>{{ rekap.title }}</v-col>
                    <v-col v-if="rekap.type>0">
                      <div class="d-block text-right">
                        <v-btn
                          elevation="2"
                          color="darken green"
                          dark
                          class="ma-1"
                          @click="DownloadExcel()"
                        >
                          Download Excel
                        </v-btn>
                        <v-btn
                          elevation="2"
                          color="darken red"
                          dark
                          class="ma-1"
                          @click="DownloadPdf()"
                        >
                          Download PDF
                        </v-btn>
                      </div>
                    </v-col>
                  </v-row>
                </v-card-title>
                
                <v-card-text>  
                  <v-simple-table fixed-header height="1000" v-if="rekap.type==1" >
                    <template v-slot:default>
                      <thead>
                        <tr>
                          <th style="width: 5%; ">No</th>
                          <th style="width: 10%;">Pemesan</th>
                          <th style="width: 20%;">Alamat Antar</th>
                          <th style="width: 20%;">Pesanan</th>
                          <th style="width: 5%; ">QTY</th>
                          <th style="width: 5%; ">Jadi</th>
                          <th style="width: 10%; ">Terima</th>
                          <th style="width: 5%;">Rider</th>
                          <th style="width: 5%;">sales</th>
                          <th style="width: 5%;">Order Notes</th>
                        </tr>
                      </thead>
                      <tbody v-for="(items,index) in rekap.data" :key="items.id">
                        <tr style="color:#0AAE46 !important" v-if="formatTime(items.waktu_dipesan) == true" v-for="(item,item_index) in items.sub_item" :key="item.id">
                                <td>{{ item_index ? '-' : index+1 }}</td>
                                <td>{{ item_index ? '-' : items.penerima }}</td>
                                <td>{{ item_index ? '-' : items.alamat }}</td>
                                <td><b>{{item.nama}}</b><br>Menu: {{item.desc}}</td>
                                <td>{{item.banyak|numFormat}}</td>
                                <td>{{ item_index ? '-' : items.jadi | moment("HH:mm") }}</td>
                                <td >{{ item_index ? '-' : items.waktu_dipesan| moment("D/M/YYYY HH:mm") }}</td>                              
                                <td >{{ item_index ? '-' : items.driver }}</td>
                                <td >{{ item_index ? '-' : items.sales_data.name }}</td>
                                <td >{{ item_index ? '-' : items.catatan }}</td>
                          
                        </tr>
                        <tr v-if="formatTime(items.waktu_dipesan) == false" v-for="(item,item_index) in items.sub_item" :key="item.id">
                                <td>{{ item_index ? '-' : index+1 }}</td>
                                <td>{{ item_index ? '-' : items.penerima }}</td>
                                <td>{{ item_index ? '-' : items.alamat }}</td>
                                <td><b>{{item.nama}}</b><br>Menu: {{item.desc}}</td>
                                <td>{{item.banyak|numFormat}}</td>
                                <td>{{ item_index ? '-' : items.jadi | moment("HH:mm") }}</td>
                                <td >{{ item_index ? '-' : items.waktu_dipesan| moment("D/M/YYYY HH:mm") }}</td>                              
                                <td >{{ item_index ? '-' : items.driver }}</td>
                                <td >{{ item_index ? '-' : items.sales_data.name }}</td>
                                <td >{{ item_index ? '-' : items.catatan }}</td>
                        </tr>
                      </tbody>
                    </template>
                  </v-simple-table>

                  <v-simple-table fixed-header height="1000" v-if="rekap.type==2">
                    <template v-slot:default>
                      <thead>
                        <tr>
                          <th style="width: 5%; ">No</th>
                          <th style="width: 10%;">Pemesan</th>
                          <th style="width: 20%;">Alamat Antar</th>
                          <th style="width: 5%;">Sales</th>
                          <th style="width: 10%;">Pesanan</th>
                          <th style="width: 5%; ">QTY</th>
                          <th style="width: 10%; ">harga Satuan</th>
                          <th style="width: 15%;">Total Harga</th>
                        </tr>
                      </thead>
                      <tbody v-for="(items,index) in rekap.data" :key="items.id">
                        <tr v-for="(item,item_index) in items.sub_item" :key="item.id">
                          <td>{{ item_index ? '-' : index+1 }}</td>
                          <td>{{ item_index ? '-' : items.penerima}}</td>
                          <td>{{ item_index ? '-' : items.alamat }}</td>
                          <td>{{ item_index ? '-' : items.sales_data.name }}</td>
                          <td><b>{{item.nama}}</b></td>
                          <td>{{item.banyak|numFormat}}</td>
                          <td>Rp. {{item.harga|numFormat}}</td>
                          <td>Rp. {{ item_index ? '-' : items.total_harga|numFormat }}</td>
                        </tr>
                      </tbody>
                    </template>
                  </v-simple-table>

                  <table v-if="rekap.type!=0" id="customers" style="color: #131a47; font-size:10pt !important; margin: 0px !important; margin-top:3%; width: 100%;">
                    <tr >
                        <td style="">Direkap Oleh: <br> {{authenticated.user.name}}</td>
                        <td style="width:20%"><br> Total Nasi: <br>Total Catering <br>Total Snack <br>Total Tumpeng</td>
                        <td style="width:10%">Qty: <br>{{rekap.total_nasi}} <br>{{rekap.total_catering}} <br>{{rekap.total_snack}} <br>{{rekap.total_tumpeng}}</td>
                        <td style="width:10%"  v-if="rekap.type==2">Total Harga: <br>Rp. {{rekap.total_harga|numFormat}} </td>
                    </tr>
                  </table>
                </v-card-text>

                <div v-if="rekap.type==1" id="RekapPrintField">
                  <div style="font-weight: 900; font-size:16pt">Rekap untuk tanggal: {{rekap.date.start | moment("D/M/YYYY")}} {{rekap.time.start}}=>{{rekap.date.end | moment("D/M/YYYY")}} {{rekap.time.end}}</div>
                  <table id="customers" style="color: #131a47; font-size:10pt !important; margin: 0px !important;">
                    <tr>
                      <th style="width: 5%; ">No</th>
                      <th style="width: 10%;">Pemesan</th>
                      <th style="width: 20%;">Alamat Antar</th>
                      <th style="width: 20%;">Pesanan</th>
                      <th style="width: 5%; ">QTY</th>
                      <th style="width: 5%; ">Jadi</th>
                      <th style="width: 10%; ">Terima</th>
                      <th style="width: 5%;">Rider</th>
                      <th style="width: 5%;">sales</th>
                      <th style="width: 5%;">Order Notes</th>
                    </tr>
                    <tbody v-for="(items,index) in rekap.data" :key="items.id">
                      <tr style="background-color: white !important;color:#0AAE46 !important" v-if="formatTime(items.waktu_dipesan) == true" v-for="(item,item_index) in items.sub_item" :key="item.id">
                              <td>{{ item_index ? '-' : index+1 }}</td>
                              <td>{{ item_index ? '-' : items.penerima }}</td>
                              <td>{{ item_index ? '-' : items.alamat }}</td>
                              <td><b>{{item.nama}}</b><br>Menu: {{item.desc}}</td>
                              <td>{{item.banyak|numFormat}}</td>
                              <td>{{ item_index ? '-' : items.jadi | moment("HH:mm") }}</td>
                              <td >{{ item_index ? '-' : items.waktu_dipesan| moment("D/M/YYYY HH:mm") }}</td>                              
                              <td >{{ item_index ? '-' : items.driver }}</td>
                              <td >{{ item_index ? '-' : items.sales_data.name }}</td>
                              <td >{{ item_index ? '-' : items.catatan }}</td>
                        
                      </tr>
                      <tr  v-if="formatTime(items.waktu_dipesan) == false" v-for="(item,item_index) in items.sub_item" :key="item.id">
                              <td>{{ item_index ? '-' : index+1 }}</td>
                              <td>{{ item_index ? '-' : items.penerima }}</td>
                              <td>{{ item_index ? '-' : items.alamat }}</td>
                              <td><b>{{item.nama}}</b><br>Menu: {{item.desc}}</td>
                              <td>{{item.banyak|numFormat}}</td>
                              <td>{{ item_index ? '-' : items.jadi | moment("HH:mm") }}</td>
                              <td >{{ item_index ? '-' : items.waktu_dipesan| moment("D/M/YYYY HH:mm") }}</td>                              
                              <td >{{ item_index ? '-' : items.driver }}</td>
                              <td >{{ item_index ? '-' : items.sales_data.name }}</td>
                              <td >{{ item_index ? '-' : items.catatan }}</td>
                      </tr>
                    </tbody>
                  </table>
                  <table id="customers" style="color: #131a47; font-size:10pt !important; margin: 0px !important; margin-top:3%">
                    <tr>
                        <td style="">Direkap Oleh: <br> {{authenticated.user.name}}</td>
                        <td style="width:20%"><br> Total Nasi <br>Total Catering <br>Total Snack <br>Total Tumpeng</td>
                        <td style="width:10%">Qty <br>{{rekap.total_nasi}} <br>{{rekap.total_catering}} <br>{{rekap.total_snack}} <br>{{rekap.total_tumpeng}}</td>
                    </tr>
                  </table>
                </div>

                <div style="display:none" id="RekapHargaPrintField">
                  <div style="font-weight: 900; font-size:16pt">Rekap untuk tanggal: {{rekap.date.start | moment("D/M/YYYY")}} {{rekap.time.start}}=>{{rekap.date.end | moment("D/M/YYYY")}} {{rekap.time.end}}</div>
                  <table id="customers" style="color: #131a47; font-size:10pt !important; margin: 0px !important;">
                    <tr>
                      <th style="width: 5%; ">No</th>
                      <th style="width: 10%;">Pemesan</th>
                      <th style="width: 20%;">Alamat Antar</th>
                      <th style="width: 5%;">Sales</th>
                      <th style="width: 10%;">Pesanan</th>
                      <th style="width: 5%; ">QTY</th>
                      <th style="width: 10%; ">harga Satuan</th>
                      <th style="width: 15%;">Total Harga</th>
                    </tr>
                    <span v-for="(items,index) in rekap.data" :key="items.id">
                      <tr v-for="(item,item_index) in items.sub_item" :key="item.id">
                        <td>{{ item_index ? '-' : index+1 }}</td>
                        <td>{{ item_index ? '-' : items.penerima}}</td>
                        <td>{{ item_index ? '-' : items.alamat }}</td>
                        <td>{{ item_index ? '-' : items.sales_data.name }}</td>
                        <td><b>{{item.nama}}</b></td>
                        <td>{{item.banyak|numFormat}}</td>
                        <td>Rp. {{item.harga|numFormat}}</td>
                        <td>Rp. {{ item_index ? '-' : items.total_harga|numFormat }}</td>
                      </tr>
                    </span>
                  </table>
                  <table id="customers" style="color: #131a47; font-size:10pt !important; margin: 0px !important; margin-top:3%">
                    <tr>
                        <td style="">Direkap Oleh: <br> {{authenticated.user.name}}</td>
                        <td style="width:20%"><br> Total Nasi: <br>Total Catering <br>Total Snack <br>Total Tumpeng</td>
                        <td style="width:10%">Qty: <br>{{rekap.total_nasi}} <br>{{rekap.total_catering}} <br>{{rekap.total_snack}} <br>{{rekap.total_tumpeng}}</td>
                        <td style="width:10%">Total Harga: <br>Rp. {{rekap.total_harga|numFormat}} </td>
                    </tr>
                  </table>
                </div>

              </v-card>
            </v-app>
        </div>
      </div>
    </section>
  </div>
</template>
<script>
import { mapGetters, mapActions } from 'vuex'
export default {
      created() {
          this.getData();
          Fire.$on('reloaddata', () => {
            this.getData();
          })
      },
      data() {        
          return {
            menu:{
              time:{
                start:null,
                end:null
              },
              date:{
                start:null,
                end:null
              }
            },
            rekap:{
              title:"Rekap Pesanan",
              type:0,
              total_harga:0,
              total_nasi:0,
              total_catering:0,
              total_snack:0,
              total_tumpeng:0,
              time:{
                start:null,
                end:null
              },
              date:{
                start:null,
                end:null
              },
              data:[
                  {
                    id:0,
                    penerima:"",
                    alamat:"",
                    no_hp:"",
                    total_harga:0,
                    total_terbayar:0,
                    driver:"",
                    nomor_driver:"",
                    created_by:0,
                    status_id:0,
                    sales:0,
                    jadi:"",
                    diterima:"",
                    created_at:"",
                    updated_at:null,
                    deleted_at:null,
                    status:{
                        id:0,
                        name:"",
                        desc:"",
                        created_at:"",
                        updated_at:null,
                        deleted_at:null
                    },
                    sales_data:{
                        id:0,
                        name:"",
                        desc:"",
                        created_at:"",
                        updated_at:null,
                        deleted_at:null
                    },
                    user:{
                        id:0,
                        username:"",
                        name:"",
                        email:"",
                        created_at:"",
                        updated_at:null,
                        deleted_at:null
                    },
                    sub_item:[
                        {
                          id:0,
                          nama:"",
                          banyak:0,
                          desc:"",
                          type_id:"",
                          harga:0,
                          item_id:0,
                        },
                    ]
                  },
                ],
            },
            
          }
      },
      computed:{
          ...mapGetters({
              authenticated: 'auth/authenticated',
          }),
          page() {
            return this.pagination.page;
          }
      },
      methods: {
        formatTime(value){
          if (value) {
            if (this.$moment(value).format('HH:mm') >= "12:00") {
              return true
            }
            if (this.$moment(value).format('HH:mm') < "12:00") {
              return false
            }
          }
        },
        async RekapData(harga){
              let data = new FormData();
              data.append('start_time',this.rekap.time.start)
              data.append('end_time',this.rekap.time.end)
              data.append('start_date',this.rekap.date.start)
              data.append('end_date',this.rekap.date.end)
            
              await axios.post(`item/rekap`,data)
                  .then(response => {   
                    this.loading = false  
                    this.rekap.data = response.data.data;
                  })
                  .catch(errors => {
                    this.loading = false
                    console.log(errors);
                  });
              await this.RekapExtraData(this.rekap.data,harga);
        },
        RekapExtraData(data,harga){
              this.rekap.total_nasi=0;
              this.rekap.total_catering=0;
              this.rekap.total_snack=0;
              this.rekap.total_tumpeng=0;
              
              data.forEach(element => {
                let sub = element.sub_item
                sub.forEach(item => {
                  if (item.type_id==1) {
                   this.rekap.total_nasi = this.rekap.total_nasi+parseInt(item.banyak);
                  }
                  if (item.type_id==2) {
                    this.rekap.total_catering = this.rekap.total_catering+parseInt(item.banyak);
                  }
                  if (item.type_id==3) {
                    this.rekap.total_snack = this.rekap.total_snack+parseInt(item.banyak);
                  }
                  if (item.type_id==4) {
                    this.rekap.total_tumpeng = this.rekap.total_tumpeng+parseInt(item.banyak);
                  }
                });
              });  
              if (harga) {
                data.forEach(element => {
                  this.rekap.total_harga = this.rekap.total_harga+element.total_harga;
                });
                this.rekap.type=2
                this.rekap.title="Rekapan Pesanan Dengan Harga"
              }else{
                this.rekap.type=1
                this.rekap.title="Rekapan Pesanan"
              }         
              
            },
        DownloadExcel(){
          axios.post(`export/pesanan/${this.rekap.date.start}/${this.rekap.date.end}/${this.rekap.time.start}/${this.rekap.time.end}/${this.rekap.type}`, null,
                  {
                      headers:
                      {
                          'Content-Disposition': "attachment; filename=template.xlsx",
                          'Content-Type': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
                      },
                      responseType: 'arraybuffer',
                  }
              ).then(response => {
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', 'Rekap Customer.xlsx');
                document.body.appendChild(link);
                link.click();
              });
        },

        DownloadPdf(){
          let field ='RekapPrintField'
          if (this.rekap.type==2) {
            field = 'RekapHargaPrintField'
          }else if(this.rekap.type==1){
            field = 'RekapPrintField'
          }
          this.$htmlToPaper(field,{windowTitle:'Rekap', styles: ['http://kotakanbackend.resepedia.net/landscape.css',] });
        }
      },
};

</script>
