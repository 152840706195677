<template>
  <div id="app" >
    <div v-if="!authenticated">
      <router-view/>
    </div>
    <div class="wrapper" v-else>
      <NavBar/>
      <SideBar/>
    <router-view style="padding-bottom:20%; background-color:#f4f6f9"/>
    </div>
  </div>

</template>

<script>
  import NavBar from '@/components/NavBar'
  import SideBar from '@/components/SideNav'
  import { mapGetters } from 'vuex'
  export default {
    components:{
      NavBar,
      SideBar,
    },
    computed:{
        ...mapGetters({
            authenticated: 'auth/authenticated',
        })
    },
  }
</script>

<style lang="scss">
  @import '~vuetify/src/components/VStepper/_variables.scss';

  @import url("http://fonts.googleapis.com/css?family=Lato&subset=latin,latin-ext");
  $body-font-family: 'Lato' !important;
  
</style>