<template>
    <aside class="main-sidebar sidebar-dark-primary " style="background-color:#0f3057;">
        <a href="#" class="brand-link" style="text-align:center" >
            <img :src="'image/logo.webp'" data-widget="pushmenu" alt="AdminLTE Logo" style="width: 45px;object-fit: cover;max-height: 45px; background-color:white;border-radius:20%;padding:5px">
            <span class="brand-text font-weight-light" style="padding-left:5px;font-weight:500!important;font-size:20pt;vertical-align: top;">Si-Data</span>
        </a>
        <div class="sidebar">
        <div class="user-panel mt-3 pb-3 mb-3 d-flex" style="align-items:center">
            <div class="image">
                <i class="left ml-2 mr-2 fas fa-user-circle"  style="font-size:20pt; color:white"></i>
            </div>
            <div class="info" style="white-space:break-spaces;">
                <a href="#" class="d-block" style="font-weight: 100;">Selamat datang</a>
                <a href="#" class="d-block" style="font-weight: 500;">{{authenticated.user.name}}</a>
                <!-- <a href="#" class="d-block">{{user.profile.nama}}</a> -->
            </div>
        </div>
        <nav class="mt-2">
            <ul class="nav nav-pills nav-sidebar flex-column" data-widget="treeview" role="menu" data-accordion="false">
                <li class="nav-item" >
                    <a href="#" class="nav-link" v-show="authenticated.user.type==1">
                        <i class="nav-icon fas fa-tachometer-alt"></i>
                        <p>
                            <router-link :to="{ name:'homesuper' }">
                            Dashboard 
                            </router-link>
                        </p>
                    </a>
                    <a href="#" class="nav-link" v-show="authenticated.user.type==2">
                        <i class="nav-icon fas fa-tachometer-alt"></i>
                        <p>
                            <router-link :to="{ name:'homeadmin' }">
                            Dashboard 
                            </router-link>
                        </p>
                    </a>
                    <a href="#" class="nav-link" v-show="authenticated.user.type==3">
                        <i class="nav-icon fas fa-tachometer-alt"></i>
                        <p>
                            <router-link :to="{ name:'homesales' }">
                            Dashboard 
                            </router-link>
                        </p>
                    </a>
                </li>
                
                <li class="nav-item" v-show="authenticated.user.type!=3">
                    <a href="#" class="nav-link">
                        <i class="fas fa-box nav-icon"></i>  
                        <p>
                            Pesanan 
                            <i class="right fas fa-angle-left"></i>
                        </p>
                    </a>    
                    <ul class="nav nav-treeview">
                        <li class="nav-item">
                            <router-link class="nav-link" :to="{ name:'item' }">
                                <i class="fas fa-angle-right text-primary nav-icon"></i>
                                <p>Tabel Pesanan</p>
                            </router-link>
                        </li>
                        <li class="nav-item">
                            <router-link class="nav-link" :to="{ name:'itemCard' }">
                                <i class="fas fa-angle-right text-primary nav-icon"></i>
                                <p>Daftar Pesanan</p>
                            </router-link>
                        </li>
                        <li class="nav-item">
                            <router-link 
                                class="nav-link"  
                                :to="{ 
                                    name: 'itemform',
                                    params: {
                                        update: false, 
                                    } 
                                }
                            ">
                                <i class="fas fa-angle-right text-primary nav-icon"></i>
                                <p> Formulir Pesanan  </p>
                            </router-link>
                        </li>
                    </ul>
                </li>
                <li class="nav-item" v-show="authenticated.user.type!=3">
                    <a href="#" class="nav-link">
                        <i class="fas fa-folder nav-icon"></i>  
                        <p>
                            Rekap
                            <i class="right fas fa-angle-left"></i>
                        </p>
                    </a>    
                    <ul class="nav nav-treeview">
                        <li class="nav-item">
                            <router-link class="nav-link" 
                                :to="{ 
                                    name:'rekapPesanan',
                                    params: {
                                        harga: false, 
                                    }  
                                }">
                                <i class="fas fa-angle-right text-primary nav-icon"></i>
                                <p> Pesanan </p>
                            </router-link>
                        </li>
                        <li class="nav-item">
                            <router-link class="nav-link" :to="{ name:'rekapCustomer' }">
                                <i class="fas fa-angle-right text-primary nav-icon"></i>
                                <p> Customer </p>
                            </router-link>
                        </li>
                    </ul>
                </li>
                <li class="nav-item" v-show="authenticated.user.type!=3">
                    <a href="#" class="nav-link">
                        <i class="fas fa-cogs nav-icon"></i>  
                        <p>
                            Data Tambahan
                            <i class="right fas fa-angle-left"></i>
                        </p>
                    </a>    
                    <ul class="nav nav-treeview">
                        <li class="nav-item">
                            <router-link class="nav-link" :to="{ name:'status' }">
                                <i class="fas fa-angle-right text-primary nav-icon"></i>
                                <p> Daftar Status </p>
                            </router-link>
                        </li>
                        <li class="nav-item">
                            <router-link class="nav-link" :to="{ name:'customer' }">
                                <i class="fas fa-angle-right text-primary nav-icon"></i>
                                <p> Daftar Customer </p>
                            </router-link>
                        </li>
                        <li class="nav-item">
                            <router-link class="nav-link" :to="{ name:'menu' }">
                                <i class="fas fa-angle-right text-primary nav-icon"></i>
                                <p> Daftar Menu </p>
                            </router-link>
                        </li>
                    </ul>
                </li>
                <li class="nav-item" v-show="authenticated.user.type==1">
                    <router-link class="nav-link" :to="{name:'user'}">
                        <i class="fas fa-users  nav-icon"></i>
                        <p> Daftar Pengguna/User </p>
                    </router-link>
                </li>
            </ul>
        </nav>
        </div>
    </aside>
</template>

<script>
    import { mapGetters, mapActions } from 'vuex'
    export default {
        computed:{
            ...mapGetters({
                authenticated: 'auth/authenticated',
            })
        },
        mounted(){
            $('[data-widget="treeview"]').Treeview('init');
            $('[data-widget="controlsidebar"]').ControlSidebar('init');
            $('[data-widget="pushmenu"]').PushMenu('init');
        }
        
    }
</script>

<style>
/* width */
::-webkit-scrollbar {
  width: 7px;
}

/* Track */
::-webkit-scrollbar-track {
  background: rgba(0,0,0,0);
  
}
 
/* Handle */
::-webkit-scrollbar-thumb {
  background: rgba(100,100,100,0.3);
  border-radius:100px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555; 
}
.main-sidebar .sidebar {
    overflow-y: scroll;
}
</style>