<template>
  <div class="content-wrapper">
    <section class="content-header">
      <div class="container-fluid">
        <div class="row">
          <div class="col-sm-6">
            <h2>Rekap Pesanan</h2>
          </div>
        </div>
      </div>
    </section>
    <section class="content">
      <div class="container-fluid">
        <div id="app">
            <v-app>
              
              <v-card outlined>
                <v-alert
                border="top"
                colored-border
                type="info"
              >
                Masukan jarak waktu dari pesanan untuk memulai merekap
              </v-alert>
                <v-row class="pr-5 pl-5">
                  <v-col md="3" sm="12">
                    <v-menu
                      ref="menuDateStart"
                      v-model="menu.date.start"
                      :close-on-content-click="false"
                      :return-value.sync="menu.date.start"
                      transition="scale-transition"
                      offset-y
                      min-width="auto"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          v-model="rekap.date.start"
                          label="Tanggal Mulai"
                          prepend-icon="mdi-calendar"
                          readonly
                          solo
                          v-bind="attrs"
                          v-on="on"
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        v-model="rekap.date.start"
                        no-title
                        scrollable
                      >
                        <v-spacer></v-spacer>
                        <v-btn
                          text
                          color="primary"
                          @click="menu.date.start = false"
                        >
                          Cancel
                        </v-btn>
                        <v-btn
                          text
                          color="primary"
                          @click="$refs.menuDateStart.save(rekap.date.start)"
                        >
                          OK
                        </v-btn>
                      </v-date-picker>
                    </v-menu>
                  </v-col>
                  <v-col md="3" sm="12">
                    <v-menu
                      ref="menuTimeStart"
                      v-model="menu.time.start"
                      :close-on-content-click="false"
                      :nudge-right="40"
                      :return-value.sync="rekap.time.start"
                      transition="scale-transition"
                      offset-y
                      max-width="290px"
                      min-width="290px"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          v-model="rekap.time.start"
                          label="Waktu Mulai"
                          prepend-icon="mdi-clock-time-four-outline"
                          readonly
                          solo
                          v-bind="attrs"
                          v-on="on"
                        ></v-text-field>
                      </template>
                      <v-time-picker
                        v-if="menu.time.start"
                        v-model="rekap.time.start"
                        full-width
                        format="24hr"
                        @click:minute="$refs.menuTimeStart.save(rekap.time.start)"
                      ></v-time-picker>
                    </v-menu>
                  </v-col>
                  <v-col md="3" sm="12">
                    <v-menu
                      ref="menuDateEnd"
                      v-model="menu.date.end"
                      :close-on-content-click="false"
                      :return-value.sync="menu.date.end"
                      transition="scale-transition"
                      offset-y
                      min-width="auto"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          v-model="rekap.date.end"
                          label="Tanggal Akhir"
                          prepend-icon="mdi-calendar"
                          readonly
                          solo
                          v-bind="attrs"
                          v-on="on"
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        v-model="rekap.date.end"
                        no-title
                        scrollable
                      >
                        <v-spacer></v-spacer>
                        <v-btn
                          text
                          color="primary"
                          @click="rekap"
                        >
                          Cancel
                        </v-btn>
                        <v-btn
                          text
                          color="primary"
                          @click="$refs.menuDateEnd.save(rekap.date.end)"
                        >
                          OK
                        </v-btn>
                      </v-date-picker>
                    </v-menu>
                  </v-col>
                  <v-col md="3" sm="12">
                    <v-menu
                      ref="menuTimeEnd"
                      v-model="menu.time.end"
                      :close-on-content-click="false"
                      :nudge-right="40"
                      :return-value.sync="rekap.time.end"
                      transition="scale-transition"
                      offset-y
                      max-width="290px"
                      min-width="290px"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          v-model="rekap.time.end"
                          label="Waktu Akhir"
                          prepend-icon="mdi-clock-time-four-outline"
                          readonly
                          solo
                          v-bind="attrs"
                          v-on="on"
                        ></v-text-field>
                      </template>
                      <v-time-picker
                        v-if="menu.time.end"
                        v-model="rekap.time.end"
                        full-width
                        format="24hr"
                        @click:minute="$refs.menuTimeEnd.save(rekap.time.end)"
                      ></v-time-picker>
                    </v-menu>
                  </v-col>
                  
                </v-row>
                <v-card-actions class="d-block text-right" >
                  <v-btn
                    elevation="2"
                    color="primary"
                    @click="RekapData()"
                  >
                    Lihat Rekap
                  </v-btn>
                </v-card-actions>
              </v-card>
              <v-card 
                outlined
                class="mt-3"
              >
                <v-card-title>
                  <v-row>
                    <v-col>{{ rekap.title }}</v-col>
                    <v-col v-if="rekap.type>0">
                      <div class="d-block text-right">
                        <v-btn
                          elevation="2"
                          color="primary"
                          @click="DownloadRekap()"
                        >
                          Download Rekap
                        </v-btn>
                      </div>
                    </v-col>
                  </v-row>
                </v-card-title>
                <v-card-text>
                  <v-simple-table fixed-header height="1000">
                    <template v-slot:default>
                      <thead>
                        <tr>
                          <th>Pelanggan</th>
                          <th>No Hp</th>
                          <th>Alamat</th>
                          <th>quantitas pembelian</th>
                          <th>total spending</th>
                          <th>total spending yang telah lunas</th>
                          <th>Pemesanan Terakhir</th>
                        </tr>
                      </thead>
                      <tbody >
                        <tr v-for="(item) in rekap.data" :key="item.id">
                          <td>{{ item.penerima }}</td>
                          <td>{{ item.no_hp }}</td>
                          <td>{{ item.alamat }}</td>
                          <td>{{ item.total_pesanan_terjadi }}</td>
                          <td>{{ item.total_nominal_pemesanan }}</td>
                          <td>{{ item.total_lunas }}</td>
                          <td>{{ item.pesanan_terakhir }}</td>
                        </tr>
                      </tbody>
                    </template>
                  </v-simple-table>
                </v-card-text>
              </v-card>
            </v-app>
        </div>
      </div>
    </section>
  </div>
</template>
<script>
import { mapGetters, mapActions } from 'vuex'
export default {
      created() {
          this.getData();
          Fire.$on('reloaddata', () => {
            this.getData();
          })
      },
      data() {        
          return {
            menu:{
              time:{
                start:null,
                end:null
              },
              date:{
                start:null,
                end:null
              }
            },
            rekap:{
              title:"Rekap Customer",
              type:0,
              total_harga:0,
              total_nasi:0,
              total_catering:0,
              total_snack:0,
              total_tumpeng:0,
              time:{
                start:null,
                end:null
              },
              date:{
                start:null,
                end:null
              },
              data:[
                  {
                    penerima:null,
                    no_hp:null,
                    alamat:null,
                    total_pesanan_terjadi:null,
                    total_nominal_pemesanan:null,
                    total_lunas:null,
                    pesanan_terakhir:null,
                  },
                ],
            },
            
          }
      },
      computed:{
          ...mapGetters({
              authenticated: 'auth/authenticated',
          }),
          page() {
            return this.pagination.page;
          }
      },
      methods: {
        formatTime(value){
          if (value) {
            if (this.$moment(value).format('HH:mm') >= "12:00") {
              return true
            }
            if (this.$moment(value).format('HH:mm') < "12:00") {
              return false
            }
          }
        },
        async RekapData(){
          let data = new FormData();
              data.append('start_time',this.rekap.time.start)
              data.append('end_time',this.rekap.time.end)
              data.append('start_date',this.rekap.date.start)
              data.append('end_date',this.rekap.date.end)
            
              await axios.post(`customer/rekap`,data)
                  .then(response => {   
                    this.loading = false  
                    this.rekap.data = response.data.data;
                  })
                  .catch(errors => {
                    this.loading = false
                    console.log(errors);
                  });
              this.rekap.type=1
        },
        DownloadRekap(){
          axios.post(`export/customer/${this.rekap.date.start}/${this.rekap.date.end}/${this.rekap.time.start}/${this.rekap.time.end}`, null,
                  {
                      headers:
                      {
                          'Content-Disposition': "attachment; filename=template.xlsx",
                          'Content-Type': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
                      },
                      responseType: 'arraybuffer',
                  }
              ).then(response => {
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', `Rekap Customer ${this.rekap.date.start} jam ${this.rekap.time.start} - ${this.rekap.date.end} jam ${this.rekap.time.end}.xlsx`);
                document.body.appendChild(link);
                link.click();
              });
        }
      },
};

</script>
