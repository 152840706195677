<template>

  <v-app>
    <div class="content-wrapper">
      <section class="content-header">
        <div class="container-fluid">
          <div class="row">
            <div class="col-sm-6">
              <h2>Daftar Status</h2>
            </div>
            <div class="col-sm-6">
              <ol class="breadcrumb float-sm-right">
                <li class="breadcrumb-item ">
                  <router-link :to="{}">
                          Table
                  </router-link>
                </li>
                <li class="breadcrumb-item active">
                  <router-link :to="{ name:'status' }">
                          Status
                  </router-link>
                </li>
              </ol>
            </div>
          </div>
        </div>
      </section>
      <section class="content">
      <div class="container-fluid">
        <div id="app">
                <v-main>
                    <v-card>
                        <v-card-title>
                            <!-- <v-btn
                                color="primary"
                                dark
                                class="mt-3"
                                @click="addNew"
                                >
                                    Tambah Baru
                            </v-btn> -->
                            <v-spacer></v-spacer>
                            <v-spacer></v-spacer>
                            <v-spacer></v-spacer>
                            <v-text-field
                                v-model="search"
                                append-icon="mdi-magnify"
                                label="Search"
                                class="ml-5"
                                single-line
                                hide-details
                            ></v-text-field>
                        </v-card-title> 
                        <!-- Datatable Start -->
                        <v-data-table
                            :headers="headers"
                            :items="data"
                            :search="search"
                            item-key="nama"
                            :loading="loading"
                            class="elevation-1"
                            >
                            <!-- <template v-slot:[`item.actions`]="{ item }">
                                
                                <v-icon 
                                  small 
                                  class="mr-2" 
                                  @click="
                                    editData(
                                      item.id,
                                      item.nama,
                                    )
                                  "
                                >
                                  mdi-pencil
                                </v-icon>
                                <v-icon
                                  small
                                  @click="
                                    deleteData(
                                      item.id,
                                      item.nama
                                    )
                                  "
                                >
                                  mdi-delete
                                </v-icon>
                            </template> -->
                        </v-data-table>
                        <!-- Datatable End -->
                    </v-card>
                </v-main>
        </div>
      </div>
      <!-- Role Modal Start -->
      <v-dialog
        v-model="dialog"
        max-width="600px"
        max-height="400px"
        style="z-index:100000;"
      >
        <v-card>
          <v-card-title>
            <span class="headline">Tambah Role Baru</span>
          </v-card-title>
          <form @submit.prevent="saveData" >
          <v-card-text>
              <v-row>
                <div class="col-sm-6">
                    <div class="form-group">
                        <v-text-field
                            v-model="form.id"
                            label="Id Role"
                            required
                            outlined
                        />
                    </div>               
                </div>
                <div class="col-sm-6">
                    <div class="form-group">
                        <v-text-field
                            v-model="form.nama"
                            label="Nama Role"
                            required
                            outlined
                        />
                    </div>               
                </div>   
              </v-row>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="blue darken-1"
              text
              @click="dialog = false"
            >
              Close
            </v-btn>
            <v-btn
              color="blue darken-1"
              text
              @click="dialog = false"
              type="submit"
            >
              Save
            </v-btn>
          </v-card-actions>
          </form>
        </v-card>
      </v-dialog>
      <!-- Role Modal End -->
    </section>
    </div>
  </v-app>
    
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
  export default {
        created() {
            this.getData();
            Fire.$on('reloaddata', () => {
              this.getData();
              this.reset();
            })
        },
    
        data() {        
            return {
              data: [],
              singleExpand: true,
              dialog: false,
              update: false,
              loading: true,
              search: '',
              id:'',

              headers: [
                  { text: 'Id Role',value: 'id', },
                  { text: 'Nama',value: 'name', },
                  { text: 'Desc',value: 'desc', },
                  // { text: 'Actions', value: 'actions', sortable: false },
              ],
              
              form: new Form({
                id:'',
                nama:'',              
              }),
            }
        },
        computed:{
            ...mapGetters({
                authenticated: 'auth/authenticated',
            })
        },
        methods: {
            getData() {
                axios.get('status')
                    .then(response => {     
                      this.loading = false
                      this.data = response.data.data;
                    })
                    .catch(errors => {
                      this.loading = false;
                      console.log(errors);
                    });
            },
            reset(){
                this.form.id='';
                this.form.nama='';
            },
            
            addNew(id,name,takaran){
                this.dialog=true;
                this.update=false;
            },
            editData(id,name){
                this.update=true;
                this.dialog=true;
                this.form.nama=name;            
                this.form.id=id;         
                this.id=id;         
            },
            filterText (value, search, item) {
                return value != null &&
                search != null &&
                typeof value === 'string' &&
                value.toString().indexOf(search) !== -1
            },
            saveData(){
              let data = new FormData();
              data.append('id',this.form.id)
              data.append('role_name',this.form.nama)
              if (this.update==true) {
                  data.append('_method', 'PUT')
                  axios.post(`roles/${this.id}`,data).then((res)=>{
                      this.$fire({
                          title: "Success",
                          text: `Role baru berhasil ditambahkan`,
                          type: "success",
                      }).then(r => {     
                      });
                    Fire.$emit('reloaddata');
                    Fire.$emit('AfterCreate');           
                  })   
              }
              else{
                  axios.post('roles',data).then((res)=>{
                      Fire.$emit('reloaddata');
                        this.$fire({
                            title: "Success",
                            text: `Role baru ${this.form.nama} berhasil diperbaharui`,
                            type: "success",
                        }).then(r => {
                        });
                      Fire.$emit('AfterCreate');                               
                      });                               
              }
                         
            },
            deleteData(id,name){
                console.log(id)
                this.$fire({
                    title: "Warning",
                    text: `apakah anda benar-benar ingin menghapus Role ${name} ?`,
                    type: "question",
                    showCancelButton: true,
                    confirmButtonColor: '#3085d6',
                    cancelButtonColor: '#d33',
                    confirmButtonText: 'Yes, delete it!'
                }).then(r => {
                    if (r.value==true) {
                        let data = new FormData();
                        data.append('_method', 'DELETE')
                        axios.post(`roles/${id}`, data).then((res) =>{
                            this.todos = res.data
                            Fire.$emit('reloaddata')
                            this.$fire({
                              title: "Title",
                              text: `Role ${name} berhasil dihapus`,
                              type: "success",
                              timer: 3000
                            }).then(r => {
                              console.log(r.value);
                            });
                            Fire.$emit('AfterCreate');
                        }).catch(() => {
                            swal("Failed!", "There was something wronge.", "warning");
                        })
                       
                      }
                });              
            },      
        },
  };
  
</script>