export default {
    install(Vue, options) {
        Vue.filter("EmptyCheck", value => {
            if (value) {
                return value;
            }else{
                return '<span style="opacity: 0.5;">Data Masih Kosong</span>';
            }
          });
    }
  };