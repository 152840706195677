<template>
    <div class="content-wrapper">
      <section class="content-header">
        <div class="container-fluid">
          <div class="row">
            <div class="col-sm-6">
              <h2>Daftar Menu</h2>
            </div>
            <div class="col-sm-6">
              <ol class="breadcrumb float-sm-right">
                <li class="breadcrumb-item ">
                  <router-link :to="{ name:'' }">
                          Table
                  </router-link>
                </li>
                <li class="breadcrumb-item active">
                  <router-link :to="{ name:'menu' }">
                          Menu
                  </router-link>
                </li>
              </ol>
            </div>
          </div>
        </div>
      </section>
      <section class="content">
      <div class="container-fluid">
        <div id="app">
            <v-app>
                <v-main>
                  <v-row>
                    <v-col>
                      <v-card>
                        <v-card-title>
                          <v-spacer></v-spacer>
                          <v-spacer></v-spacer>
                          <v-spacer></v-spacer>
                          <v-text-field
                              v-model="search"
                              append-icon="mdi-magnify"
                              label="Search"
                              class="ml-5"
                              single-line
                              hide-details
                          ></v-text-field>
                        </v-card-title> 
                        <!-- Datatable Start -->
                        <v-data-table
                            :headers="headers"
                            :items="data"
                            :search="search"
                            item-key="id"
                            :loading="loading"
                            class="elevation-1"
                        >
                          <template v-slot:[`item.actions`]="{ item }">
                              <v-icon 
                                small 
                                class="mr-2"
                                @click="
                                  openModal(
                                    true,
                                    item.id,
                                  )
                                "
                              >
                                  mdi-pencil
                              </v-icon>
                              <v-icon
                                small
                                @click="
                                  deleteData(
                                    item.id,
                                    item.username
                                  )
                                "
                              >
                                mdi-delete
                              </v-icon>
                          </template>
                        </v-data-table>
                    </v-card>
                    </v-col>
                    <v-col>
                      <v-card>
                        <v-card-title>
                          <h3>{{form.type}}</h3>
                          <form >
                            <v-card-text>
                                <v-row>
                                  <div class="col-sm-6">
                                    <v-text-field
                                        v-model="form.nama"
                                        label="Nama Menu"
                                        hide-details=true
                                        required
                                        outlined
                                    />           
                                  </div> 
                                  <div class="col-sm-6">
                                    <v-text-field
                                        v-model="form.harga"
                                        label="Harga Menu"
                                        type="number"
                                        hide-details=true
                                        required
                                        outlined
                                    />           
                                  </div>   
                                  <div class="col-12">
                                    <v-textarea
                                      type="desc"
                                      v-model="form.desc"
                                      label="Deskripsi Menu"
                                      hide-details=true
                                      required
                                      outlined
                                    ></v-textarea>
                                  </div>                  
                                </v-row>
                            </v-card-text>
                            <v-card-actions>
                              <v-spacer></v-spacer>
                              <v-btn
                                color="blue darken-1"
                                text
                                @click="dialog = false"
                              >
                                Close
                              </v-btn>
                              <v-btn
                                color="blue darken-1"
                                text
                                @click="saveData()"
                                type="submit"
                              >
                                Save
                              </v-btn>
                            </v-card-actions>
                            </form>
                        </v-card-title> 
                      </v-card>
                    </v-col>
                  </v-row>
                    
                </v-main>
            </v-app>
        </div>
      </div>
    </section>
    </div>
</template>
<style>
  .minim{
    padding-top:5px !important;
    padding-bottom:5px !important;
    padding-right:5px !important;
    padding-left:3% !important;
  }
  .label-text{
    font-size: 15px;
    padding-bottom: 10px !important;
    font-weight: 400;
    color: rgb(109, 109, 109)
  }
  .main-text{
    font-size: 18px;
    font-weight: 500;
    color: rgb(36, 35, 35)
  }
  .sub-text{
    font-size: 16px;
    font-weight: 500;
    color: rgb(53, 53, 53)
  }
</style>
<script>
import { mapGetters, mapActions } from 'vuex'
  export default {
        created() {
            this.getData();
            Fire.$on('reloaddata', () => {
              this.getData();
            })
        },
    
        data() {        
            return {
              expanded: [],
              data: [],
              singleExpand: true,
              loading: true,
              search: '',
              update: false,
              form:{
                id:'',
                nama:'',
                harga:'',
                desc:'',
                type:'Form Input Data Baru',
              },
              imagePreview: `${process.env.BASE_URL}image/image.webp`,
              headers: [
                  { text: 'Nama Menu',value: 'nama', },
                  { text: 'Harga',value: 'harga', },
                  { text: 'Deskripsi',value: 'desc', },
                  { text: 'Actions', value: 'actions', sortable: false },
              ],
            }
        },
         computed:{
            ...mapGetters({
                authenticated: 'auth/authenticated',
            })
        },
        methods: {
            reset(){
              this.form.type="Form Input Data Baru "
              this.form.nama=null;
              this.form.harga=null;
              this.form.desc=null;
              this.update = false;
            },
            imageNull(event) { 
                event.target.src = this.imagePreview 
            },
            getData() {
              axios.get('menu')
                  .then(response => {   
                    this.loading = false  
                    this.data = response.data.data;
                  })
                  .catch(errors => {
                    this.loading = false
                    console.log(errors);
                  });
            },
            filterText (value, search, item) {
                return value != null &&
                search != null &&
                typeof value === 'string' &&
                value.toString().indexOf(search) !== -1
            },
            openModal (update,id) {
                if (update == true) {
                  axios.get(`/menu/show/${id}`)
                  .then(response => {     
                    this.form.id = id;
                    this.form.nama = response.data.data.nama;
                    this.form.harga = response.data.data.harga;
                    this.form.desc = response.data.data.desc;
                    this.form.type="Form Update Data "+response.data.data.username;
                    this.update = true
                  })
                  .catch(errors => {
                    this.loading = false
                    this.$fire({
                        title: "Error",
                        text: `${errors}`,
                        type: "error",
                    });
                  });
                }else{
                  this.form.type="Form Input Data Baru "
                  this.form.nama=null;
                  this.form.harga=null;
                  this.form.desc=null;
                  this.update = false;
                }
            },
            saveData(){
              this.overlay=true;
              let data = new FormData();
              if (this.form.update==true) {
                data.append('id',this.form.id)
              }
              data.append('nama',this.form.nama)
              data.append('harga',this.form.harga)
              data.append('desc',this.form.desc)
              if (this.update==true) {
                // data.append('_method', 'PUT')
                axios.post(`/menu/update/${this.form.id}`,data).then((res)=>{
                  this.overlay=false;
                  this.dialog=false;
                  if(res.data.success = true){
                    this.update = false
                    this.$fire({
                        title: "Success",
                        text: `Menu ${this.form.name} berhasil diperbaharui`,
                        type: "success",
                    });
                    Fire.$emit('reloaddata');
                    Fire.$emit('AfterCreate'); 
                    this.reset()
                  }else{
                    this.$fire({
                        title: "Error",
                        text: `Menu ${this.form.name} gagal diperbaharui`,
                        type: "error",
                    });
                  }
                              
                })   
              }
              else{
                axios.post('/menu/add',data).then((res)=>{
                  this.overlay=false;
                  this.dialog=false;
                  if(res.data.success = true){
                    this.update = false
                    this.$fire({
                        title: "Success",
                        text: `Menu baru berhasil ditambahkan`,
                        type: "success",
                    });
                    Fire.$emit('reloaddata');
                    Fire.$emit('AfterCreate');
                    this.reset()
                  }else{
                    this.$fire({
                        title: "Error",
                        text: `Menu baru gagal ditambahkan`,
                        type: "error",
                    });
                  }                            
                });                               
              }               
            },
            deleteData(id,name){
                console.log(id)
                this.$fire({
                    title: "Warning",
                    text: `Apakah anda benar-benar ingin menghapus ${name} ?`,
                    type: "question",
                    showCancelButton: true,
                    confirmButtonColor: '#3085d6',
                    cancelButtonColor: '#d33',
                    confirmButtonText: 'Yes, delete it!'
                }).then(r => {
                    if (r.value==true) {
                        let data = new FormData();
                        data.append('_method', 'DELETE')
                        axios.post(`menu/delete/${id}`, data).then((res) =>{
                            this.todos = res.data
                            Fire.$emit('reloaddata')
                            this.$fire({
                              title: "Title",
                              text: `Menu ${name} berhasil dihapus`,
                              type: "success",
                              timer: 3000
                            }).then(r => {
                              console.log(r.value);
                            });
                            Fire.$emit('AfterCreate');
                        }).catch(() => {
                            swal("Failed!", "There was something wronge.", "warning");
                        })
                       
                      }
                });              
            },   
        },
  };
  
</script>